<template>
    <v-container>
        <Breadcrumbs :breadcrumbs="breadcrumbs"/>
        <v-row class="row mb-0 mt-1">
          <v-col class="ma-0 row" cols="12" sm="6" md="8" lg="9" >
            <v-select style="max-width: 150px;" class="mr-5"
                :items="user_items"
                v-model="media_id"
                label="매체"
                item-text="name"
                item-value="id"
                @change="medias"
                hide-details
            ></v-select>
          </v-col>
          <v-col class=" ma-0 row" cols="12" sm="6" md="4" lg="3" style="flex-wrap: nowrap;">
            <v-toolbar
                flat
            >
              <v-dialog
                  v-model="dialog"
                  max-width="500px"
              >
                <template v-slot:activator="{ on, attrs }"  >
                  <v-btn
                      class="ml-lg-8"
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      outlined
                  >
                    카테고리
                    <v-icon
                        right
                        dark
                        rounded
                    >
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </template>
                <v-card >
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col>
                          <v-text-field
                              v-model="editedItem.topic"
                              label="카테고리"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="white--text"
                        color="#283593"
                        @click="save"
                    >
                      저장
                    </v-btn>
                    <v-btn
                        class="white--text"
                        color="#E23C44"
                        @click="close"
                    >
                      취소
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
            <v-text-field
                style="max-width: 220px;"
                label="검색"
                v-model="search"
                append-icon="mdi-magnify"
                @keyup.native.enter="searchChange()"
                hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider class="indigo mt-2 mb-4"></v-divider>
        <v-row v-if="loading" class="justify-center"
               :loading="loading"
        >
          <spinner :loading="loading"></spinner>
        </v-row>
        <v-row v-else class="pt-5">
            <v-col v-if="this.items.length === 0"
                   justify="center" align="center"
            >
              <span class="subtitle-1">데이터가 존재하지 않습니다.</span>
            </v-col>
            <v-col v-else>
                  <v-data-table
                      hide-default-footer
                      :headers="headers"
                      :items="items"
                      class="elevation-1"
                      :items-per-page="per_page"
                  >
                      <template v-slot:item.actions="{ item }">
                          <v-icon
                              small
                              class="mr-1"
                              @click="editItem(item)"
                          >
                              mdi-pencil
                          </v-icon>
                      </template>
                  </v-data-table>
                  <v-pagination
                      v-if="this.searchCount > per_page"
                      class="mt-10 mb-5"
                      v-model="page"
                      :length="last_page"
                      circle
                      color="#1550B2"
                      prev-icon="mdi-chevron-left"
                      next-icon="mdi-chevron-right"
                      :total-visible="10"
                      @input="getData()"
                  ></v-pagination>
              </v-col>
          </v-row>
    </v-container>
</template>

<script>
import Breadcrumbs from "../../components/Breadcrumbs";
import Spinner from "../../components/Spinner";
import {mapGetters} from "vuex";
import numeral from "numeral";

export default {
    components: {
        Breadcrumbs,
        Spinner,
    },
    data() {
        return {
            loading: false,
            breadcrumbs: [
                {
                    text: '카테고리 관리',
                    disabled: false,
                    href: '/topics',
                },
            ],
            user_items: [],
            items: [],
            media_id: null,
            dialog: false,
            headers: [
                {text: 'NO.', sortable: false, value: 'no', width: '250px'},
                {text: '카테고리', sortable: false, value: 'topic', width: '250px'},
                {text: '카테고리 등록일', sortable: false, value: 'created_at', width: '250px'},
                {text: '수정', sortable: false, value: 'actions', width: '150px'},
            ],
            editedItem: {
                media_id: 0,
                topic: '',
                created_at: '',
            },
            defaultItem: {
                media_id: '',
                topic: '',
                created_at: '',
            },
            editedIndex: -1,
            page: 1,
            per_page: 20,
            last_page: 1,
            search: '',
            searchCount: '', // total 또는 검색했을 때 값
        }
    },
    computed: {
      formTitle() {
        return this.editedIndex === -1 ? '카테고리 추가' : '카테고리 수정'
      },
      ...mapGetters({
        user: 'auth/user',
      }),
      getMediaId() {
        return this.$store.getters.getMediaId;
      },
    },
    watch: {
      media_id() {
        this.$store.commit("SET_MEDIA_ID", this.media_id);
      },
    },
    mounted() {
        this.media_id = this.getMediaId;
        const mediaId = this.media_id;
        if (mediaId) {
            this.media_id = parseInt(mediaId);
        } else {
            this.media_id = this.user.user_media[0].id;
        }

        if (localStorage.getItem('token')) {
            this.user_items = this.user.user_media;
        }

        if (this.$route.query.search) {
          this.search = this.$route.query.search;
        }
        this.getData();
    },
    methods: {
        medias(media) {
            this.page = 1;
            this.media_id = media;
            if (typeof this.media_id === "undefined") {
                this.media_id = this.user.user_media[0].id;
            } else {
                this.media_id = media
            }
            this.getData();
        },
        getData() {
            let items = [];
            let header = '';
            this.loading = true;
            if (this.media_id === 1) {
                header = 'bloodpressure';
            } else if (this.media_id === 2) {
                header = 'golfzip';
            } else if (this.media_id === 3) {
                header = 'ticketoffice';
            } else if (this.media_id === 4) {
                header = 'tingstar';
            } else if (this.media_id === 5) {
                header = 'sivillage';
            }
            this.axios.get('api/v1/topics', {
                params: {
                    'media_id': this.media_id,
                    'page': this.page,
                    'per_page': this.per_page,
                    'search': this.search ? this.search : '',
                },headers: {
                    'c9': header
                }
            })
                .then(res => {
                    this.searchCount = res.data.data.totalCount; // total 또는 검색 했을 때 개수
                    if (res.data.data.topics.length > 0) {
                        res.data.data.topics.map((item, index) => {
                          let no = res.data.data.totalCount - (this.page - 1) * this.per_page - index;
                            items.push({
                                no: this.numberComma(no),
                                id: item.id,
                                media_id: item.media_id,
                                topic: item.topic,
                                created_at: item.created_at, // 등록일
                            })
                        })
                    }
                    this.items = items;
                    if (Number.isInteger(res.data.data.totalCount / this.per_page) == false) {
                      this.last_page = Math.floor(res.data.data.totalCount / this.per_page) + 1;
                    } else {
                      this.last_page = res.data.data.totalCount / this.per_page;
                    }
                    this.loading = false;
                })
                .catch(err => {
                    console.error(err);
                });
        },
        save() {
            if (this.editedIndex > -1) {
                Object.assign(this.items[this.editedIndex], this.editedItem)
                const data = {
                    media_id: this.media_id,
                    topic: this.editedItem.topic,
                };
                this.axios.put('api/v1/topics/' + this.editedItem.id, data).then(res => {
                    this.medias(res.data.data.media_id);
                }).catch(err => {
                    console.error(err);
                });
            } else {
                this.items.push(this.editedItem)
                const data = {
                    media_id: this.media_id,
                    topic: this.editedItem.topic,
                };
                this.axios.post('api/v1/topics', data).then(res => {
                    this.medias(res.data.data.media_id);
                }).catch(err => {
                    console.error(err);
                });
            }
            this.close();
        },
        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        editItem(item) {
            this.editedIndex = this.items.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },
        searchChange() {
          this.page = 1;
          this.getData();
        },
        numberComma(value) {
          return numeral(value).format('0,0');
        },
    }
}
</script>

<style scoped>

</style>
