<template>
  <v-container>
    <Breadcrumbs :breadcrumbs="breadcrumbs"/>
    <v-row>
      <v-col cols="12" sm="3">
        <v-select
            :items="mediasItems"
            label="매체"
            v-model="currentMedia"
            @change="filterMedia"
        ></v-select>
      </v-col>

      <v-col cols="12" sm="3">
        <v-select
            :items="platformsItems"
            label="플랫폼"
            v-model="currentPlatform"
            @change="filterPlatform"
        ></v-select>
      </v-col>

      <v-col cols="12" sm="3">
        <v-select
            :items="eventsItems"
            label="행동"
            v-model="currentEvent"
            @change="filterEvent"
        ></v-select>
      </v-col>

      <v-col cols="12" sm="3">
        <v-menu
            ref="date_range"
            v-model="calendar"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="date_range_text"
                label="검색구간"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="date_range"
              locale="ko-kr"
              no-title
              scrollable
              range
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="calendar = false">취소</v-btn>
            <v-btn text color="primary" @click="changeDateRange()">확인</v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="3">
        <v-text-field class=""
                      label="글번호 검색"
                      v-model="searchArticle"
                      append-icon="mdi-magnify"
                      @keyup.native.enter="filterArticle()"
                      hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field class=""
                      label="유저 검색"
                      v-model="searchUser"
                      append-icon="mdi-magnify"
                      @keyup.native.enter="filterUser()"
                      hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="3">
        <v-combobox
            v-model="tags"
            :search-input.sync="newTag"
            hide-selected
            label="태그"
            hint="태그는 'OR(또는)' 검색입니다."
            persistent-hint
            multiple
            small-chips
            @change="filterTags"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{newTag}}를 <kbd>enter</kbd>로 검색 추가합니다.
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-combobox>
      </v-col>

      <v-col cols="12" sm="3" class="d-flex align-center justify-space-between">
        <div class="mr-2 mb-2">
          <v-checkbox
              label="제외사용자 미포함"
              color="indigo"
              v-model="currentWithout"
              @change="filterWithout"
          ></v-checkbox>
        </div>
        <div>
          <v-btn
              color="indigo"
              class="mb-3 white--text"
              @click="manualGetData"
          >검색</v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row
        class="mt-6 mb-3"
    >
      <v-col
          cols="12"
      >
        <v-card
            class="elevation-1"
        >
          <v-row
          >
            <v-col
                cols="12"
                md="6"
                lg="6"
                :class="$vuetify.breakpoint.mobile ? 'd-flex justify-start ml-3' : 'd-flex justify-center'"
            >
              <strong>검색 결과</strong><span class="pl-3">{{numberComma(total)}}</span>
            </v-col>

            <v-col
                cols="12"
                md="6"
                lg="6"
                :class="$vuetify.breakpoint.mobile ? 'd-flex justify-start ml-3' : 'd-flex justify-center'"
            >
              <strong>유저수</strong><span class="pl-3">{{numberComma(uniqueTotal)}}</span>
            </v-col>

          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-divider class="indigo mt-6 mb-6"></v-divider>

    <v-row  class="pt-0">
      <v-col v-if="this.items.length === 0 && loading === false" justify="center" align="center">
        <span class="subtitle-1">데이터가 존재하지 않습니다.</span>
      </v-col>
      <v-col v-else>
        <ul class="pa-0">
          <v-expansion-panels>
            <v-expansion-panel>
              <li class="font-weight-bold" style="color: rgba(0,0,0.87);">
                <v-expansion-panel-header disabled>
                  <div class="text-center" style="display:inline-block; width: 8% !important;">매체</div>
                  <div class="text-center" style="display:inline-block; width: 8% !important;">플랫폼</div>
                  <div class="text-center" style="display:inline-block; width:10% !important;">글번호</div>
                  <div class="text-center" style="display:inline-block; width:10% !important;">행동</div>
                  <div class="text-center" style="display:inline-block; width:18% !important;">태그</div>
                  <div class="text-center" style="display:inline-block; width:29% !important;">유저</div>
                  <div class="text-center" style="display:inline-block; width:5% !important;">제외</div>
                  <div class="text-center" style="display:inline-block; width:12% !important;">날짜</div>
                  <template v-slot:actions>
                    <span style="display: inline-block;width: 20px;"></span>
                  </template>
                </v-expansion-panel-header>
              </li>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-expansion-panels focusable class="mt-4">
            <v-expansion-panel v-for="(item, index) in items" :key="index">
              <li class="align-center">
                <v-expansion-panel-header style="padding-top:0px; padding-bottom: 0px;">
                  <div class="text-center" style="width :  8% !important;">{{ item.media_name }}</div>
                  <div class="text-center" style="width :  8% !important;">
                    <v-icon v-if="item.icon" :color="`${item.iconColor} darken-2`">
                      {{ item.icon }}
                    </v-icon>
                    <span v-else>{{ item.platform }}</span>
                  </div>
                  <div class="text-center" style="width : 10% !important;">{{ item.article_id }}</div>
                  <div class="text-center" style="width : 10% !important;">
                    <v-chip outlined :color="item.eventColor">
                      {{ item.eventKor }}
                    </v-chip>
                  </div>
                  <div class="text-center" style="width : 18% !important;">
                    <v-chip v-for="(tag, idx) in item.presentTags" :key="idx" class="tag mr-1" small>
                      {{tag}}
                    </v-chip>
                    <span v-if="item.totalTagCount > 0"> + {{item.totalTagCount}}</span>
                  </div>
                  <div class="text-center" style="width : 29% !important;">{{ item.user_id }}</div>
                  <div class="text-center" style="width : 5% !important;">
                    <v-btn
                        icon
                        color="red"
                        @click.native.stop
                        @click="addExcludedUser(item)"
                    >
                      <v-icon>mdi-account-cancel</v-icon>
                    </v-btn>
                  </div>
                  <div class="text-center" style="width : 12% !important; line-height: 1.4em;">{{ item.presentDate }}<br />{{ item.presentTime }}</div>
                  <!--            <td class="text-center" v-html="addTagHighlight(item.tag)"></td>-->
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="pa-4">
                    <v-col cols="12" xl="2">
                      <v-chip class="mr-1" label outlined>
                        글번호
                      </v-chip>
                      <router-link :to="{
                        path: `/${item.article_id}`,
                        query: {
                          c9: $root.getMediaEngNameById(item.media_id),
                          media_id: item.media_id,
                          state: 1,
                        }}">{{ item.article_id }}</router-link>
                    </v-col>
                    <v-col cols="12" xl="3">
                      <v-chip class="mr-1" label outlined>
                        유저ID
                      </v-chip>
                      <router-link :to="{
                        name: 'UserSearchList',
                        query: {
                          search: item.user_id,
                          media_id: item.media_id
                        }}">{{ item.user_id }}</router-link>
                    </v-col>
                    <v-col cols="12" xl="7">
                      <v-chip class="mr-1" label outlined>
                        모든 태그
                      </v-chip>
                      <v-chip v-for="(tag, idx) in item.tags" :key="idx" class="tag mr-1" small>{{tag}}</v-chip>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </li>
            </v-expansion-panel>
          </v-expansion-panels>

        </ul>
        <div class="mt-8 d-flex flex justify-center" v-if="items.length < total">
          <v-btn class="indigo darken-3 white--text" @click.prevent="getNextPageData">more logs</v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="loading" class="justify-center" :loading="loading">
      <spinner :loading="loading"></spinner>
    </v-row>
  </v-container>
</template>

<script>
import Breadcrumbs from "../../components/Breadcrumbs";
import Spinner from "../../components/Spinner";
import {mapGetters} from "vuex";
import numeral from "numeral";
import dayjs from "dayjs";

export default {
  components: {
    Breadcrumbs,
    Spinner,
  },
  data() {
    return {
      loading: false,
      breadcrumbs: [
        {
          text: '사용자 접속 로그',
          disabled: false,
          href: '/statistic-user-logs',
        },
      ],
      medias: [],
      mediasObj: {},
      platforms: [],
      eventsItems: [
        { text: '전체', value: null },
        { text: '방문', value: 'visit' },
        { text: '클릭', value: 'click' },
        { text: '즐겨찾기', value: 'favorite' },
        { text: '좋아요', value: 'like' },
      ],
      currentMedia: null,
      currentPlatform: null,
      currentEvent: null,
      currentWithout: false,
      calendar: false,
      searchArticle: '',
      searchUser: '',
      date_range: [dayjs().set('date', -28).format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
      tags: [],
      newTag: '',
      requestBody: {
        start_date: dayjs().set('date', -28).format('YYYY-MM-DD'),
        end_date: dayjs().format('YYYY-MM-DD'),
        per_page: 100,
        search: [
          {
            field: "media_id",
            value: []
          }
        ],
        without: false,
        sort: [
          {
            field: "created_at",
            order: "desc"
          }
        ],
      },
      items: [],
      total: 0,
      uniqueTotal: 0,
      nextId: '',
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    mediasItems() {
      return [
        {
          'text': '전체',
          'value': null,
        },
        ...this.medias.map(m => ({ 'text': m.name, 'value': m.id }))
      ];
    },
    platformsItems() {
      return [
        {
          'text': '전체',
          'value': null,
        },
        ...this.platforms.map(p => ({ 'text': p.platform_kr, 'value': p.platform, 'id': p.id })),
      ];
    },
    date_range_text() {
      return this.date_range.join(' - ')
    },
    getMediaId() {
      return this.$store.getters.getMediaId;
    },
  },
  watch: {
    currentMedia() {
      this.$store.commit("SET_MEDIA_ID", this.currentMedia);
    },
  },
  mounted() {
    this.currentMedia = this.getMediaId;
    if (localStorage.getItem('token')) {
      this.user_items = this.user.user_media;
    }

    if (this.$route.query.search) {
      this.search = this.$route.query.search;
    }


    this.getMedia().then(() => {
      // this.requestBody.search = this.requestBody.search.filter(s => s.field !== 'media_id')
      // this.requestBody.search.push({ field: 'media_id', value: this.medias.map(m => m.id) })
      this.filterMedia();
      this.getData();
    });

    this.getPlatform()

  },
  methods: {
    moveToSearchResult(userId, mediaId) {
      this.$store.commit("SET_MEDIA_ID", mediaId);
      window.location.href = `/statistic-user-search?search=${userId}&media_id=${mediaId}`;
    },
    getMedia() {
      this.loading = true;
      return this.axios.get('api/v1/medias')
          .then(res => {
            this.medias = res.data.data;
            this.medias.forEach(m => {
              this.mediasObj[m.id] = m;
            })
            this.loading = false;
          })
          .catch(err => {
            console.error(err);
          });
    },

    getPlatform() {
      this.loading = true;
      return this.axios.get('api/v1/platforms')
          .then(res => {
            this.platforms = res.data.data.platforms;
            this.loading = false;
          })
          .catch(err => {
            console.error(err);
          });
    },

    getData() {
      this.loading = true;
      this.axios.post('/api/v1/admin/log', {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        ...this.requestBody,
      })
          .then(res => {
            const results = res.data.data.data.map((item, i) => {
              item.no = this.items.length ? this.items[this.items.length - 1].no + i + 1 : i + 1;
              item.media_name = this.mediasObj[item.media_id].name;
              item.presentTags = item.tags;
              item.event = item.article_id ? item.event : 'visit';
              item.eventKor = item.event === 'visit' ? '방문' : item.event === 'click' ? '클릭' : item.event === 'favorite' ? '즐겨찾기': '좋아요';
              item.eventColor = item.event === 'visit' ? 'indigo' : item.event === 'click' ? 'info' : item.event === 'favorite' ? 'green' : 'pink';
              [item.presentDate, item.presentTime] = item.created_at.split(' ');
              item.icon = this.$root.getPlatformIconByName(item.platform);
              item.iconColor = this.$root.getPlatformColorByName(item.platform);

              return item
            })

            if (this.items.length == 0) {
              this.uniqueTotal = res.data.data.total_unique_count
            }

            this.items.push(...results)

            this.total = res.data.data.total_count
            this.nextId = res.data.data.search_after

            this.loading = false
          })
          .catch(err => {
            if(err.response.status === 404) {
              this.total = 0
              this.nextId = null
              this.loading = false
            } else {
              console.error(err);
            }
          });
    },
    addExcludedUser(item) {
      if (confirm('정말 집계제외 사용자로 등록하시겠습니까?')) {

        this.axios.post('/api/v1/admin/log/exclude/user', {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          user_id: item.user_id,
          adid: item.device.adid,
          media_id: item.media_id,
        })
            .then(res => {
              if (res.data.result == true) {
                alert('정상적으로 등록되었습니다!');
              } else {
                alert('오류가 발생하였습니다!');
              }
            })
            .catch(err => {
              alert('오류가 발생하였습니다!');
              console.error(err);
            });
      }
    },
    getNextPageData() {
      this.requestBody = {
        ...this.requestBody,
        search_after: this.nextId,
      }

      this.getData()
    },
    manualGetData() {
      this.items = []
      delete this.requestBody.search_after

      this.requestBody.search = this.requestBody.search.filter((s) => s.field !== 'tags')
      if( this.tags.length > 0 ) {
        this.requestBody.search.push( { field: 'tags', value: this.tags } );
      }

      this.requestBody.search = this.requestBody.search.filter((s) => s.field !== 'article_id');
      if(this.searchArticle !== '') {
        this.requestBody.search.push( { field: 'article_id', value: [this.searchArticle] } )
      }

      this.requestBody.search = this.requestBody.search.filter((s) => s.field !== 'user_id');
      if(this.searchUser !== '') {
        this.requestBody.search.push( { field: 'user_id', value: [this.searchUser] } )
      }

      this.getData()
    },

    filterMedia() {
      this.items = []
      delete this.requestBody.search_after

      this.requestBody.search = this.requestBody.search.filter((s) => s.field !== 'media_id')
      this.requestBody.search = [
        ...this.requestBody.search,
        { field: 'media_id', value: this.currentMedia !== null ? [this.currentMedia] : this.medias.map(m => m.id) }
      ]

      this.getData()
    },
    filterPlatform() {
      this.items = []
      delete this.requestBody.search_after

      this.requestBody.search = this.requestBody.search.filter((s) => s.field !== 'platform')

      if( this.currentPlatform !== null ) {
        this.requestBody.search.push( { field: 'platform', value: [this.currentPlatform] });
      }

      this.getData()
    },
    filterEvent() {
      this.items = []
      delete this.requestBody.search_after

      this.requestBody.search = this.requestBody.search.filter((s) => s.field !== 'event')

      if( this.currentEvent !== null ) {
        this.requestBody.search.push( { field: 'event', value: this.currentEvent } );
      }

      this.getData()
    },

    filterWithout() {
      this.items = []
      delete this.requestBody.search_after

      this.requestBody.without = this.currentWithout

      this.getData()
    },

    filterTags() {
      this.items = []
      delete this.requestBody.search_after

      this.requestBody.search = this.requestBody.search.filter((s) => s.field !== 'tags')

      if( this.tags.length > 0 ) {
        this.requestBody.search.push( { field: 'tags', value: this.tags } );
      }

      this.getData()
    },

    changeDateRange() {
      // 날짜 역순 선택 예외처리 추가
      if(this.date_range[0] > this.date_range[1]) {
        this.requestBody.start_date = this.date_range[1]
        this.requestBody.end_date = this.date_range[0]
      } else {
        this.requestBody.start_date = this.date_range[0]
        this.requestBody.end_date = this.date_range[1]
      }

      this.items = []
      delete this.requestBody.search_after

      this.getData();

      this.calendar = false;
    },

    filterArticle() {
      this.items = []
      delete this.requestBody.search_after

      this.requestBody.search = this.requestBody.search.filter((s) => s.field !== 'article_id');
      if(this.searchArticle !== '') {
        this.requestBody.search.push( { field: 'article_id', value: [this.searchArticle] } )
      }

      this.getData();
    },

    filterUser() {
      this.items = []
      delete this.requestBody.search_after

      this.requestBody.search = this.requestBody.search.filter((s) => s.field !== 'user_id');
      if(this.searchUser !== '') {
        this.requestBody.search.push( { field: 'user_id', value: [this.searchUser] } )
      }

      this.getData();
    },

    numberComma(value) {
      return numeral(value).format('0,0');
    },


  }
}
</script>

<style scoped>
.theme--light.v-chip:hover:before {
  opacity: 0;
}
.theme--light.v-chip {
  padding: 0 6px;
}
</style>
