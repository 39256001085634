<template>
    <v-container>
      <Breadcrumbs :breadcrumbs="breadcrumbs"/>
      <v-row class="row mb-0 mt-1">
        <v-col class="col-lg-4 ma-0 row">
          <v-select style="max-width: 150px;"
              :items="user_items"
              v-model="media_id"
              label="매체"
              item-text="name"
              item-value="id"
              @change="medias"
              hide-details
          ></v-select>
        </v-col>
        <v-col class="col-lg-8 ma-0 justify-end" >
          <v-text-field class="float-right"
                        style="max-width: 220px;"
                        label="검색"
                        placeholder="Base Keyword"
                        v-model="search"
                        append-icon="mdi-magnify"
                        @keyup.native.enter="searchChange()"
                        hide-details
          ></v-text-field>
          <v-toolbar
              flat
          >
            <v-spacer></v-spacer>
            <v-dialog
                v-model="dialog"
                max-width="600px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                >
                  키워드 (PK) 등록
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                  <v-spacer></v-spacer>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="white--text"
                        color="#1239A3"
                        @click="save"
                    >
                      저장
                    </v-btn>
                    <v-btn
                        class="white--text"
                        color="#E23C44"
                        @click="close"
                    >
                      취소
                    </v-btn>
                  </v-card-actions>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-select
                            :items="topic_items"
                            v-model="editedItem.topic"
                            label="카테고리"
                            item-text="topic"
                            item-value="topic"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-textarea
                            outlined
                            v-model="editedItem.baseKeyword"
                            label="* 키워드 (PK)가 여러개일 경우 쉼표로 구분해주세요."
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-combobox
                          v-model="selected_platform"
                          :items="platform_list"
                          chips
                          clearable
                          label="플랫폼을 선택해주세요."
                          multiple
                          prepend-icon="mdi-apps"
                          solo
                      > <!-- mdi-filter-variant -->
                        <template v-slot:selection="{ attrs, item, select, selected }">
                          <v-chip
                              v-bind="attrs"
                              :input-value="selected"
                              close
                              @click="select"
                              @click:close="remove(item)"
                          >
                            <strong>{{ item }}</strong>&nbsp;
                          </v-chip>
                        </template>
                      </v-combobox>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-btn v-if="grade !== 0"
                   :loading="upload_loading"
                   :disabled="upload_loading"
                   @click="manualScrap()"
                   color="#546E7A"
                   class="mb-2 ml-2"
                   outlined
            >
              수집
              <v-icon
                  right
                  rounded
              >
                mdi-database
              </v-icon>
            </v-btn>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-divider class="indigo mt-2 mb-4"></v-divider>
      <v-row v-if="loading" class="justify-center"
             :loading="loading"
      >
        <spinner :loading="loading"></spinner>
      </v-row>
      <v-row v-else class="pt-2">
          <v-col v-if="this.items.length === 0"
                 justify="center" align="center"
          >
            <span class="subtitle-1">데이터가 존재하지 않습니다.</span>
          </v-col>
          <v-col v-else>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center" style="width:8%">NO.</th>
                    <th class="text-left" style="width:10%">매체</th>
                    <th class="text-left" style="width:15%">키워드 (PK)</th>
                    <th class="text-left" style="width:14%">플랫폼</th>
                    <th class="text-center" style="width:11%">생성일</th>
                    <th class="text-center" style="width:10%">카테고리</th>
                    <th class="text-left" style="width:10%">노출여부</th>
                    <th class="text-left" style="width:10%">사용여부</th>
                    <th class="text-left" style="width:10%; margin-left: 26px">수정/삭제</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td class="text-center">{{ item.no }}</td>
                    <td class="text-left">{{ item.media_name }}</td>
                    <td>{{ item.baseKeyword }}</td>
                    <td class="text-left">
                      <span v-for="(platform, sub_index) in item.platforms" :key="sub_index">
                        <v-icon :color="platform.platform_color">{{ platform.platform_icon }}</v-icon> <!-- {{ item.platform_kr }} -->
                      </span>
                    </td>
                    <td class="text-center">{{ item.created_at }}</td>
                    <td class="text-center">{{ item.topic}}</td>
                    <td>
                      <v-switch
                          v-model="item.exposable"
                          :true-value=1
                          :false-value=0
                          color="#22bb33"
                          class="my-1"
                          hide-details
                          @change="toggleExposable(item.id)"
                      ></v-switch>
                    </td>
                    <td>
                      <v-switch
                          v-model="item.state"
                          :true-value=1
                          :false-value=0
                          color="#22bb33"
                          class="my-1"
                          hide-details
                          @change="toggleState(item.id)"
                      ></v-switch>
                    </td>
                    <td>
                      <v-icon
                          class="mr-1"
                          @click="editItem(item)"
                      >
                        mdi-pencil
                      </v-icon>
                      /
                      <v-icon
                          class="mr-1"
                          @click="deleteItem(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-pagination
                v-if="this.searchCount > per_page"
                class="mt-10 mb-5"
                v-model="page"
                :length="last_page"
                circle
                color="#1550B2"
                prev-icon="mdi-chevron-left"
                next-icon="mdi-chevron-right"
                :total-visible="10"
                @input="getData()"
            ></v-pagination>
          </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Breadcrumbs from "../../components/Breadcrumbs";
import Spinner from "../../components/Spinner";
import {mapGetters} from "vuex";
import numeral from "numeral";

export default {
  components: {
    Breadcrumbs,
    Spinner,
  },
  data() {
    return {
      loading: false,
      breadcrumbs: [
        {
          text: '키워드 (PK) 관리',
          disabled: false,
          href: '/BaseKeyword',
        },
      ],
      platform_list: [],
      selected_platform: [],
      user_items: [],
      topic_items: [],
      items: [],
      media_id: null,
      dialog: false,
      upload_loading: false,
      editedItem: {
        media_id: 0,
        baseKeyword: '',
        platform: '',
        created_at: '',
        state: '',
        topic: '',
        exposable: '',
      },
      defaultItem: {
        media_id: '',
        baseKeyword: '',
        platform: '',
        topic: '',
        exposable: '',
        state: '',
      },
      editedIndex: -1,
      total: '',
      page: 1,
      per_page: 20,
      last_page: 1,
      search: '',
      searchCount: '', // total 또는 검색했을 때 값
      grade:''
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? '키워드 (PK) 등록' : '키워드 (PK) 수정'
    },
    ...mapGetters({
      user: 'auth/user',
    }),
    getMediaId() {
      return this.$store.getters.getMediaId;
    },
  },
  watch: {
    media_id() {
      this.$store.commit("SET_MEDIA_ID", this.media_id);
    },
    dialog() {
      if(this.dialog === false) {
        this.selected_platform = [];
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      }
    }
  },
  mounted() {
    this.media_id = this.getMediaId;

    const mediaId = this.media_id;
    if (mediaId) {
      this.media_id = parseInt(mediaId);
    } else {
      this.media_id = this.user.user_media[0].id;
    }

    if (localStorage.getItem('token')) {
      this.user_items = this.user.user_media;
      this.grade = this.user.grade;
    }
    this.getData();
    this.getPlatformList();
  },
  methods: {
    getData() {
      // 멀티 플랫폼일 때
      this.loading = true;
      let result = [];
      let header = this.$root.getMediaEngNameById(this.media_id);
      const params = {
        'media_id': this.media_id,
        'page': this.page,
        'per_page': this.per_page,
        'search': this.search ? this.search : '',
      };
      this.axios.get('api/v1/base-keywords', {
        params,
        headers: {
          'c9': header
        }
      }).then(res => {
        this.searchCount = res.data.data.count;
        if (this.searchCount > 0) {
          res.data.data.keywords.map((item, index) => {
            let no = res.data.data.count - (this.page - 1) * this.per_page - index;
            let platforms = [];

            if(item.count === null){
              item.count = 0;
            }
            if(item.platform.length > 0) {
              item.platform.forEach(
                  element => {
                    platforms.push({
                      platform: this.$root.getPlatformHanNameByName(element),
                      platform_color:this.$root.getPlatformColorByName(element),
                      platform_icon: this.$root.getPlatformIconByName(element),
                    })
                  }
              )
            }

            result.push({
              no: this.numberComma(no),
              id: item.id,
              media_id: item.media_id,
              media_name: this.$root.getMediaHanNameById(item.media_id),
              platforms: platforms,
              baseKeyword: item.keyword,
              topic: item.topic,
              created_at: item.created_at,
              exposable: item.is_exposable,
              state: item.state,
            })
          })

          if (Number.isInteger(this.searchCount / this.per_page) == false) {
            this.last_page = Math.floor(this.searchCount / this.per_page) + 1;
          } else {
            this.last_page = this.searchCount / this.per_page;
          }
        }
        this.loading = false;
      }).catch(err => {
        this.loading = false;
        console.error(err);
      });
      this.items = result;

      this.axios.get('api/v1/medias/' + this.media_id, {
        headers: {
          'c9': header
        }
      }).then(res => {
        this.topic_items = res.data.data.topics;
      }).catch(err => {
        console.error(err);
      });
    },
    medias(media) {
      this.page = 1;
      this.media_id = media;
      if (typeof this.media_id === "undefined") {
        this.media_id = this.user.user_media[0].id;
      } else {
        this.media_id = media;
      }
      this.getData();
    },
    save() {
      if(!this.editedItem.baseKeyword){
        this.$Swal.fire({
          icon: 'error',
          text: '키워드를 입력해주세요.',
        })
        return false;
      }
      if (this.editedIndex > -1) { // 수정
        Object.assign(this.items[this.editedIndex], this.editedItem)

        for(let i = 0; i < this.selected_platform.length; i++)
        {
          this.selected_platform[i] = this.$root.getPlatformNameByHanName(this.selected_platform[i]);
        }

        const data = {
          media_id: this.media_id,
          platform: this.selected_platform,
          topic: this.editedItem.topic,
          keyword: this.editedItem.baseKeyword,
        };
        this.axios.put('api/v1/base-keywords/' + this.editedItem.id, data).then(res => {
          // 베이스 키워드 통합 후 데이터 갱신 변경.
          //this.items.push(this.editedItem)
          //this.medias(this.media_id);
          res;
          this.close();
          this.getData();
        }).catch(err => {
          console.error(err);
        });
      } else { //추가
        if(this.selected_platform.length < 1){
          this.$Swal.fire({
            icon: 'error',
            text: '플랫폼을 선택해주세요.',
          })
          return false;
        }

        for(let i = 0; i < this.selected_platform.length; i++)
        {
          this.selected_platform[i] = this.$root.getPlatformNameByHanName(this.selected_platform[i]);
        }

        const data = {
          media_id: this.media_id,
          keyword: this.editedItem.baseKeyword.split(','),
          topic: this.editedItem.topic,
          platform: this.selected_platform,
        };

        this.axios.post('api/v1/base-keywords', data
        ).then(res => {
          // 베이스 키워드 통합 후 데이터 갱신 변경.
          //this.items.push(this.editedItem)
          //this.medias(this.media_id);
          res;
          this.close();
          this.getData();
        }).catch(err => {
          console.error(err);
        });
      }
    },
    close() {
      this.dialog = false;
    },
    toggleState(id) {
      this.axios.put(`/api/v1/base-keywords/toggle-state/${id}`).then(
      ).catch(err => {
        console.error(err);
      });
    },
    toggleExposable(id) {
      this.axios.put(`/api/v1/base-keywords/toggle-exposable/${id}`).then(
      ).catch(err => {
        console.error(err);
      });
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      item.platforms.forEach(
          element => {
            this.selected_platform.push(element.platform);
          })
      this.dialog = true
    },
    deleteItem(item) {
      this.$Swal.fire({
        text: '삭제 하시겠습니까?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: '확인',
        denyButtonText: `취소`,
        confirmButtonColor: '#1550B2'
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete('api/v1/base-keywords/' + item.id, {
            data:{
              media_id: this.media_id,
              keyword: item.baseKeyword
            }}).then(res => {
            // 베이스 키워드 통합 후 데이터 갱신 변경.
            //this.items.push(this.editedItem)
            //this.medias(this.media_id);
            this.getData();

            res;
          }).catch(err => {
            console.error(err);
          });
        } else if (result.isDenied) {
          // this.$Swal.fire('취소 되었습니다.', '', 'info')
        }
      })
    },
    manualScrap() {
      this.$Swal.fire({
        text: '수집 스크래핑 진행하시겠습니까?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: '확인',
        denyButtonText: `취소`,
        confirmButtonColor: '#1550B2'
      }).then((result) => {
        if (result.isConfirmed) {
          const data = {
            media_id: this.media_id,
            // type: 'keyword',
          };
          this.axios.post('api/v1/admin/article/scrap', data).then(res => {
            this.$Swal.fire('수집 스크래핑 작업을 진행합니다. \n등록된 키워드가 많을수록 시간이 오래 걸립니다.', '', 'success')
            res;
          }).catch(err => {
            if (err.response.data.error.statusCode) {
              this.$Swal.fire({
                icon: 'error',
                text: err.response.data.error.message,
              }).then((result) => {
                result;
              }).catch(err => {
                console.error(err);
              });
            }
          });
        } else if (result.isDenied) {
          // this.$Swal.fire('취소 되었습니다.', '', 'info')
        }
      })
    },
    getPlatformList() {
      this.loading = true
      this.axios.get('api/v1/platforms?state=1')
          .then(res => {
            if (res.data.data.platforms.length > 0) {
              res.data.data.platforms.map((item) => {
                this.platform_list.push(item.platform_kr);
              })
            }
            this.loading = false
          })
          .catch(err => {
            console.error(err);
          });
    },
    searchChange() {
      this.page = 1;
      this.getData();
    },
    numberComma(value) {
      return numeral(value).format('0,0');
    },
    remove(item) {
      this.selected_platform.splice(this.selected_platform.indexOf(item), 1)
    },
  }
}
</script>

<style scoped>

</style>
