<template>
    <v-container>
        <Breadcrumbs :breadcrumbs="breadcrumbs"/>
        <v-row class="row mb-0 mt-1">
          <v-col class="col-lg-4 ma-0 row ">
            <v-row>
              <v-col cols="col-lg-4" sm="4">
                <v-select
                    :items="user_items"
                    v-model="media_id"
                    label="매체"
                    item-text="name"
                    item-value="id"
                    @change="medias"
                    hide-details
                ></v-select>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="col-lg-4" sm="4">
                <v-select
                    :items= "isignores"
                    item-text="name"
                    item-value="value"
                    label="제외여부"
                    v-model="is_ignore"
                    @change="getData"
                ></v-select>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="col-lg-4" sm="4">
              </v-col>
            </v-row>

          </v-col>
          <v-col class="col-lg-8 ma-0 justify-end" >
            <v-text-field class="float-right"
                          style="max-width: 220px;"
                          label="검색"
                          v-model="search"
                          append-icon="mdi-magnify"
                          @keyup.native.enter="searchChange()"
                          hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider class="indigo mt-2 mb-4"></v-divider>
        <v-row v-if="loading" class="justify-center" :loading="loading">
          <spinner :loading="loading"></spinner>
        </v-row>
        <v-row v-else class="pt-0">
          <v-col v-if="this.searchCount === 0" justify="center" align="center"><!-- 검색겨로가가 없을경우 -->
            <span class="subtitle-1">데이터가 존재하지 않습니다.</span>
          </v-col>
          <v-col v-else>
            <!-- <table class="type10"> -->
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center" style="width : 20% !important;">No.</th>
                    <th class="text-center" style="width : 20% !important;">매체</th>
                    <th class="text-center" style="width : 35% !important;">태그</th>
                    <th class="text-left" style="width : 10% !important;">적용여부</th>
                    <th class="text-center" style="width : 10% !important;">삭제</th>
                  </tr>
                </thead>
              <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <td class="text-center" style="width : 20% !important;">{{ item.no }}</td>
                <td class="text-center" style="width : 20% !important;">{{ item.media_name }}</td>
                <td class="text-center" style="width : 35% !important;">{{ item.tag }}</td>
                <td class="text-left" style="width : 10% !important;">
                    <v-switch
                      v-model="item.ignore"
                      color="indigo"
                      class="toggle-btn-ignore my-2"
                      hide-details
                      @change="toggleIgnoreStatus(item)"
                  ></v-switch>
                </td>
                <td class="text-center" style="width : 10% !important;">
                  <v-btn
                      plain
                      color="white"
                      style="min-height:28px; max-width: 26px;"
                      class="my-1"
                      small
                      @click="tagIgnoreDelete(item.tag, item.media_id)"
                  >
                    <v-icon
                        color="rgb(255,0,0)"
                    >
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
              </tbody>
              </template>
            </v-simple-table>
            <!-- </table> -->
                <v-pagination
                    v-if="this.searchCount > per_page"
                    class="mt-5 mb-5"
                    v-model="page"
                    :length="last_page"
                    circle
                    color="#1550B2"
                    prev-icon="mdi-chevron-left"
                    next-icon="mdi-chevron-right"
                    :total-visible="10"
                    @input="getData()"
                ></v-pagination>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Breadcrumbs from "../../components/Breadcrumbs";
import Spinner from "../../components/Spinner";
import {mapGetters} from "vuex";
import numeral from "numeral";

export default {
    components: {
        Breadcrumbs,
        Spinner,
    },
    data() {
      return {
          loading: false,
          breadcrumbs: [
              {
                  text: '키워드 (PK) 제외',
                  disabled: false,
                  href: '/statistic-tag-ignores',
              },
          ],
          user_items: [],
          isignores: [
              { name: "전체", value: null },
              { name: "적용", value: 1 },
              { name: "적용 안함", value: 0 }
          ],
          is_ignore:null,
          items: [],
          media_id: null,
          page: 1,
          per_page: 16,
          last_page: 1,
          search: '',
          searchCount: '', // total 또는 검색했을 때 값
        }
    },
    computed: {
      ...mapGetters({
        user: 'auth/user',
      }),
      getMediaId() {
        return this.$store.getters.getMediaId;
      },
    },
    watch: {
      media_id() {
        this.$store.commit("SET_MEDIA_ID", this.media_id);
      },
    },
    mounted() {
        this.media_id = this.getMediaId;
        if (localStorage.getItem('token')) {
            this.user_items = this.user.user_media;
        }

        if (this.$route.query.search) {
          this.search = this.$route.query.search;
        }
        this.getData();
    },
    methods: {
        medias(media) {
            this.page = 1;
            this.media_id = media;
            if (typeof this.media_id === "undefined") {
                this.media_id = this.user.user_media[0].id;
            } else {
                this.media_id = media;
            }
            this.getData();
        },
        getData() {
            let result = [];
            let header = '';
            this.loading = true;

            const params = {
                'media_id': this.media_id,
                'page': this.page,
                'perPage': this.per_page,
                'is_ignore':this.is_ignore,
                'search': this.search ? this.search : '',
            };
            this.axios.get('api/v1/tags/ignore', {
                params,
                headers: {
                    'c9': header
                }
            }).then(res => {
                this.searchCount = res.data.data.count; // total 또는 검색 했을 때 개수
                if (res.data.data.count > 0) {
                      res.data.data.tags.map((item, index) => {
                          let no = res.data.data.count - (this.page - 1) * this.per_page - index;
                          if(item.count === null){
                            item.count = 0;
                          }
                          result.push({
                              no: this.numberComma(no),
                              id: item.id,
                              media_id: item.media_id,
                              media_name: item.media_name,
                              tag: item.tag,
                              ignore:item.is_ignore,
                          })
                      })
                    this.items = result;
                    if (Number.isInteger(res.data.data.count / this.per_page) == false) {
                        this.last_page = Math.floor(res.data.data.count / this.per_page) + 1;
                    } else {
                        this.last_page = res.data.data.count / this.per_page;
                    }
                }
                this.loading = false;
            })
            .catch(err => {
                console.error(err);
            });
        },

        searchChange() {
            this.page = 1;
            this.getData();
        },

        numberComma(value) {
            return numeral(value).format('0,0');
        },

        tagIgnoreDelete(tag, mediaid) {
            this.axios.delete('api/v1/tags/ignore', {
                data:[{
                    media_id: mediaid,
                    tag: tag
                }]
            }).then(res=>{
                this.getData();
                res;
            }).catch(err => {
                console.error(err);
            });

        },

      toggleIgnoreStatus(item) {
        this.axios.put(`/api/v1/tags/ignore/toggle-ignore/${item.id}`).then(
        ).catch(err => {
          console.error(err);
        });
      },
    }
}
</script>

<style scoped>
table.type10 {
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12)!important;
  width: 99%;
  text-align: left;
  line-height: 1.5;
  overflow-x: hidden;
  border-spacing: 0;
}
table.type10 thead th {
  width: 150px;
  padding: 10px;
  vertical-align: top;
  color: rgba(0,0,0,.6);
  margin: 20px 10px;
  border-bottom: thin solid rgba(0,0,0,.12);
  font-size: .75rem;
}
table.type10 tbody th {
  width: 150px;
  padding: 10px;
  font-size: .75rem;
}
table.type10 td {
  width: 350px;
  padding: 00px;
  vertical-align: middle;
  border-bottom: thin solid rgba(0,0,0,.12);
  font-size: .875rem;
  line-height: 2.5;
}
table.type10:hover tbody tr:hover td {
  background: #eeeeee;
  color: black;
}
</style>
