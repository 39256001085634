<template>
  <v-container>
    <Breadcrumbs :breadcrumbs="breadcrumbs"/>
    <div class="font-weight-bold mt-0">
      <span class="pt-0" style="color: gray;font-size: 12px;">최고관리자만 사용할 수 있는 메뉴입니다.순위는 100위까지 보여주며, <span style="text-decoration: underline; color: #3b3b3b;">회원/비회원</span>이 해당 기간 동안 접속한 횟수를 집계하여 순위를 보여줍니다.</span>
      <span class="pt-0" style="color: gray;font-size: 12px;"> (사용자 아이디를 클릭하면, 사용자 검색 메뉴로 넘어갑니다.)</span>
    </div>
    <v-row class="row mb-0 mt-1">
      <v-col class="col-4 ma-0 row ">
        <v-select style="max-width: 150px;"
                  :items="user_items"
                  v-model="media_id"
                  label="매체"
                  item-text="name"
                  item-value="id"
                  @change="medias"
                  hide-details
        ></v-select>
      </v-col>
      <v-col class="col-8 ma-0 row justify-end ">
        <v-select style="max-width: 150px;" class="mr-3"
                  :items="sort_items"
                  v-model="sort"
                  label="정렬 기준"
                  item-text="sort"
                  item-value="value"
                  @change="sortChange"
                  hide-details
        ></v-select>
        <v-menu
            v-model="datepicker"
            :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <v-text-field class="row-pointer mr-2"
                          style="max-width: 110px;"
                          label="시작일"
                          append-icon="mdi-calendar"
                          v-on="on"
                          offset-y
                          :value="dateRanges[0]"
                          readonly
                          hide-details
            >
            </v-text-field>
          </template>
          <v-date-picker
              v-model="dateRanges[0]"
              no-title
              locale="ko-kr"
              changeMonth="true"
              :max="today"
              @input="dateSearchChange"
          />
        </v-menu>
        <p style="margin-top: 20px !important;">~</p>
        <v-menu
            v-model="end_datepicker"
            :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <v-text-field class="row-pointer ml-2 mr-2"
                          style="max-width: 110px;"
                          label="종료일"
                          append-icon="mdi-calendar"
                          v-on="on"
                          offset-y
                          :value="dateRanges[1]"
                          readonly
                          hide-details
            >
            </v-text-field>
          </template>
          <v-date-picker
              v-model="dateRanges[1]"
              no-title
              locale="ko-kr"
              changeMonth="true"
              :max="today"
              @input="dateSearchChange"
          />
        </v-menu>
      </v-col>
    </v-row>
    <v-divider class="indigo mt-2 mb-4"></v-divider>
    <v-row v-if="loading" class="justify-center"
           :loading="loading"
    >
      <spinner :loading="loading"></spinner>
    </v-row>
    <v-row class="pt-3 justify-center" >
      <template>
        <v-card
            style="width: 99%;overflow: auto;overflow-x: hidden;"
            :min-height="respHeight"
            :max-height="respHeight"
        >
          <table class="type10">
            <thead>
            <tr>
              <th class="text-center" style="width : 20% !important;">순위</th>
              <th class="text-center" style="width : 60% !important;">사용자 아이디</th>
              <th class="text-center" style="width : 20% !important;">접속 횟수</th>
            </tr>
            </thead>
            <tbody v-if="this.items.length > 0">
            <tr v-for="(item, index) in items" :key="index"  @click="moreView(item.user_id)">
              <td class="text-center"> <v-icon v-if="item.no === 1" color="rgb(255 190 0)">mdi-crown-outline</v-icon>{{ item.no }}위</td>
              <td class="text-center" v-if="item.user_id.length === 36" style="color: #283593 !important;" >{{ item.user_id }}</td>
              <td class="text-center" v-else style="color: rgb(122 121 121) !important;">{{ item.user_id }}</td>
              <td class="text-center">{{ item.count }}회</td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="3" class="text-center">해당 날짜에는 데이터가 없습니다.</td>
            </tr>
            </tbody>
          </table>
        </v-card>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import Breadcrumbs from "../../components/Breadcrumbs";
import Spinner from "../../components/Spinner";
import {mapGetters} from "vuex";
import numeral from 'numeral';
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import dayjs from "dayjs";

am4core.useTheme(am4themes_animated);

export default {
  components: {
    Breadcrumbs,
    Spinner,
  },
  data() {
    return {
      loading: false,
      breadcrumbs: [
        {
          text: '접속자 순위',
          disabled: false,
          href: '/statistic-content-ranks',
        },
      ],
      article_pv_length: 0,
      top_count: 10,
      per_page: 2,
      page: 1,
      items: [],
      user_items: [],
      media_id: null,
      datepicker: false,
      end_datepicker: false,
      dateRanges : [],
      sort: '',
      sort_items: [
        {sort: '전체', value: ''},
        {sort: '회원', value: 'member'},
        {sort: '비회원', value: 'none_member'},
      ],
      date_type_text : '일',
      // yesterday : dayjs(dayjs().format("YYYY-MM-DD")).subtract(1, 'day').format('YYYY-MM-DD'),
      today : dayjs().format("YYYY-MM-DD"),
      respHeight:780,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    getPage() {
      return this.$store.getters.getPage;
    },
    getMediaId() {
      return this.$store.getters.getMediaId;
    },
    // getDateRanges() {
    //   return this.$store.getters.getDateRanges;
    // },
  },
  watch: {
    dateRanges: {
      handler() {
        // this.$store.commit("SET_DATERANGES", this.dateRanges);
        // if(!this.dateRanges[0] && this.dateRanges[1]){
        //   this.$store.commit("SET_DATERANGES", []);
        // }
        // if (this.dateRanges[0] > this.dateRanges[1]) {
        //   this.dateRanges.reverse();
        //   this.$store.commit("SET_DATERANGES", this.dateRanges);
        //   this.dateSearchChange();
        // }
        if(this.getDateRanges[0] !== this.dateRanges[0] || this.getDateRanges[1] !== this.dateRanges[1]) {
          if(!this.dateRanges[0])  this.dateRanges[0] = this.getDateRanges[0];
          if(!this.dateRanges[1])  this.dateRanges[1] = this.getDateRanges[1];
          // this.$store.commit("SET_DATERANGES", this.dateRanges);
        }
      }
    },
    page() {
      this.$store.commit("SET_PAGE", this.page);
    },
    media_id() {
      this.$store.commit("SET_MEDIA_ID", this.media_id);
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    window.dispatchEvent(new Event('resize'));

    console.log(this.dateRanges);
    this.dateRanges[0] = dayjs(dayjs().format("YYYY-MM-DD")).subtract(30, 'day').format('YYYY-MM-DD');
    this.dateRanges[1] = this.today;
    // this.$store.commit("SET_DATERANGES", this.dateRanges);


    const mediaId = this.media_id;
    if (mediaId) {
      this.media_id = parseInt(mediaId);
    } else {
      this.media_id = this.user.user_media[0].id;
    }

    if(this.getMediaId && this.getMediaId !== this.media_id) this.media_id = this.getMediaId;

    if(this.getPage !== 1) this.page = this.getPage;
    if(this.getPage === 1 && this.page !== this.getPage) this.page = 1;

    // if(!this.dateRanges[0] && this.dateRanges[1]){
    //   this.$store.commit("SET_DATERANGES", []);
    // }

    if (localStorage.getItem('token')) {
      this.user_items = this.user.user_media;
    }
    this.changeDatas();

  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      //this.windowWidth = window.innerWidth;
      this.respHeight = window.innerHeight - 300;
    },
    medias(media) {
      this.changePages();
      this.media_id = media;
      if (typeof this.media_id === "undefined") {
        this.media_id = this.user.user_media[0].id;
      } else {
        this.media_id = media;
      }
      this.changeDatas();
    },
    numberComma(value) {
      return numeral(value).format('0,0');
    },
    getData(){ // 접속자 순위
      let header = this.$root.getMediaEngNameById(this.media_id);
      let result = [];
      this.loading = true;
      this.axios.get('api/v1/users/visit', {
        params: {
          'media_id': parseInt(this.media_id),
          'start_date': this.dateRanges ? this.dateRanges[0] : '',
          'end_date': this.dateRanges ? this.dateRanges[1] : '',
          'sort': this.sort,
        },
        headers: {
          'c9': header
        }
      })
          .then(res => {
            this.loading = false;
            if (res.data.data.data.length > 0) {
              res.data.data.data.map((item, index) => {
                result.push({
                  no: index + 1,
                  user_id: item.key,
                  count: item.doc_count,
                })
              })
              this.items = result;

            }else{
              this.items = [];
            }
            this.loading = false;
          })
          .catch(err => {
            this.items = [];
            this.loading = false;

            console.error(err);
            //location.reload();
          });
    },
    dateSearchChange() {
      this.changePages();
      if (this.dateRanges[0] && this.dateRanges[1]) {
        this.changeDatas();
      } else {
        if(this.dateRanges[0]){
          this.changeDatas();
          this.dateRanges[1] = this.today;
        }else{
          this.$Swal.fire({
            icon: 'error',
            text: '시작 날짜를 선택해주세요.',
          }).then((result) => {
            if (result.isConfirmed) {
              location.reload();
            }
          }).catch(err => {
            console.error(err);
          });
        }
      }
      this.datepicker = false;
      this.end_datepicker = false;
    },
    changeDatas(){
      this.getData();
    },
    changePages(){
      this.page = 1;
    },
    moreView(value) {
      this.$router.push('/statistic-user-search?search='+value);
    },
    getDay(date){
      const week = ['일', '월', '화', '수', '목', '금', '토'];
      return week[new Date(date).getDay()];
    },
    sortChange(value) {
      this.sort = value;
      this.changeDatas();
    },
  }
}
</script>

<style scoped>
.font-member {
  color: #283593 !important;
}
.font-non-member {
  color: rgb(122 121 121) !important;
}
table.type10 {
  width: 99%;
  border-collapse: collapse;
  text-align: left;
  line-height: 1.5;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin: 20px 10px;
  overflow-x: hidden;
}
table.type10 thead th {
  width: 150px;
  padding: 10px;
  font-weight: bold;
  vertical-align: top;
  color: #fff;
  background: #283593!important;
  margin: 20px 10px;
}
table.type10 tbody th {
  width: 150px;
  padding: 10px;
}
table.type10 td {
  width: 350px;
  padding: 10px;
  vertical-align: top;
  border-bottom: 1px solid #ccc;
}
table.type10:hover tbody tr:hover td {
  cursor: pointer;
  background: #f0f6fd;
  color: black;
}
</style>
