<template>
    <v-container>
        <Breadcrumbs :breadcrumbs="breadcrumbs"/>
        <v-row class="row mb-0 mt-1">
          <v-col class="col-lg-4 ma-0 row ">
            <v-select style="max-width: 150px;"
                :items="user_items"
                v-model="media_id"
                label="매체"
                item-text="name"
                item-value="id"
                @change="medias"
                hide-details
            ></v-select>
          </v-col>
          <v-col class="col-lg-8 ma-0 justify-end" >
            <v-text-field class="float-right"
                  style="max-width: 220px;"
                  label="검색"
                  v-model="search"
                  append-icon="mdi-magnify"
                  @keyup.native.enter="searchChange()"
                  hide-details
            ></v-text-field>
            <v-toolbar style="float: right;"
                flat
            >
              <v-file-input
                  truncate-length="15"
                  style="width: 150px"
                  class="mt-2 mr-2"
                  label="file"
                  accept=".csv"
                  v-model="file"
              ></v-file-input>
              <v-btn
                  :loading="upload_loading"
                  :disabled="upload_loading"
                  @click="upload()"
                  color="#1550B2"
                  class="mb-2 mr-2"
                  outlined
              >
                업로드
                <v-icon
                    right
                    dark
                    rounded
                >
                  mdi-cloud-upload
                </v-icon>
              </v-btn>
              <v-btn
                  color="#1550B2"
                  class="mb-2 mr-2"
                  outlined
                  href="/files/tags-test.csv"
              >
                <v-icon
                >
                  mdi-download
                </v-icon>
              </v-btn>
              <v-dialog
                  v-model="dialog"
                  max-width="500px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                      outlined
                  >
                    태그
                    <v-icon
                        right
                        dark
                    >
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col>
                          <v-textarea
                              v-model="editedItem.tag"
                              label="* 태그가 여러개일 경우 쉼표로 구분해주세요."
                              outlined
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="white--text"
                        color="#283593"
                        @click="save"
                    >
                      저장
                    </v-btn>
                    <v-btn
                        class="white--text"
                        color="#E23C44"
                        @click="close"
                    >
                      취소
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </v-col>
        </v-row>
        <v-divider class="indigo mt-2 mb-4"></v-divider>
        <v-row v-if="loading" class="justify-center"
               :loading="loading"
        >
          <spinner :loading="loading"></spinner>
        </v-row>
        <v-row v-else class="pt-0">
          <v-col v-if="this.searchCount === 0"
                 justify="center" align="center"
          >
            <span class="subtitle-1">데이터가 존재하지 않습니다.</span>
          </v-col>
          <v-col v-else>
<!--            <v-data-table
                hide-default-footer
                :headers="headers"
                :items="items"
                class="elevation-1"
                :items-per-page="per_page"
            >
              <div class="text-center pt-2">
                <v-btn color="primary" class="mr-2" @click="toggleOrder">Toggle sort order</v-btn>
                <v-btn color="primary" @click="nextSort">Sort next column</v-btn>
              </div>
              <template v-slot:item.actions="{ item }">
                  <v-icon
                      small
                      class="mr-1"
                      @click="editItem(item)"
                  >
                      mdi-pencil
                  </v-icon>
              </template>
            </v-data-table>-->
            <table class="type10">
              <thead>
              <tr>
                <th class="text-left" style="width : 15% !important;">No.</th>
                <th class="text-left" style="width : 30% !important;">태그</th>
                <th class="text-center" style="width : 20% !important;">수집수
                  <v-icon small @click="toggleOrder" v-if="this.sort === 'count_asc'">mdi-arrow-up</v-icon>
                  <v-icon small @click="toggleOrder" v-else>mdi-arrow-down</v-icon>
                </th>
                <th class="text-center" style="width : 25% !important;">등록일</th>
                <th class="text-left" style="width : 10% !important;">외부 노출</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <td class="text-left" style="padding-top: 0px; padding-bottom: 0px;">{{ item.no }}</td>
                <td class="text-left" style="padding-top: 0px; padding-bottom: 0px;">{{ item.tag }}</td>
                <td class="text-center" style="padding-top: 0px; padding-bottom: 0px;">{{ item.article_count }}</td>
                <td class="text-center" style="padding-top: 0px; padding-bottom: 0px;">{{ item.created_at }}</td>
                <td class="text-left" style="padding-top: 0px; padding-bottom: 0px;">
                  <v-switch
                    v-model="item.is_exposable"
                    color="indigo"
                    class="toggle-btn-exposable my-2"
                    hide-details
                    inset
                    dense
                    :loading="item.is_loading"
                    @change="toggle_exposable(item)"
                  ></v-switch>
                </td>
              </tr>
              </tbody>
            </table>
            <v-pagination
                v-if="this.searchCount > per_page"
                class="mt-5 mb-5"
                v-model="page"
                :length="last_page"
                circle
                color="#1550B2"
                prev-icon="mdi-chevron-left"
                next-icon="mdi-chevron-right"
                :total-visible="10"
                @input="getData()"
            ></v-pagination>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Breadcrumbs from "../../components/Breadcrumbs";
import Spinner from "../../components/Spinner";
import {mapGetters} from "vuex";
import numeral from "numeral";

export default {
    components: {
        Breadcrumbs,
        Spinner,
    },
    data() {
        return {
            sort: 'count_desc',
            sortDesc: false,
            loading: false,
            breadcrumbs: [
                {
                    text: '키워드 (PK) 관리',
                    disabled: false,
                    href: '/tags',
                },
            ],
            user_items: [],
            items: [],
            media_id: null,
            dialog: false,
            upload_loading: false,
            file: null,
            headers: [
                {text: 'NO.', sortable: false, value: 'no', width: '300px'},
                {text: '태그', sortable: false, value: 'tag', width: '400px'},
                {text: '수집수', sortable: true, value: 'article_count', width: '300px'},
                {text: '등록일', sortable: false, value: 'created_at', width: '300px'},
                {text: '외부 노출', sortable: false, value: 'is_exposable', width: '100px'},
            ],
            editedItem: {
                media_id: 0,
                tag: '',
            },
            defaultItem: {
                media_id: '',
                article_count: 0,
                created_at: '',
                is_exposable: false,
                is_loading: false,
            },
            editedIndex: -1,
            page: 1,
            per_page: 16,
            last_page: 1,
            search: '',
            searchCount: '', // total 또는 검색했을 때 값
        }
    },
    computed: {
      formTitle() {
        return this.editedIndex === -1 ? '태그 추가' : '태그 수정'
      },
      ...mapGetters({
        user: 'auth/user',
      }),
      getMediaId() {
        return this.$store.getters.getMediaId;
      },
    },
    watch: {
      media_id() {
        this.$store.commit("SET_MEDIA_ID", this.media_id);
      },
    },
    mounted() {
        this.media_id = this.getMediaId;
        const mediaId = this.media_id;
        if (mediaId) {
            this.media_id = parseInt(mediaId);
        } else {
            this.media_id = this.user.user_media[0].id;
        }

        if (localStorage.getItem('token')) {
            this.user_items = this.user.user_media;
        }

        if (this.$route.query.search) {
          this.search = this.$route.query.search;
        }
        this.getData();
    },
    methods: {
        medias(media) {
            this.page = 1;
            this.media_id = media;
            if (typeof this.media_id === "undefined") {
                this.media_id = this.user.user_media[0].id;
            } else {
                this.media_id = media;
            }
            this.getData();
        },
        getData() {
            let result = [];
            let header = '';
            this.loading = true;
            if (this.media_id === 1) {
                header = 'bloodpressure';
            } else if (this.media_id === 2) {
                header = 'golfzip';
            } else if (this.media_id === 3) {
                header = 'ticketoffice';
            } else if (this.media_id === 4) {
                header = 'tingstar';
            } else if (this.media_id === 5) {
                header = 'sivillage';
            }
            this.axios.get('api/v1/tags', {
                params: {
                    'media_id': this.media_id,
                    'page': this.page,
                    'per_page': this.per_page,
                    'search': this.search ? this.search : '',
                    'sort': this.sort,
                },
                headers: {
                    'c9': header
                }
            })
            .then(res => {
              this.searchCount = res.data.data.totalCount; // total 또는 검색 했을 때 개수
              if (res.data.data.totalCount > 0) {
                    res.data.data.tags.map((item, index) => {
                        let no = res.data.data.totalCount - (this.page - 1) * this.per_page - index;
                        if(item.count === null){
                          item.count = 0;
                        }
                        result.push({
                            no: this.numberComma(no),
                            id: item.id,
                            media_id: item.media_id,
                            tag: item.tag,
                            article_count: this.numberComma(item.count)+"개", // 수집수
                            created_at: item.created_at, // 등록일
                            is_exposable: item.is_exposable,
                            is_loading: false,
                        })
                    })
                }
                this.items = result;
                if (Number.isInteger(res.data.data.totalCount / this.per_page) == false) {
                  this.last_page = Math.floor(res.data.data.totalCount / this.per_page) + 1;
                } else {
                  this.last_page = res.data.data.totalCount / this.per_page;
                }
                this.loading = false;
            })
            .catch(err => {
                console.error(err);
            });
        },
        save() {
            if (this.editedIndex > -1) {
                Object.assign(this.items[this.editedIndex], this.editedItem)
                const data = {
                    media_id: this.media_id,
                    tag: this.editedItem.tag,
                };
                this.axios.put('api/v1/tags/' + this.editedItem.id, data).then(res => {
                    this.medias(res.data.data.media_id);
                }).catch(err => {
                    console.error(err);
                });
            } else {
                this.items.push(this.editedItem)
                const data = {
                    media_id: this.media_id,
                    type: 'tag',
                    data: this.editedItem.tag
                };
                this.axios.post('api/v1/tags/text', data).then(res => {
                    //console.log(res);
                    res;
                    this.medias(this.media_id);
                }).catch(err => {
                    console.error(err);
                });
            }
            this.close();
        },
        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        editItem(item) {
            this.editedIndex = this.items.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },
        upload() {
            if (this.file) {
                this.upload_loading = true;
                let formData = new FormData()
                formData.append('media_id', this.media_id ?? null)
                formData.append('type', 'tag')
                formData.append('file', this.file ?? null)
                this.axios.post('api/v1/tags/csv', formData, {
                    headers: {
                        'content-type': 'multipart/form-data'
                    },
                }).then(res => {
                    this.upload_loading = false;
                    this.file = null;
                    this.medias(res.data.data.media_id);
                }).catch(err => {
                    console.error(err);
                });
            } else {
                this.$Swal.fire({
                    icon: 'error',
                    text: '파일을 선택해주세요.',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.upload_loading = false;
                    }
                }).catch(err => {
                    console.error(err);
                });
            }
        },
        searchChange() {
          this.page = 1;
          this.getData();
        },
        numberComma(value) {
          return numeral(value).format('0,0');
        },
        toggleOrder() {
          if(this.sort === 'count_asc') this.sort = 'count_desc';
          else this.sort = 'count_asc';
          //console.log(this.sort);
          this.getData();
        },
        toggle_exposable(item) {
          item.is_loading = 'warning';
          this.axios.put(`/api/v1/tags/toggle-exposable/${item.id}`)
            .then((res) => {
              this.items[this.items.indexOf(item)].is_exposable = res.data.data.is_exposable;
              item.is_loading = false;
            });
        },
    }
}
</script>

<style scoped>
table.type10 {
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12)!important;
  width: 99%;
  text-align: left;
  line-height: 1.5;
  overflow-x: hidden;
  border-spacing: 0;
}
table.type10 thead th {
  width: 150px;
  padding: 10px;
  vertical-align: top;
  color: rgba(0,0,0,.6);
  margin: 20px 10px;
  border-bottom: thin solid rgba(0,0,0,.12);
  font-size: .75rem;
}
table.type10 tbody th {
  width: 150px;
  padding: 10px;
  font-size: .75rem;
}
table.type10 td {
  width: 350px;
  height: 40px;
  padding: 10px;
  vertical-align: middle;
  border-bottom: thin solid rgba(0,0,0,.12);
  font-size: .875rem;
  line-height: 2.5;
}
table.type10:hover tbody tr:hover td {
  background: #eeeeee;
  color: black;
}

</style>
