<template>
    <v-breadcrumbs :items="breadcrumbs"  style="padding: 0;" class="pa-0 pb-2">
        <template v-slot:item="{ item }">
            <v-breadcrumbs-item
                v-if="item.value === 'back'"
                style="cursor:pointer"
                @click="$router.go(-1)"
            >
              <h2>{{item.text}}</h2>
            </v-breadcrumbs-item>
            <v-breadcrumbs-item
                v-else
                :disabled="item.disabled"
            >
                <h2>{{ item.text}}</h2>
            </v-breadcrumbs-item>
        </template>
        <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
        </template>
    </v-breadcrumbs>
</template>

<script>
export default {
    props: {
        breadcrumbs: [],
    }
}
</script>

<style scoped>

</style>
