import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"; // 새로고침 시, 유지

import auth from './auth'
import contact from './contact'
Vue.use(Vuex)

export default new Vuex.Store({
	plugins: [
		createPersistedState({
			storage: window.sessionStorage // store를 session storage 에 유지
		})
	],
	state: {
		errors: false,
		publicData:[],
		mediaId: null,
		platform: [],
		type: [],
		dateRanges: [],
		dateChoice: 'daily',
		search: '',
		sort: 'sort_id',
		page: 1,
		pageModal: 1,
		medias: [],
	},

	getters: {
		errors: state => {
			return state.errors;
		},
		getMediaId(state) {
			return state.mediaId;
		},
		getMedias(state) {
			return state.medias;
		},
		getPlatform(state) {
			return state.platform;
		},
		getType(state) {
			return state.type;
		},
		getSearch(state) {
			return state.search;
		},
		getDateRanges(state) {
			return state.dateRanges;
		},
		getPage(state) {
			return state.page;
		},
		getPageModal(state) {
			return state.pageModal;
		},
		getSort(state) {
			return state.sort;
		},
		getDateChoice(state) {
			return state.dateChoice;
		},
	},

	mutations: {
		SET_ERROR (state, errors) {
			state.errors = errors
		},
		SET_MEDIA_ID(state, payload) {
			state.mediaId = payload;
		},
		SET_PLATFORM(state, payload) {
			state.platform = payload;
		},
		SET_TYPE(state, payload) {
			state.type = payload;
		},
		SET_SEARCH(state, payload) {
			state.search = payload;
		},
		SET_DATERANGES(state, payload) {
			state.dateRanges = payload;
		},
		SET_PAGE(state, payload) {
			state.page = payload;
		},
		SET_PAGE_MODAL(state, payload) {
			state.pageModal = payload;
		},
		SET_SORT(state, payload) {
			state.sort = payload;
		},
		SET_DATECHOICE(state, payload) {
			state.dateChoice = payload;
		},
	},

	actions: {
		setError ({ commit }, errors) {
			commit('SET_ERROR', errors)
		},
	},

	modules: {
		auth,
		contact,
	}
})
