import axios from 'axios'
import store from '../store'

store.subscribe((mutation) => {
    switch (mutation.type) {
        case 'auth/SET_TOKEN':
            if (mutation.payload) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${mutation.payload}`
                localStorage.setItem('token', mutation.payload)
                localStorage.setItem('Authorization', 'eyJpdiI6ImRna2YyMi9jWkx2RmJMcC8zQ1ZqOXc9PSIsInZhbHVlIjoiQitMZUFGWHZ5eXp4cCsyemtsa2l3Zz09IiwibWFjIjoiMGEzMjdjODNiZmI0YzZkOTI3NjhmZmQ4YTkyNTFkMzA5MzYyMzNiYmIxZjA5YzljNWIxMWI0YjQxMjkyNTQ5NyJ9')
                localStorage.setItem('c9', '')
            } else {
                axios.defaults.headers.common['Authorization'] = null
                localStorage.removeItem('token', null)
            }

            break;
    }
})
