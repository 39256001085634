
<template>
  <v-overlay z-index="100" style="max-width: 100%">
    <v-card style="background-color: #ffffff; color: #000000;"
        class="mx-auto modal_card"
        height="auto"
        outlined
    >
      <v-list-item three-line>
        <v-list-item-content class="pb-0" >
          <div class="text-overline mb-4" style="color: #000000;">
            <v-icon
                mid
                color="#000000"
            >
              {{ item.icon }}
            </v-icon>
            {{ item.platform }}

            <v-btn style="color: black; float: right;"
                   small
                   outlined
                   @click="moreView"
            >
              <v-icon
                  small
              >
                mdi-text-search
              </v-icon>
              상세보기
            </v-btn>
          </div>
          <v-list-item-title class="text-h6 mb-1" style="color: #000000;">
            {{ item.title }}
          </v-list-item-title>
          <v-list-item-subtitle style="color: #000000;">{{ item.contents }}</v-list-item-subtitle>
          <v-list-item-subtitle style="color: #000000;" v-if="item.url">
            <p class="float-right ma-0">
              <v-btn style="text-decoration: underline darkgray;font-size:.875rem!important; padding: 0;color: #000000;"
                   text
                   small
                   :href="item.url" target="_blank"
                   elevation="0"
              >
                URL 열기
              </v-btn>
            </p>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item-content v-if="item.platform === 'instagram' && !item.article_medias[0].is_thumbnail">
        <v-carousel
            v-model="media_modal"
            show-arrows-on-hover
            height="400"
        >
          <v-carousel-item
              v-for="(media, i) in item.article_medias"
              :key="i"
          >
            <v-row class="pa-5"
                   style="background-color: black; color: #ffffff;"
            >
              <div class="subtitle-2">
                {{ i + 1 }}. 이미지 가로 : {{ media.width }} / 이미지 세로 : {{ media.height }}
              </div>
              <v-spacer></v-spacer>
              <v-btn
                  @click="choiceThumbnail(item.id, media.not_full_storage_url)"
                  color="success"
                  dark
              >
                썸네일로 지정하기
              </v-btn>
            </v-row>
            <v-img style="background-position: center center;max-width: 400px"
                   :src="media.storage_url"
                   tile
            >
            </v-img>
          </v-carousel-item>
        </v-carousel>
      </v-list-item-content>
      <v-list-item-content v-else>
        <v-card elevation="0">
          <v-card-title class="subtitle-2">
            이미지 가로 : {{ item.thumbnail_width }} / 이미지 세로 : {{ item.thumbnail_height }}
          </v-card-title>
          <v-img style="max-height: 300px;"
              :src="item.storage_thumbnail_url"
              class="grey darken-4"
          ></v-img>
        </v-card>
      </v-list-item-content>
      <v-card-actions>

<!--        <v-btn style="color: black; float: right;"-->
<!--               small-->
<!--               outlined-->
<!--               @click="deleteArticle"-->
<!--               color="red"-->
<!--        >-->
<!--          삭제하기-->
<!--        </v-btn>-->

<!--        <v-spacer></v-spacer>-->

        <span class="subtitle-2">{{ item.diff_sort_text }} : {{ item.diff_date }}</span>
        <v-spacer></v-spacer>
        <v-btn style="color: black; "
             small
             outlined
             @click="closeModal"
        >
          <v-icon
              small
          >
            mdi-close
          </v-icon>
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>



<script>
export default {
    props: ['item','mediaId'],
    data() {
      return {
        media_modal: 0, // 이미지 개수
        thumbnail: '',
      }
    },
    methods: {
      closeModal: function () {
        this.$emit('close');
      },
      moreView: function () {
        this.$emit('moreView', this.item);
      },
      deleteArticle: function () {
        this.$emit('deleteArticle', this.item);
      },
      choiceThumbnail(id, thumbnail) {
        let header = {};
        const data = {
          article_id: id,
          thumbnail: thumbnail,
          media_id: this.mediaId,
        };
        if(confirm("썸네일을 변경하시겠습니까?")){
          let mediaIdKind = this.$root.getMediaEngNameById(this.mediaId);
          if(mediaIdKind !== ''){
            header = {
              headers: {
                'c9': mediaIdKind,
              }
            }
          }
          this.axios.put('api/v1/admin/articles/medias/thumbnail', data, header).then(res => {
            //console.log(res);
            res;
          }).catch(err => {
            console.error(err);
          });
          this.$emit('refresh');
        }
      },
    }

}
</script>
<style leng="css" scoped>
.v-list-item__content{
  max-width:1200px;
  width:calc(100% - 32px);
  margin: 0 auto;
}
</style>