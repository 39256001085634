<template>
    <v-container>
      <Breadcrumbs :breadcrumbs="breadcrumbs"/>
      <div class="font-weight-bold mt-0"><span class="pt-0" style="color: gray;font-size: 12px;">최고관리자만 사용할 수 있는 메뉴입니다. 매체를 선택 후, 사용자를 추가하면 해당 매체에 신규 사용자가 등록됩니다.</span></div>
      <v-row class="row mb-0 mt-1">
          <v-col class="col-lg-4 ma-0 row ">
            <v-select style="max-width: 150px;"
                      :items="user_items"
                      v-model="media_id"
                      label="매체"
                      item-text="name"
                      item-value="id"
                      @change="medias"
                      hide-details
            ></v-select>
          </v-col>
          <v-col class="col-lg-8 ma-0 row justify-end" style="flex-wrap: nowrap;">
              <v-dialog
                  v-model="dialog"
                  max-width="500px"
              >
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          class="ml-2 mr-1 mt-3"
                      >
                          사용자
                          <v-icon
                              right
                              dark
                          >
                              mdi-account-plus
                          </v-icon>
                      </v-btn>
                  </template>
                  <v-card>
                      <v-card-title>
                          <span class="text-h5">{{ formTitle }}</span>
                      </v-card-title>

                      <v-card-text>
                          <v-container>
                              <v-row>
                                  <v-text-field
                                      v-model="editedItem.email"
                                      label="email"
                                  ></v-text-field>
                                  <v-text-field
                                      v-model="editedItem.name"
                                      label="이름"
                                  ></v-text-field>
                                  <v-text-field
                                      v-model="editedItem.password"
                                      label="비밀번호"
                                  ></v-text-field>
                                  <v-text-field
                                      v-model="editedItem.password_confirmation"
                                      label="비밀번호 확인"
                                  ></v-text-field>
                              </v-row>
                          </v-container>
                      </v-card-text>
                      <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                              class="white--text"
                              color="#283593"
                              @click="save"
                          >
                              저장
                          </v-btn>
                          <v-btn
                              class="white--text"
                              color="#E23C44"
                              @click="close"
                          >
                              취소
                          </v-btn>
                      </v-card-actions>
                  </v-card>
              </v-dialog>
              <v-dialog v-if="this.media_id !== null"
                  v-model="media_dialog"
                  max-width="500px"
              >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="#283593"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    style="float: right"
                    class="ml-2 mr-2 mt-3"
                >
                  매체
                  <v-icon
                      right
                      dark
                  >
                    mdi-plus
                  </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ mediaFormTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-select
                            :items="user_list_items"
                            v-model="mediaEditedItem.user_id"
                            label="사용자"
                            item-text="name"
                            item-value="id"
                            style="max-width: 300px"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      class="white--text"
                      color="#283593"
                      @click="media_save"
                  >
                    저장
                  </v-btn>
                  <v-btn
                      class="white--text"
                      color="#E23C44"
                      @click="media_close"
                  >
                    취소
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
              <v-text-field class="float-right ml-2"
                  style="max-width: 220px;"
                  label="검색"
                  placeholder="이름 or 이메일"
                  v-model="search"
                  append-icon="mdi-magnify"
                  @keyup.native.enter="searchChange()"
                  hide-details
              ></v-text-field>
          </v-col>
      </v-row>
      <v-divider class="indigo mt-6 mb-6"></v-divider>
      <v-row v-if="loading" class="justify-center"
             :loading="loading"
      >
        <spinner :loading="loading"></spinner>
      </v-row>
      <v-row v-else class="pt-3">
          <v-col v-if="this.items.length === 0"
                 justify="center" align="center"
          >
            <span class="subtitle-1">데이터가 존재하지 않습니다.</span>
          </v-col>
          <v-col v-else
              cols="12" sm="6" md="4" lg="3"
              v-for="(item, i) in items" :key="i"
          >
              <v-card
                  height="180px"
              >
                  <v-card-title>
                    <i class="mdi mdi-account ma-0" style="color: #283593"></i>
                    {{ item.name }}
                  </v-card-title>

                  <v-card-text>
                      <div class="text-subtitle-1">
                          <i class="mdi mdi-email"></i>
                          {{ item.email }}
                      </div>
                      <v-chip
                          outlined
                          small
                          label
                          v-for="item in item.children"
                          color="#283593"
                          class="mr-1 mt-1"
                          :key="item.id"
                      >
                        {{ item.name }}
                      </v-chip>
                  </v-card-text>
              </v-card>
          </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Breadcrumbs from "../../components/Breadcrumbs";
import Spinner from "../../components/Spinner";

export default {
    components: {
      Breadcrumbs,
      Spinner,
    },
    data() {
        return {
            loading : false,
            breadcrumbs: [
                {
                    text: '사용자 관리',
                    disabled: false,
                    href: '/users',
                },
            ],
            headers: [
                {text: 'NO.', value: 'no', filterable: false, sortable: false},
                {text: '매체', value: 'media_name', sortable: false},
            ],
            items: [],
            user_list_items: [],
            user_items: [],
            media_id: null,
            media_items: [],
            media_items2: [],
            user_media: [],
            dialog: false,
            media_dialog: false,
            editedItem: {
                email: '',
                name: '',
                password: '',
                password_confirmation: ''
            },
            mediaEditedItem: {
                user_id: '',
                media_id: '',
            },
            defaultItem: {
                email: '',
                name: '',
                password: '',
                password_confirmation: ''
            },
            mediaDefaultItem: {
                user_id: '',
                media_id: '',
            },
            dialogDelete: false,
            editedIndex: -1,
            mediaEditedIndex: -1,
            user: '',
            selection: [],
            search: '',
        }
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? '사용자 추가' : '사용자 수정'
        },
        mediaFormTitle() {
            return this.mediaEditedIndex === -1 ? '매체 추가' : '매체 수정'
        },
        getMediaId() {
          return this.$store.getters.getMediaId;
        },
    },
    watch: {
        dialogDelete(val) {
            val || this.closeDelete()
        },
        media_id() {
          if(this.media_id !== null){
            this.$store.commit("SET_MEDIA_ID", this.media_id);
          }
        },
      mediaEditedItem(){
        if(this.media_id !== null){
          this.mediaEditedItem.media_id = this.media_id;
        }
      }
    },
    mounted() {
        this.media_id = this.getMediaId;
        this.medias(this.media_id);
        if(this.media_id !== null){
          this.mediaEditedItem.media_id = this.media_id;
        }

        this.getData();
        this.getMedia();
    },
    methods: {
        medias(media) {
          this.media_id = media;
          if (typeof this.media_id === "undefined") {
            this.media_id = null;
          } else {
            this.media_id = media;
          }
          this.getData();
          this.getMedia();
        },
        getData() {
            let users = [];
            let userList = [];
            this.loading = true;
            this.axios.get('api/v1/users', {
              params: {
                'search': this.search ? this.search : '',
                'media_id': this.media_id ? this.media_id : null,
              }
            })
                .then(res => {
                    if (res.data.data.data.length > 0) {
                        res.data.data.data.map((item, index) => {
                            users.push({
                                no: index + 1,
                                id: item.id,
                                email: item.email,
                                name: item.name,
                                children: item.user_media,
                            })
                        })
                        res.data.data.userList.map((item, index) => {
                          userList.push({
                            no: index + 1,
                            id: item.id,
                            email: item.email,
                            name: item.name,
                            children: item.user_media,
                          })
                        })
                    }
                    this.items = users;
                    this.user_list_items = res.data.data.userList;
                    this.loading = false;
                })
                .catch(err => {
                    console.error(err);
                });
        },
        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        media_close() {
            this.media_dialog = false
            this.$nextTick(() => {
                this.mediaEditedItem = Object.assign({}, this.mediaDefaultItem)
                this.mediaEditedIndex = -1
            })
        },
        save() {
            if (this.editedIndex > -1) {
                Object.assign(this.items[this.editedIndex], this.editedItem)
                const data = {
                    email: this.editedItem.email,
                    name: this.editedItem.name,
                    password: this.editedItem.password,
                    password_confirmation: this.editedItem.password_confirmation,
                    media_id: this.media_id,
                };
                this.axios.put('api/v1/users/' + this.editedItem.id, data).then(res => {
                    this.medias(res.data.data.media_id);
                }).catch(err => {
                    console.error(err);
                });
            } else {
                this.items.push(this.editedItem)
                const data = {
                    email: this.editedItem.email,
                    name: this.editedItem.name,
                    password: this.editedItem.password,
                    password_confirmation: this.editedItem.password_confirmation,
                    media_id: this.media_id,
                };
                this.axios.post('api/v1/users', data).then(res => {
                    this.medias(res.data.data.media_id);
                }).catch(err => {
                    console.error(err);
                });
            }
            this.close()
        },
        media_save() {
            if (this.mediaEditedIndex > -1) {
                Object.assign(this.media_items[this.editedIndex], this.editedItem)
                const data = {
                    user_id: this.mediaEditedItem.user_id,
                    media_id: this.mediaEditedItem.media_id,
                };
                this.axios.put('api/v1/keywords/' + this.editedItem.id, data).then(res => {
                    this.medias(res.data.data.media_id);
                }).catch(err => {
                    console.error(err);
                });
            } else {
                this.media_items.push(this.mediaEditedItem)
                const data = {
                    user_id: this.mediaEditedItem.user_id,
                    media_id: this.mediaEditedItem.media_id,
                };
                this.axios.post('api/v1/users/medias', data).then(res => {
                    this.medias(res.data.data.media_id);
                }).catch(err => {
                    console.error(err);
                });
            }
            this.media_close()
        },
        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        deleteItemConfirm() {
            this.axios.delete('api/v1/users/' + this.editedItem.id).then(res => {
                this.medias(res.data.data.media_id);
            }).catch(err => {
                console.error(err);
            });
            this.closeDelete()
        },
        getMedia() {
            return this.axios.get('api/v1/medias')
                .then(res => {

                    this.media_items = res.data.data;
                    this.user_items = res.data.data;
                    this.user_items.unshift({'id': null, name:'매체 미지정'});
                })
                .catch(err => {
                    console.error(err);
                });
        },
        searchChange() {
            this.getData();
        },
    }
}
</script>

<style scoped>

</style>
