import Vue from 'vue'
import Vuetify from 'vuetify'
import colors from 'vuetify/lib/util/colors'
import 'vuetify/dist/vuetify.min.css'
import axios from 'axios'
import '@mdi/font/css/materialdesignicons.css'

import App from './App.vue'
import router from './router'
import store from './store'
import Swal from 'sweetalert2';

require('./store/subscriber')

Vue.use(Vuetify)

window.axios = require('axios')
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.baseURL = process.env.VUE_APP_AXIOS_BASE_URL;
axios.defaults.headers.common['Authorization'] =
	'Bearer ' + localStorage.getItem('token');
axios.defaults.headers.common['c9'] = '';

Vue.config.productionTip = false
Vue.prototype.axios = window.axios;

Object.defineProperty(Vue.prototype, '$Swal', {
	value: Swal
})

store.dispatch('auth/attempt', localStorage.getItem('token')).then(() => {
	new Vue({
		vuetify: new Vuetify({
			theme: {
				themes: {
					light: {
						primary: "#0A0C0C",
					},
					dark: {
						primary: colors.purple.darken3,
					},
				},
			},
			icons: {
				iconfont: 'mdiSvg',
			},
		}),
		router,
		store,
		render: h => h(App),
		methods: {
			getMediaEngNameById(id) {
				switch (id) {
					case 1: return 'bloodpressure';
					case 2: return 'golfzip';
					case 3: return 'ticketoffice';
					case 4: return 'tingstar';
					case 5: return 'sivillage';
					case 6: return 'onemillion';
					case 7: return 'tingsearch';
					case 8: return 'trouver';
					case 9: return 'smartcompas';
					default: console.error('Not defined prepared media id. Please set mediaId, name in src/App.vue file');
				}
			},
			getMediaHanNameById(id) {
				switch (id) {
					case 1: return '건강매니저';
					case 2: return '골프집';
					case 3: return '매표소';
					case 4: return '눈팅';
					case 5: return 'sivillage';
					case 6: return 'onemillion';
					case 7: return '팅서치';
					case 8: return '트루버';
					default: return '';
				}
			},
			getPlatformNameById(id) {
				switch (id) {
					case 1: return 'instagram';
					case 2: return 'youtube';
					case 3: return 'twitter';
					case 4: return 'naver-blog';
					case 5: return 'facebook';
					case 6: return 'tiktok';
					case 7: return 'google-news';
					case 8: return 'naver-news';
					case '1': return 'instagram';
					case '2': return 'youtube';
					case '3': return 'twitter';
					case '4': return 'naver-blog';
					case '5': return 'facebook';
					case '6': return 'tiktok';
					case '7': return 'google-news';
					case '8': return 'naver-news';
					default: return '';
				}
			},
			getPlatformIconByName(platform) {
				switch (platform) {
					case 'instagram': return 'mdi-instagram';
					case 'youtube': return 'mdi-youtube';
					case 'naver-blog': return 'mdi-blogger';//'mdi-alpha-b-circle';//'mdi-alpha-b-box';//mdi-blogger
					case 'twitter': return 'mdi-twitter';
					case 'facebook': return 'mdi-facebook';
					case 'tiktok': return 'mdi-music-note';
					case 'google-news': return 'mdi-google';
					case 'naver-news': return 'mdi-alpha-n-box';
					default: return '';
				}
			},
			getPlatformHanNameByName(platform) {
				switch (platform) {
					case 'instagram': return '인스타그램';
					case 'youtube': return '유튜브';
					case 'naver-blog': return '네이버블로그';
					case 'twitter': return '트위터';
					case 'facebook': return '페이스북';
					case 'tiktok': return '틱톡';
					case 'google-news': return '구글뉴스';
					case 'naver-news': return '네이버뉴스';
					default: return '';
				}
			},
			getPlatformNameByHanName(platform) {
				switch (platform) {
					case '인스타그램': return 'instagram';
					case '유튜브': return 'youtube';
					case '네이버블로그': return 'naver-blog';
					case '트위터': return 'twitter';
					case '페이스북': return 'facebook';
					case 'tiktok': return '틱톡';
					case '구글뉴스': return 'google-news';
					case '네이버뉴스': return 'naver-news';
					default: return '';
				}
			},
			getPlatformColorByName(platform) {
				switch (platform) {
					case 'instagram': return 'purple';
					case 'youtube': return 'red';
					case 'naver-blog': return 'green';
					case 'twitter': return 'light-blue';
					case 'facebook': return 'indigo';
					case 'tiktok': return 'black';
					case 'google-news': return 'blue';
					case 'naver-news': return 'green';
					default: '';
				}
			},
			getStorageUrlByMediaName(name) {
				const env = process.env["NODE_ENV"];
				if(env !== 'production') return process.env["VUE_APP_AZURE_STORAGE_URL_DEV"];

				const envName = 'VUE_APP_AZURE_STORAGE_URL_' + name.toUpperCase();

				return process.env[envName];
			},
		},
	}).$mount('#app')
})
