<template>
    <v-app style="background-color:#ffffff;">
        <v-navigation-drawer style="border-radius: 0 40px 0 0;"
            v-model="drawer"
            color="indigo darken-3"
            dark
            app
            clipped-top
            v-if="authenticated"
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title style="cursor:pointer"
                  class="text-h5 text-left mt-1 mb-1"
                  @click="changeHome"
              >
                Curator9 Console
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>
          <v-list
                nav
                dense
            >
                <v-list-item-group>
                    <v-list subheader>
                        <template
                            v-for="(item) in items"
                        >
                            <v-list-item
                                v-for="(menu) in item.menus"
                                :key="menu.id"
                                link
                                :to="menu.link"
                                active-class="white--text"
                                @click="resetStore"
                                exact
                            >
                                <v-list-item-icon>
                                    <v-icon>{{ menu.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ menu.text }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-list>
                </v-list-item-group>
            <div class="mt-2">
              <v-list-group
                  v-for="item in statistics"
                  :key="item.title"
                  active-class="white--text"
                  :prepend-icon="item.action"
                  no-action
              >
                <template v-slot:activator >
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item to="/statistics" sub-group active-class="white--text"  >
                  <v-list-item-content>
                    <v-list-item-title>수집/게시/신고 개수</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/statistic-users" sub-group active-class="white--text"  >
                  <v-list-item-content>
                    <v-list-item-title>일간 사용자 유입량</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/statistic-content-ranks" sub-group active-class="white--text">
                  <v-list-item-content>
                    <v-list-item-title>콘텐츠 순위</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/statistic-tag-ranks" sub-group active-class="white--text">
                  <v-list-item-content>
                    <v-list-item-title>키워드 (PK) 순위</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/statistic-tag-aggs" sub-group active-class="white--text">
                  <v-list-item-content>
                    <v-list-item-title>키워드 (PK) 집계</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/statistic-user-ranks" sub-group active-class="white--text" v-if="grade !== 0" >
                  <v-list-item-content>
                    <v-list-item-title>접속자 순위</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/statistic-user-search" sub-group active-class="white--text" v-if="grade !== 0" >
                  <v-list-item-content>
                    <v-list-item-title>사용자 검색</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/statistic-user-logs" sub-group active-class="white--text">
                  <v-list-item-content>
                    <v-list-item-title>사용자 접속 로그</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </div>
            <div class="mt-2">
                    <v-list-group
                        v-for="item in settings"
                        :key="item.title"
                        active-class="white--text"
                        :prepend-icon="item.action"
                        no-action
                    >
                        <template v-slot:activator >
                            <v-list-item-content>
                                <v-list-item-title v-text="item.title"></v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-list-item to="/users" sub-group active-class="white--text" v-if="grade !== 0" >
                            <v-list-item-content>
                                <v-list-item-title>사용자 관리</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
<!--                        <v-list-item to="/platforms" sub-group active-class="white&#45;&#45;text">
                            <v-list-item-content>
                                <v-list-item-title>플랫폼 관리</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>-->
                        <v-list-item to="/topics" sub-group active-class="white--text">
                            <v-list-item-content>
                                <v-list-item-title>카테고리 관리</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
<!--                        <v-list-item to="/keywords" sub-group active-class="white&#45;&#45;text">-->
<!--                            <v-list-item-content>-->
<!--                                <v-list-item-title>키워드 관리</v-list-item-title>-->
<!--                            </v-list-item-content>-->
<!--                        </v-list-item>-->
                      <v-list-item to="/base-keywords" sub-group active-class="white--text">
                        <v-list-item-content>
                          <v-list-item-title>키워드 (PK) 관리</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item to="/channels" sub-group active-class="white--text">
                            <v-list-item-content>
                                <v-list-item-title>채널 관리</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
<!--                        <v-list-item to="/tags" sub-group active-class="white&#45;&#45;text">-->
<!--                            <v-list-item-content>-->
<!--                                <v-list-item-title>비즈니스태그 관리</v-list-item-title>-->
<!--                            </v-list-item-content>-->
<!--                        </v-list-item>-->
                      <v-list-item to="/statistic-tag-ignores" sub-group active-class="white--text">
                        <v-list-item-content>
                          <v-list-item-title>키워드 (PK) 제외</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                        <v-list-item to="/exposure" sub-group active-class="white--text">
                          <v-list-item-content>
                            <v-list-item-title>노출 가중치 관리</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                </div>
            </v-list>
        </v-navigation-drawer>
        <v-app-bar
            color="#ffffff"
            app
            clipped-left
            white
            elevation="0"
            v-if="authenticated"
        >
            <v-app-bar-nav-icon style="color:#000;" @click.stop="drawer = !drawer"/>
            <v-spacer/>
            <v-menu
                offset-y
            >
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" color="#000000">
                        <v-icon>mdi-account-cog</v-icon>
                    </v-btn>
                </template>

                <v-list dense>
                    <v-list-item>
                        <v-list-item-avatar color="#000000" size="48">
                            <span class="white--text font-weight-bold" >
                                관리
                            </span>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title class="title">{{ this.user && this.user.name }}님</v-list-item-title>
                          <v-list-item-title class="mt-1">- {{ this.user && this.user.email }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-divider class="my-2"/>

                    <v-list-item @click="logout">
                        <v-list-item-icon>
                            <v-icon>mdi-logout</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>로그아웃</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
        <v-main class="main-ml" style="width: 95%;">
            <router-view/>
        </v-main>
    </v-app>
</template>
<script>

import {mapGetters, mapActions} from 'vuex'

export default {
    data() {
        return {
            name: '',
            state: '',
            email: '',
            token: '',
            grade: '',
            drawer: null,
            items: [
                {
                    subheader: '관리',
                    menus: [
                        {icon: "mdi-view-dashboard", text: "수집정보", link: "/collect"},
                        {icon: "mdi-format-list-bulleted", text: "게시정보", link: "/article"},
                        {icon: "mdi-comment-alert-outline", text: "신고정보", link: "/article-report"},
                    ]
                },
            ],
            settings: [
                {
                    action: 'mdi-cog',
                    item: [
                        {title: '사용자 관리', link: "/users"},
                        // {title: '플랫폼 관리', link: "/platform"},
                        {title: '카테고리 관리', link: "/target"},
                        {title: '키워드 및 채널 관리', link: "/media"},
                        {title: '노출 가중치 관리', link: "/exposure"},
                    ],
                    title: '설정',
                },
            ],
            statistics: [
              {
                action: 'mdi-chart-bar',
                item: [
                  {title: '수집/게시/신고 개수', link: "/statistic"},
                  {title: '일간 사용자 유입량', link: "/statistic-users"},
                  {title: '콘텐츠 순위', link: "/statistic-content-ranks"},
                  {title: '비즈니스태그 순위', link: "/statistic-tag-ranks"},
                  {title: '접속자 순위', link: "/statistic-user-ranks"},
                  {title: '사용자 검색', link: "/statistic-user-search"},
                  {title: '사용자 접속 로그', link: "/statistic-user-logs"},
                ],
                title: '통계',
              },
            ],
            menu: [],
        }
    },
    computed: {
        ...mapGetters({
            authenticated: 'auth/authenticated',
            user: 'auth/user',
            errors: 'errors',
        }),
        getPage() {
          return this.$store.getters.getPage;
        },
    },
    watch: {
      $route(to, from) {
        let arrToValue = ['/statistics', '/statistic-users', '/statistic-content-ranks', '/statistic-tag-ranks', '/statistic-user-ranks', '/statistic-user-search', '/users', '/topics','/keywords', '/channels', '/tags', '/exposure'];
        let arrFromValue = ['/statistics', '/statistic-users', '/statistic-content-ranks', '/statistic-tag-ranks', '/statistic-user-ranks'];
        if(from.path === '/article' && (to.path === '/collect' || to.path === '/article-report' || this.inArray(to.path, arrToValue) !== false)){
          this.resetStore();
        }
        if(from.path === '/collect' && (to.path === '/article' || to.path === '/article-report' || this.inArray(to.path, arrToValue) !== false)){
          this.resetStore();
        }
        if(from.path === '/article-report' && (to.path === '/collect' || to.path === '/article' || this.inArray(to.path, arrToValue) !== false)){
          this.resetStore();
        }
        if(this.inArray(to.path, arrFromValue) !== false && (to.path === '/collect' || to.path === '/article' || to.path === '/article-report' || this.inArray(to.path, arrToValue) !== false)){
          this.resetStore();
        }
      },
    },
    mounted() {
        this.token = localStorage.getItem('token');
        if (this.token) {
            this.grade = this.user.grade;
            this.name = this.user.name;
            this.email = this.user.email;
        }
    },
    methods: {
        ...mapActions({
            logoutAction: 'auth/logout'
        }),
        logout() {
            this.resetStore();
            this.logoutAction().then(() => {
                this.$router.replace({
                    name: 'Login'
                })
            })
        },
        changeHome() {
          this.resetStore();
          if(this.$route.path!=='/collect') this.$router.push('/collect');
          else location.reload();
        },
        resetStore(){
          this.$store.commit("SET_PLATFORM", []);
          this.$store.commit("SET_TYPE", []);
          this.$store.commit("SET_SEARCH", '');
          this.$store.commit("SET_DATERANGES", []);
          this.$store.commit("SET_PAGE", 1);
          this.$store.commit("SET_SORT", 'sort_id');
          this.$store.commit("SET_DATECHOICE", 'daily');
        },
        inArray(val, arrValue) {
          for(let i=0; i < arrValue.length; i++) {
            if(arrValue[i] === val) return true;
          }
          return false;
        },
      }
}
</script>


<style>
.row-pointer :hover {
  cursor: pointer;
}

.flexDiv {
  display: flex;
  flex-wrap: wrap;
}

.flexDiv > col {
  align-items: center
}
@media screen and (min-width:2560px) {
  .card-width{
    max-width: 199px;
  }
  .main-ml{
    margin-left: 2.5%;
  }
  .modal_card {
    min-width: 40%;
    max-width: 40%;
  }
}
@media screen and (min-width:2048px) and (max-width:2559px) {
  .card-width{
    max-width: 185px;
  }
  .main-ml{
    margin-left: 2.5%;
  }
  .modal_card {
    min-width: 40%;
    max-width: 40%;
  }
}
@media screen and (min-width:1904px) and (max-width:2047px) {
  .card-width{
    max-width: 180px;
  }
  .main-ml{
    margin-left: 2.5%;
  }
  .modal_card {
    min-width: 40%;
    max-width: 40%;
  }
}
@media screen and (min-width:1600px) and (max-width:1903px) {
  .card-width{
    max-width: 173px;
  }
  .main-ml{
    margin-left: 2.5%;
  }
  .modal_card {
    min-width: 40%;
    max-width: 40%;
  }
}
@media screen and (min-width:1280px) and (max-width:1599px) {
  .card-width{
    max-width: 165px;
  }
  .main-ml{
    margin-left: 3%;
  }
  .modal_card {
    min-width: 40%;
    max-width: 40%;
  }
}
@media screen and (min-width:1024px) and (max-width:1279px) {
  .card-width{
    max-width: 155px;
  }
  .main-ml{
    margin-left: 2.5%;
  }
  .modal_card {
    min-width: 40%;
    max-width: 40%;
  }
}
/* 테블릿 세로 (해상도 768px ~ 1023px)*/
@media screen and (min-width:768px) and (max-width:1023px) {
  .card-width{
    max-width: 197px;
  }
  .main-ml{
    margin-left: 5%;
  }
  .modal_card {
    min-width: 40%;
    max-width: 40%;
  }
}
/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media screen and (min-width:480px) and (max-width:767px) {
  .card-width{
    max-width: 230px;
  }
  .main-ml{
    margin-left: 2%;
  }
  .modal_card {
    min-width: 20%;
    max-width: 20%;
  }
}
/* 모바일 가로, 테블릿 세로 (해상도 412px ~ 479px)*/
@media screen and (min-width:412px) and (max-width:479px) {
  .card-width{
    max-width: 230px;
  }
  .main-ml{
    margin-left: 2%;
  }
  .modal_card {
    min-width: 20%;
    max-width: 20%;
  }
}
/* 모바일 가로, 테블릿 세로 (해상도 321px ~ 411px)*/
@media screen and (min-width:321px) and (max-width:411px) {
  .card-width{
    max-width: 230px;
  }
  .main-ml{
    margin-left: 2%;
  }
  .modal_card {
    min-width: 20%;
    max-width: 20%;
  }
}
/* 모바일 가로, 테블릿 세로 (해상도  ~ 320px)*/
@media screen and (max-width:320px) {
  .card-width{
    max-width: 230px;
  }
  .main-ml{
    margin-left: 2%;
  }
  .modal_card {
    min-width: 20%;
    max-width: 20%;
  }

}
</style>
