<template>
  <v-container>
    <Breadcrumbs :breadcrumbs="breadcrumbs"/>
    <div class="font-weight-bold mt-0"><span class="pt-0" style="color: gray;font-size: 12px;">최고관리자만 사용할 수 있는 메뉴입니다. <font color="black">정확한 회원 아이디를 입력</font>하면 관련 데이터를 보여줍니다.</span></div>
    <v-row class="row mb-0 mt-1">
      <v-col class="col-4 ma-0 row">
        <v-select style="max-width: 150px;"
                  :items="user_items"
                  v-model="media_id"
                  label="매체"
                  item-text="name"
                  item-value="id"
                  @change="medias"
                  hide-details
        ></v-select>
      </v-col>
      <v-col class="col-8 ma-0 row justify-end">
        <v-menu
            v-model="datepicker"
            :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <v-text-field class="row-pointer mr-2"
                          style="max-width: 110px;"
                          label="시작일"
                          append-icon="mdi-calendar"
                          v-on="on"
                          offset-y
                          :value="dateRanges[0]"
                          readonly
                          hide-details
            >
            </v-text-field>
          </template>
          <v-date-picker
              v-model="dateRanges[0]"
              no-title
              locale="ko-kr"
              changeMonth="true"
              :max="today"
              @input="dateSearchChange"
          />
        </v-menu>
        <p style="margin-top: 20px !important;">~</p>
        <v-menu
            v-model="end_datepicker"
            :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <v-text-field class="row-pointer ml-2 mr-2"
                          style="max-width: 110px;"
                          label="종료일"
                          append-icon="mdi-calendar"
                          v-on="on"
                          offset-y
                          :value="dateRanges[1]"
                          readonly
                          hide-details
            >
            </v-text-field>
          </template>
          <v-date-picker
              v-model="dateRanges[1]"
              no-title
              locale="ko-kr"
              changeMonth="true"
              :max="today"
              @input="dateSearchChange"
          />
        </v-menu>
        <v-text-field style="max-width: 250px;"
                      label="검색"
                      v-model="search"
                      placeholder="정확한 사용자 아이디만 입력"
                      append-icon="mdi-magnify"
                      v-on:keyup.enter="searchData"
                      hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-divider class="indigo mt-2 mb-4"></v-divider>
    <v-row v-if="loading" class="justify-center"
           :loading="loading"
    >
      <spinner :loading="loading"></spinner>
    </v-row>
    <template>
      <v-row class="mb-0 mt-4">
        <v-col lg="12" class="justify-center pb-0 mb-0">
          <div class="font-weight-bold">이벤트 집계</div>
        </v-col>
      </v-row>
      <v-row class="mb-0">
        <v-col class="justify-center"
               v-for="item in event_items"
               :key="item.event"
               lg="3"
        >
          <v-card>
            <v-card-text class="justify-center" style="text-align: -webkit-center;">
              <v-list-item-content>
                <v-card-title style="font-size: 1rem !important;">
                  <v-icon v-if="item.event === 'click'">mdi-cursor-default-click-outline</v-icon>
                  <v-icon v-else-if="item.event === 'visit'">mdi-eye-outline</v-icon>
                  <v-icon v-else-if="item.event === 'like'">mdi-thumb-up-outline</v-icon>
                  <v-icon v-else-if="item.event === 'favorite'">mdi-star-outline</v-icon>
                  <strong class="ml-3">{{ item.event }}</strong> <span class="ml-2">{{ item.count }}번</span>
                </v-card-title>
              </v-list-item-content>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="mb-0 mt-4">
        <v-col lg="12" class="justify-center pb-0 mb-0">
          <div class="font-weight-bold">플랫폼 집계</div>
        </v-col>
      </v-row>
      <v-row class="mb-0">
        <v-col
            class="justify-center"
            v-for="item in platform_items"
            :key="item.event"
            style="width: 20%;"
        >
          <v-card>
            <v-card-text class="justify-center" style="text-align: -webkit-center;">
              <v-list-item-content>
                <v-card-title style="font-size: 1rem !important;">
                  <span>
                    <v-icon class="mr-2" :color="item.color">
                      {{ item.icon }}
                    </v-icon>
                    {{ item.platform_kr }}
                  </span>
                  <span class="ml-2">{{ item.count}}번{{item.event}}</span>
                </v-card-title>
              </v-list-item-content>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mb-0 mt-2">
        <v-col lg="12" class="justify-center pb-0 mb-0">
          <div class="font-weight-bold" style="margin-top: 2%;">콘텐츠 순위<span class="pl-2" style="color: gray;font-size: 12px;">순위는 100위까지 보여주며, <span style="text-decoration: underline; color: #3b3b3b;">검색한 회원</span>이 유입된 콘텐츠를 집계하여 순위를 보여줍니다. 인스타그램은 콘텐츠 영역에 내용이, 그 외 플랫폼은 제목이 나옵니다. 단, <strong>비활성 된 컨텐츠</strong>는 제외됩니다.</span></div>
        </v-col>
      </v-row>
      <v-row class="pt-3 justify-center">
        <template>
          <v-card style="width: 99%;max-height: 300px !important;overflow: auto;height: 300px !important;overflow-x: hidden;">
            <table class="type10">
              <thead>
              <tr>
                <th class="text-center" style="width : 15% !important;">순위</th>
                <th class="text-left" style="width : 20% !important;">플랫폼</th>
                <th class="text-left" style="width : 40% !important;">콘텐츠</th>
                <th class="text-left" style="width : 15% !important;">횟수</th>
              </tr>
              </thead>
              <tbody v-if="this.articles_items.length > 0">
              <tr v-for="(item, index) in articles_items" :key="index" style="cursor: pointer;" @click="moreArticleShow(item)">
                <td class="text-center"> <v-icon v-if="item.no === 1" color="rgb(255 190 0)">mdi-crown-outline</v-icon>{{ item.no }}위</td>
                <td class="text-left" v-if="typeof item.article_data !== 'undefined'">
                  <span>
                    <v-icon class="mr-2" :color="item.color">
                      {{ item.icon }}
                    </v-icon>
                    {{ item.platform_kr }}
                  </span>
                </td>
                <td class="text-left" v-else></td>
                <td class="text-left" v-if="typeof item.article_data !== 'undefined'">{{ item.article_data.title ? item.article_data.title.substr(0, 60) : item.article_data.contents.substr(0, 60) }}</td>
                <td class="text-left" v-else>{{ item.article_id }}</td>
                <td class="text-left">{{ item.count }}번</td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td colspan="4" class="text-center">데이터가 없습니다.</td>
              </tr>
              </tbody>
            </table>
          </v-card>
        </template>
      </v-row>
      <v-row class="mb-0 mt-4">
        <v-col lg="12" class="justify-center pb-0 mb-0">
          <div class="font-weight-bold" style="margin-top: 2%;">키워드 (PK) 순위<span class="pl-2" style="color: gray;font-size: 12px;">순위는 100위까지 보여주며, <span style="text-decoration: underline; color: #3b3b3b;">검색한 회원</span>이 유입된 콘텐츠의 키워드 (PK)를 집계하여 순위를 보여줍니다.</span></div>
        </v-col>
      </v-row>
      <v-row class="pt-3 mb-5 justify-center">
        <template>
          <v-card style="width: 99%;max-height: 300px !important;overflow: auto;height: 300px !important;overflow-x: hidden;">
            <table class="type10">
              <thead>
              <tr>
                <th class="text-center" style="width : 30% !important;">순위</th>
                <th class="text-left" style="width : 30% !important;">태그</th>
                <th class="text-right" style="width : 20% !important;">횟수</th>
                <th class="text-right" style="width : 20% !important;">
                  <v-btn
                      small
                      color="#8BE0E8"
                      @click="resetUserTagStats"
                  >스코어링초기화</v-btn>
                </th>
              </tr>
              </thead>
              <tbody v-if="this.tags_items.length > 0">
              <tr v-for="(item, index) in tags_items" :key="index">
                <td class="text-center"> <v-icon v-if="item.no === 1" color="rgb(255 190 0)">mdi-crown-outline</v-icon>{{ item.no }}위</td>
                <td class="text-left">{{ item.tag }}</td>
                <td class="text-right">{{ item.count }}번</td>
                <td class="text-center"></td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td colspan="4" class="text-center">데이터가 없습니다.</td>
              </tr>
              </tbody>
            </table>
          </v-card>
        </template>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import Breadcrumbs from "../../components/Breadcrumbs";
import Spinner from "../../components/Spinner";
import {mapGetters} from "vuex";
import numeral from 'numeral';
import dayjs from "dayjs";


export default {
  components: {
    Breadcrumbs,
    Spinner,
  },
  data() {
    return {
      loading: false,
      breadcrumbs: [
        {
          text: '사용자 검색',
          disabled: false,
          href: '/statistic-user-search',
        },
      ],
      article_pv_length: 0,
      top_count: 10,
      per_page: 2,
      page: 1,
      event_items: [
        {event: 'visit', count: 0},
        {event: 'click', count: 0},
        {event: 'like', count: 0},
        {event: 'favorite', count: 0},
      ],
      articles_items: [],
      platform_items: [
        {platform: 'instagram', count: 0},
        {platform: 'naver-blog', count: 0},
        {platform: 'google-news', count: 0},
        {platform: 'youtube', count: 0},
        {platform: 'twitter', count: 0}
      ],
      tags_items: [],
      user_items: [],
      media_id: null,
      datepicker: false,
      end_datepicker: false,
      search: '',
      dateRanges : [],
      date_type_text : '일',
      yesterday : dayjs(dayjs().format("YYYY-MM-DD")).subtract(1, 'day').format('YYYY-MM-DD'),
      today : dayjs().format("YYYY-MM-DD"),
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    getMediaId() {
      return this.$store.getters.getMediaId;
    },
    getDateRanges() {
      return this.$store.getters.getDateRanges;
    },
  },
  watch: {
    dateRanges: {
      handler() {
        this.$store.commit("SET_DATERANGES", this.dateRanges);
        if(!this.dateRanges[0] && this.dateRanges[1]){
          this.$store.commit("SET_DATERANGES", []);
        }
        if (this.dateRanges[0] > this.dateRanges[1]) {
          this.dateRanges.reverse();
          this.$store.commit("SET_DATERANGES", this.dateRanges);
          this.dateSearchChange();
        }
         if(this.getDateRanges[0] !== this.dateRanges[0] || this.getDateRanges[1] !== this.dateRanges[1]) {
           if(!this.dateRanges[0])  this.dateRanges[0] = this.getDateRanges[0];
           if(!this.dateRanges[1])  this.dateRanges[1] = this.getDateRanges[1];
           this.$store.commit("SET_DATERANGES", this.dateRanges);
         }
      }
    },
    media_id() {
      this.$store.commit("SET_MEDIA_ID", this.media_id);
    },
  },
  mounted() {
    this.dateRanges[0] = dayjs(dayjs().format("YYYY-MM-DD")).subtract(30, 'day').format('YYYY-MM-DD');
    this.dateRanges[1] = this.today;
    this.$store.commit("SET_DATERANGES", this.dateRanges);

    const mediaId = this.media_id;
    if (mediaId) {
      this.media_id = parseInt(mediaId);
    } else {
      this.media_id = this.user.user_media[0].id;
    }

    if(this.getMediaId && this.getMediaId !== this.media_id) this.media_id = this.getMediaId;

    if(!this.dateRanges[0] && this.dateRanges[1]){
      this.$store.commit("SET_DATERANGES", []);
    }

    if (localStorage.getItem('token')) {
      this.user_items = this.user.user_media;
    }

    if (this.$route.query.search) {
      this.search = this.$route.query.search;
    }
    this.changeDatas();

  },
  methods: {
    medias(media) {
      this.media_id = media;
      if (typeof this.media_id === "undefined") {
        this.media_id = this.user.user_media[0].id;
      } else {
        this.media_id = media;
      }
      window.location.href = `/statistic-user-search`;
    },
    numberComma(value) {
      return numeral(value).format('0,0');
    },
    getData(){
      let header = this.$root.getMediaEngNameById(this.media_id);
      let event_result = [];
      let articles_result = [];
      let platform_check = [];
      let platform_result = [];
      let tags_result = [];
      let isset_like = false;
      let isset_click = false;
      let isset_visit = false;
      let isset_favorite = false;
      this.articles_items = [];
      this.tags_items = [];
      this.platform_items = [
        {platform: 'instagram', icon: '', color: '', count: 0, isset: 0},
        {platform: 'naver-blog', icon: '', color: '', count: 0, isset: 0},
        {platform: 'google-news', icon: '', color: '', count: 0, isset: 0},
        {platform: 'youtube', icon: '', color: '', count: 0, isset: 0},
        {platform: 'twitter', icon: '', color: '', count: 0, isset: 0}
      ];
      this.event_items = [
        {event: 'visit', count: 0},
        {event: 'click', count: 0},
        {event: 'like', count: 0},
        {event: 'favorite', count: 0},
      ];

      // 처음 접속 시 예외처리
      if(this.search == '') {
        return;
      }
      this.loading = true;

      this.axios.get('api/v1/users/search-detail/'+ this.search, {
        params: {
          'media_id': parseInt(this.media_id),
          'start_date': this.dateRanges ? this.dateRanges[0] : '',
          'end_date': this.dateRanges ? this.dateRanges[1] : '',
        },
        headers: {
          'c9': header
        }
      }).then(res => {
        // 이벤트 집계
        if(res.data.data.data.count.length > 0) {
          res.data.data.data.count.map((item) => {
            if(item.key === 'like') isset_like = true;
            if(item.key === 'click') isset_click = true;
            if(item.key === 'visit') isset_visit = true;
            if(item.key === 'favorite') isset_favorite = true;

            event_result.push({
              event: item.key,
              count: item.doc_count,
            })
          })

          if(!isset_visit){
            event_result.push({
              event: 'visit',
              count: 0,
            })
          }
          if(!isset_like){
            event_result.push({
              event: 'like',
              count: 0,
            })
          }
          if(!isset_click){
            event_result.push({
              event: 'click',
              count: 0,
            })
          }
          if(!isset_favorite){
            event_result.push({
              event: 'favorite',
              count: 0,
            })
          }
          this.event_items = event_result;
        }
        // 콘텐츠 순위
        let articles_entries = [];
        articles_entries = Object.entries(res.data.data.data.articles);
        if(articles_entries.length > 0) {
          articles_entries.map((item, index) => {
            articles_result.push({
              no: index+1,
              article_id: item[1].key,
              count: item[1].doc_count,
              platform_kr: this.$root.getPlatformHanNameByName(item[1].article_data.platform),
              icon: this.$root.getPlatformIconByName(item[1].article_data.platform),
              color: this.$root.getPlatformColorByName(item[1].article_data.platform),
              article_data: item[1].article_data
            })
          })
          this.articles_items = articles_result;
        }

        // 플랫폼 집계
        if(res.data.data.data.platform_count.length > 0) {
          res.data.data.data.platform_count.map((item) => {
            platform_check = this.platform_items.find(obj => obj.platform === item.key);
            platform_check.isset = 1;
            platform_result.push({
              platform: item.key,
              platform_kr: this.$root.getPlatformHanNameByName(item.key),
              icon: this.$root.getPlatformIconByName(item.key),
              color: this.$root.getPlatformColorByName(item.key),
              count: item.doc_count
            })
          })
        }
        platform_check = [];
        platform_check = this.platform_items.filter(obj => obj.isset === 0);
        for(let i = 0; i < platform_check.length; i++){
          platform_result.push({
            platform: platform_check[i].platform,
            platform_kr: this.$root.getPlatformHanNameByName(platform_check[i].platform),
            icon: this.$root.getPlatformIconByName(platform_check[i].platform),
            color: this.$root.getPlatformColorByName(platform_check[i].platform),
            count: 0,
          })
        }
        this.platform_items = platform_result;

        // 비즈니스태그 순위
        if(res.data.data.data.tags.length > 0) {
          res.data.data.data.tags.map((item, index) => {
            tags_result.push({
              no: index + 1,
              //tag: item.key,
              //count: item.doc_count
              tag: item.tag,
              count: item.priority
            })
          })
          this.tags_items = tags_result;
        }
        this.loading = false;
      }).catch(err => {
        this.loading = false;
        console.error(err);
        //location.reload();
      });
    },
    resetUserTagStats() {
      const data = {
        media_id: this.media_id,
        user_id: this.search
      };
      this.$Swal.fire({
        text: '삭제 하시겠습니까?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: '확인',
        denyButtonText: `취소`,
        confirmButtonColor: '#1550B2'
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.post('api/v1/tags/user/stats/reset', data
          ).then( res => {
            this.changeDatas();
            res;
          }).catch(err => {
            console.error(err);
          });
        }
      })
    },
    dateSearchChange() {
      if (this.dateRanges[0] && this.dateRanges[1]) {
        this.changeDatas();
      } else {
        if(this.dateRanges[0]){
          this.changeDatas();
          this.dateRanges[1] = this.today;
        }else{
          this.$Swal.fire({
            icon: 'error',
            text: '시작 날짜를 선택해주세요.',
          }).then((result) => {
            if (result.isConfirmed) {
              location.reload();
            }
          }).catch(err => {
            console.error(err);
          });
        }
      }
      this.datepicker = false;
      this.end_datepicker = false;
    },
    changeDatas(){
      this.getData();
    },
    searchData(){
      this.articles_items = [];
      this.tags_items = [];
      this.platform_items = [
        {platform: 'instagram', count: 0},
        {platform: 'naver-blog', count: 0},
        {platform: 'google-news', count: 0},
        {platform: 'youtube', count: 0},
        {platform: 'twitter', count: 0}
      ];
      this.event_items = [
        {event: 'visit', count: 0},
        {event: 'click', count: 0},
        {event: 'like', count: 0},
        {event: 'favorite', count: 0},
      ];
      this.$store.commit("SET_SEARCH", this.search);
      window.location.href = `/statistic-user-search?search=${this.search}`;
    },
    getDay(date){
      const week = ['일', '월', '화', '수', '목', '금', '토'];
      return week[new Date(date).getDay()];
    },
    moreArticleShow(item){
      let header = this.$root.getMediaEngNameById(this.media_id);
      let url = `&media_id=${this.media_id}&search=${this.search}&start_date=${this.dateRanges[0] ?? ''}&end_date=${this.dateRanges[1] ?? ''}`;
      this.$router.push('/'+item.article_id+'?c9='+header+url);
    },
  }
}
</script>

<style scoped>
.font-member {
  color: #283593 !important;
}
.font-non-member {
  color: rgb(122 121 121) !important;
}
table.type10 {
  width: 99%;
  border-collapse: collapse;
  text-align: left;
  line-height: 1.5;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin: 20px 10px;
  overflow-x: hidden;
}
table.type10 thead th {
  width: 150px;
  padding: 10px;
  font-weight: bold;
  vertical-align: top;
  color: #fff;
  background: #283593!important;
  margin: 20px 10px;
}
table.type10 tbody th {
  width: 150px;
  padding: 10px;
}
table.type10 td {
  width: 350px;
  padding: 10px;
  vertical-align: top;
  border-bottom: 1px solid #ccc;
}
table.type10:hover tbody tr:hover td {
  background: #f0f6fd;
  color: black;
}
</style>
