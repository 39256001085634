<template>
    <v-container>
      <Breadcrumbs :breadcrumbs="breadcrumbs"/>
      <v-row class="row mb-0 mt-1">
        <v-col class="col-lg-4 ma-0 row">
          <v-select style="max-width: 150px;"
              :items="user_items"
              v-model="media_id"
              label="매체"
              item-text="name"
              item-value="id"
              @change="medias"
              hide-details
          ></v-select>
        </v-col>
        <v-col class="col-lg-8 ma-0 justify-end" >
          <v-text-field class="float-right"
                        style="max-width: 220px;"
                        label="검색"
                        placeholder="키워드"
                        v-model="search"
                        append-icon="mdi-magnify"
                        @keyup.native.enter="searchChange()"
                        hide-details
          ></v-text-field>
          <v-toolbar
              flat
          >
            <v-spacer></v-spacer>
            <v-file-input
                truncate-length="15"
                style="width: 70px"
                class="mt-2 mr-2"
                label="file"
                accept=".csv"
                v-model="file"
            ></v-file-input>
            <v-btn
                :loading="upload_loading"
                :disabled="upload_loading"
                @click="upload()"
                color="#1550B2"
                class="mb-2 mr-2"
                outlined
            >
              업로드
              <v-icon
                  right
                  rounded
              >
                mdi-cloud-upload
              </v-icon>
            </v-btn>
            <v-btn
                color="#1550B2"
                class="mb-2 mr-2"
                outlined
                href="/files/keywords-test.csv"
            >
              <v-icon
              >
                mdi-download
              </v-icon>
            </v-btn>
            <v-dialog
                v-model="dialog"
                max-width="500px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                >
                  키워드
                  <v-icon
                      right
                  >
                    mdi-plus
                  </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-select
                            :items="topic_items"
                            v-model="editedItem.topic"
                            label="카테고리"
                            item-text="topic"
                            item-value="topic"
                        ></v-select>
                      </v-col>
                      <v-col>
                        <v-select
                            :items="platform_items"
                            v-model="editedItem.platform"
                            label="플랫폼"
                            item-text="platform_kr"
                            item-value="platform"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-textarea
                            outlined
                            v-model="editedItem.keyword"
                            label="* 키워드가 여러개일 경우 쉼표로 구분해주세요."
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      class="white--text"
                      color="#1239A3"
                      @click="save"
                  >
                    저장
                  </v-btn>
                  <v-btn
                      class="white--text"
                      color="#E23C44"
                      @click="close"
                  >
                    취소
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-btn v-if="grade !== 0"
                :loading="upload_loading"
                :disabled="upload_loading"
                @click="manualScrap()"
                color="#546E7A"
                class="mb-2 ml-2"
                outlined
            >
              수집
              <v-icon
                  right
                  rounded
              >
                mdi-database
              </v-icon>
            </v-btn>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-divider class="indigo mt-2 mb-4"></v-divider>
      <v-row class="pt-1">
        <v-col class="col-12" style="padding-top:0px; padding-bottom: 0px;">
          <p class="my-1">
              <v-chip
                  class="ma-1"
                  color="primary"
              >
                전체 {{ this.total }}개
              </v-chip>
              <v-chip
                  class="ma-1 white--text"
                  color="#22bb33"
              >
                활성 {{ this.active }}개
              </v-chip>
              <v-chip
                  class="ma-1 white--text"
                  color="rgb(218 33 36)"
              >
                비활성 {{ this.inactive }}개
              </v-chip>
            </p>
        </v-col>
      </v-row>

      <v-row v-if="loading" class="justify-center"
             :loading="loading"
      >
        <spinner :loading="loading"></spinner>
      </v-row>
      <v-row v-else class="pt-1">
          <v-col v-if="this.items.length === 0" justify="center" align="center" >
            <span class="subtitle-1">데이터가 존재하지 않습니다.</span>
          </v-col>
          <v-col v-else >
            <v-simple-table dense>
              <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center" style="width: 10% !important;">No.</th>
                      <th class="text-center" style="width: 10%!important;">플랫폼</th>
                      <th class="text-center" style="width: 15%!important;">카테고리</th>
                      <th class="text-center" style="width: 30%!important;">키워드</th>
                      <th class="text-center" style="width: 20%!important;">등록일</th>
                      <th class="text-center" style="width: 5%!important;">사용여부</th>
                      <th class="text-center" style="width: 10%!important;">수정 / 삭제</th>
                    </tr>
                  </thead>
                <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td class="text-center" style="width: 10% !important;">{{ item.no }}</td>
                    <td class="text-center" style="width: 10% !important;">
                      <v-icon
                          mid
                          dark
                          left
                          :color="getColor(item.platform)"
                      >
                        {{ item.icon }}
                      </v-icon>
                      {{ item.platform_kr }}
                    </td>
                    <td class="text-center" style="width: 15% !important;"></td>
                    <td class="text-center" style="width: 30% !important;">{{ item.keyword }}</td>
                    <td class="text-center" style="width: 20% !important;">{{ item.created_at }}</td>
                    <td class="text-left" style="width: 5% !important;">
                      <v-switch
                          v-model="item.state"
                          :true-value=1
                          :false-value=0
                          color="#22bb33"
                          class="my-2"
                          @change="changeState(item.id)"
                          hide-details
                      ></v-switch>
                    </td>
                    <td class="text-center" style="width: 15% !important;">
                      <v-icon
                          class="mr-1"
                          @click="editItem(item)"
                      >
                        mdi-pencil
                      </v-icon>
                      /
                      <v-icon
                          class="mr-1"
                          @click="deleteItem(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
              <v-pagination
                  v-if="this.searchCount > per_page"
                  class="mt-5 mb-5"
                  v-model="page"
                  :length="last_page"
                  circle
                  color="#1550B2"
                  prev-icon="mdi-chevron-left"
                  next-icon="mdi-chevron-right"
                  :total-visible="10"
                  @input="getData()"
              ></v-pagination>
          </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Breadcrumbs from "../../components/Breadcrumbs";
import Spinner from "../../components/Spinner";
import {mapGetters} from "vuex";
import numeral from "numeral";

export default {
    components: {
        Breadcrumbs,
        Spinner,
    },
    data() {
        return {
            loading: false,
            breadcrumbs: [
                {
                    text: '키워드 관리',
                    disabled: false,
                    href: '/keywords',
                },
            ],
            user_items: [],
            headers: [
                {text: 'NO.', sortable: false, value: 'no', width: '70px'},
                {text: '플랫폼', sortable: false, value: 'icon', width: '160px'},
                {text: '카테고리', sortable: false, value: 'topic', width: '140opx'},
                {text: '키워드', sortable: false, value: 'keyword', width: '200opx'},
                {text: '등록일', sortable: false, value: 'created_at', width: '200opx'},
                {text: '사용여부', sortable: false, value: 'state', width: '150opx'},
                {text: '수정/삭제', sortable: false, value: 'actions', width: '120opx'},
            ],
            items: [],
            topic_items: [],
            platform_items: [],
            media_id: null,
            dialog: false,
            upload_loading: false,
            file: null,
            editedItem: {
                media_id: 0,
                platform: '',
                keyword: '',
                created_at: '',
                state: '',
                topic: '',
            },
            defaultItem: {
                media_id: '',
                platform: '',
                keyword: '',
                created_at: '',
                state: '',
                topic: '',
            },
            editedIndex: -1,
            active: '',
            inactive: '',
            total: '',
            page: 1,
            per_page: 15,
            last_page: 1,
            search: '',
            searchCount: '', // total 또는 검색했을 때 값
            grade:''
        }
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? '키워드 추가' : '키워드 수정'
        },
        ...mapGetters({
            user: 'auth/user',
        }),
        getMediaId() {
          return this.$store.getters.getMediaId;
        },
    },
    watch: {
      media_id() {
        this.$store.commit("SET_MEDIA_ID", this.media_id);
      },
    },
    mounted() {
        this.media_id = this.getMediaId;
        const mediaId = this.media_id;
        if (mediaId) {
            this.media_id = parseInt(mediaId);
        } else {
            this.media_id = this.user.user_media[0].id;
        }

        if (localStorage.getItem('token')) {
            this.user_items = this.user.user_media;
            this.grade = this.user.grade;
        }
        this.getData();
        this.getCount();
        this.getPlatform();
    },
    methods: {
        medias(media) {
            this.page = 1;
            this.media_id = media;
            if (typeof this.media_id === "undefined") {
                this.media_id = this.user.user_media[0].id;
            } else {
                this.media_id = media;
            }
            this.getData();
            this.getCount();
        },
        getData() {
            let items = [];
            let header = '';
            this.loading = true;
            if (this.media_id === 1) {
                header = 'bloodpressure';
            } else if (this.media_id === 2) {
                header = 'golfzip';
            } else if (this.media_id === 3) {
                header = 'ticketoffice';
            } else if (this.media_id === 4) {
                header = 'tingstar';
            } else if (this.media_id === 5) {
                header = 'sivillage';
            }
            this.axios.get('api/v1/keywords', {
                params: {
                    'media_id': this.media_id,
                    'page': this.page,
                    'per_page': this.per_page,
                    'search': this.search ? this.search : '',
                },
                headers: {
                    'c9': header
                }
            })
            .then(res => {
                this.searchCount = res.data.data.totalCount; // total 또는 검색 했을 때 개수
                if (res.data.data.keywords.length > 0) {
                    res.data.data.keywords.map((item, index) => {
                        let icon = this.$root.getPlatformIconByName(item.platform);
                        let platform_kr = this.$root.getPlatformHanNameByName(item.platform);
                        let no = res.data.data.totalCount - (this.page - 1) * this.per_page - index;

                        items.push({
                            no: this.numberComma(no),
                            id: item.id,
                            media_id: item.media_id,
                            platform: item.platform,
                            platform_kr: platform_kr,
                            keyword: item.keyword,
                            created_at: item.created_at, // 등록일
                            state: item.state,
                            topic: item.topic,
                            icon: icon
                        })
                    })
                  }
                this.items = items;
                if (Number.isInteger(res.data.data.totalCount / this.per_page) == false) {
                  this.last_page = Math.floor(res.data.data.totalCount / this.per_page) + 1;
                } else {
                  this.last_page = res.data.data.totalCount / this.per_page;
                }
                this.loading = false;

                this.axios.get('api/v1/medias/' + this.media_id, {
                  headers: {
                    'c9': header
                  }
                })
                .then(res => {
                  this.topic_items = res.data.data.topics;
                })
                .catch(err => {
                  console.error(err);
                });
            })
            .catch(err => {
              console.error(err);
            });
        },
        getCount() {
            let header = '';
            this.loading = true
            if (this.media_id === 1) {
                header = 'bloodpressure';
            } else if (this.media_id === 2) {
                header = 'golfzip';
            } else if (this.media_id === 3) {
                header = 'ticketoffice';
            } else if (this.media_id === 4) {
                header = 'tingstar';
            } else if (this.media_id === 5) {
                header = 'sivillage';
            }
            this.axios.get('api/v1/keywords/stats?media_id=' + this.media_id, {
                headers: {
                    'c9': header
                }
            })
                .then(res => {
                    this.active = res.data.data.active;
                    this.inactive = res.data.data.inactive;
                    this.total = res.data.data.total;
                    this.loading = false;
                })
                .catch(err => {
                    console.error(err);
                });
        },
        save() {
            if(!this.editedItem.platform){
              this.$Swal.fire({
                icon: 'error',
                text: '플랫폼을 선택해주세요.',
              })
              return false;
            }
            if(!this.editedItem.keyword){
              this.$Swal.fire({
                icon: 'error',
                text: '키워드를 입력해주세요.',
              })
              return false;
            }
            if (this.editedIndex > -1) {
                Object.assign(this.items[this.editedIndex], this.editedItem)
                const data = {
                    media_id: this.media_id,
                    platform: this.editedItem.platform,
                    keyword: this.editedItem.keyword,
                    topic: this.editedItem.topic,
                    state: 1
                };
                this.axios.put('api/v1/keywords/' + this.editedItem.id, data).then(res => {
                    this.medias(res.data.data.media_id);
                }).catch(err => {
                    console.error(err);
                });
            } else {
                this.items.push(this.editedItem)
                const data = {
                    media_id: this.media_id,
                    platform: this.editedItem.platform,
                    data: this.editedItem.keyword,
                    topic: this.editedItem.topic,
                    type: 'keyword',
                    state: 1
                };
                this.axios.post('api/v1/keywords/text', data).then(res => {
                    console.log(res.data.data);
                    this.medias(this.media_id);
                }).catch(err => {
                    console.error(err);
                });
            }
            this.close()
        },
        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        changeState(id) {
            const item = this.items.filter(item => item.id === id)[0];
            const data = {
                media_id: item.media_id,
                platform: item.platform,
                keyword: item.keyword,
                state: item.state
            };
            this.axios.put('api/v1/keywords/' + id, data).then(res => {
                this.medias(res.data.data.media_id);
            }).catch(err => {
                console.error(err);
            });
        },
        editItem(item) {
            this.editedIndex = this.items.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },
        deleteItem(item) {
            this.editedIndex = this.items.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.$Swal.fire({
                text: '삭제 하시겠습니까?',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: '확인',
                denyButtonText: `취소`,
                confirmButtonColor: '#1550B2'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.axios.delete('api/v1/keywords/' + this.editedItem.id).then(res => {
                        this.medias(res.data.data.media_id);
                    }).catch(err => {
                        console.error(err);
                    });
                } else if (result.isDenied) {
                    // this.$Swal.fire('취소 되었습니다.', '', 'info')
                }
            })
        },
        upload() {
            if (this.file) {
                this.upload_loading = true;
                let formData = new FormData()
                formData.append('media_id', this.media_id ?? null)
                formData.append('type', 'keyword')
                formData.append('file', this.file ?? null)
                this.axios.post('api/v1/keywords/csv', formData, {
                    headers: {
                        'content-type': 'multipart/form-data'
                    },
                }).then(res => {
                    this.upload_loading = false;
                    this.file = null;
                    this.medias(res.data.data.media_id);
                }).catch(err => {
                    console.error(err);
                });
            } else {
                this.$Swal.fire({
                    icon: 'error',
                    text: '파일을 선택해주세요.',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.upload_loading = false;
                    }
                }).catch(err => {
                    console.error(err);
                });
            }
        },
        manualScrap() {
            this.$Swal.fire({
                text: '수집 스크래핑 진행하시겠습니까?',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: '확인',
                denyButtonText: `취소`,
                confirmButtonColor: '#1550B2'
            }).then((result) => {
                if (result.isConfirmed) {
                    const data = {
                        media_id: this.media_id,
                        type: 'keyword',
                    };
                    this.axios.post('api/v1/commands/jobs', data).then(res => {
                        this.$Swal.fire('수집 스크래핑 작업을 진행합니다. \n등록된 키워드가 많을수록 시간이 오래 걸립니다.', '', 'success')
                        this.medias(res.data.data.media_id);
                    }).catch(err => {
                        if (err.response.data.error.statusCode) {
                            this.$Swal.fire({
                                icon: 'error',
                                text: err.response.data.error.message,
                            }).then((result) => {
                                console.log(result);
                            }).catch(err => {
                                console.error(err);
                            });
                        }
                    });
                } else if (result.isDenied) {
                    // this.$Swal.fire('취소 되었습니다.', '', 'info')
                }
            })
        },
        getPlatform() {
            let result = [];
            this.loading = true
            this.axios.get('api/v1/platforms?state=1')
                .then(res => {
                    if (res.data.data.platforms.length > 0) {
                        res.data.data.platforms.map((item, index) => {
                            result.push({
                                no: index + 1,
                                id: item.id,
                                platform: item.platform,
                                platform_kr: item.platform_kr,
                            })
                        })
                    }
                    this.platform_items = result;
                    this.loading = false
                })
                .catch(err => {
                    console.error(err);
                });
        },
        getColor(platform) {
          return this.$root.getPlatformColorByName(platform);
        },
        searchChange() {
          this.page = 1;
          this.getData();
        },
        numberComma(value) {
          return numeral(value).format('0,0');
        },
    }
}
</script>

<style scoped>

</style>
