<template>
  <v-container>
    <Breadcrumbs :breadcrumbs="breadcrumbs"/>
    <v-row class="row mb-0 mt-1">
      <v-col class="col-4 ma-0 row ">
        <v-select style="max-width: 150px;"
                  :items="user_items"
                  v-model="media_id"
                  label="매체"
                  item-text="name"
                  item-value="id"
                  @change="medias"
                  hide-details
        ></v-select>
      </v-col>
      <v-col class="col-8 ma-0 row justify-end ">
        <v-select style="max-width: 150px;" class="mr-3"
                  :items="sort_items"
                  v-model="sort"
                  label="정렬 기준"
                  item-text="sort"
                  item-value="value"
                  @change="sortChange"
                  hide-details
        ></v-select>
        <v-btn-toggle
            v-model="dateChoice"
            class="mt-3 mr-3"
            color="deep-indigo accent-3"

        >
          <v-btn
              style="height: 40px;"
              value="daily"
          >
            <span class="hidden-sm-and-down">일간</span>
          </v-btn>

          <v-btn
              style="height: 40px;"
              value="weekly"
          >
            <span class="hidden-sm-and-down">주간</span>
          </v-btn>
          <v-btn
              style="height: 40px;"
              value="monthly"
          >
            <span class="hidden-sm-and-down">월간</span>
          </v-btn>
        </v-btn-toggle>
        <v-menu
            v-model="datepicker"
            :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <v-text-field class="row-pointer mr-2"
                          style="max-width: 110px;"
                          label="시작일"
                          append-icon="mdi-calendar"
                          v-on="on"
                          offset-y
                          :value="dateRanges[0]"
                          readonly
                          hide-details
            >
            </v-text-field>
          </template>
          <v-date-picker
              v-model="dateRanges[0]"
              no-title
              locale="ko-kr"
              changeMonth="true"
              :max="today"
              @input="dateSearchChange"
          />
        </v-menu>
        <p style="margin-top: 20px !important;">~</p>
        <v-menu
            v-model="end_datepicker"
            :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <v-text-field class="row-pointer ml-2 mr-2"
                          style="max-width: 110px;"
                          label="종료일"
                          append-icon="mdi-calendar"
                          v-on="on"
                          offset-y
                          :value="dateRanges[1]"
                          readonly
                          hide-details
            >
            </v-text-field>
          </template>
          <v-date-picker
              v-model="dateRanges[1]"
              no-title
              locale="ko-kr"
              changeMonth="true"
              :max="today"
              @input="dateSearchChange"
          />
        </v-menu>
      </v-col>
    </v-row>
    <v-divider class="indigo mt-2 mb-4"></v-divider>
    <v-row v-if="loading" class="justify-center"
           :loading="loading"
    >
      <spinner :loading="loading"></spinner>
    </v-row>
    <v-row class="mb-0">
      <v-col lg="12" class="justify-center pb-0 mb-0">
        <v-row class="ma-0">
          <v-col class="row pb-0 mb-0 pt-0 pr-0">
            <div class="font-weight-bold" style="margin-top: 2%;">키워드 (PK) 순위<span class="pl-2" style="color: gray;font-size: 12px;">순위는 100위까지 보여주며, <span style="text-decoration: underline; color: #3b3b3b;">회원</span>이 유입된 콘텐츠의 키워드 (PK)를 집계하여 순위를 보여줍니다.</span></div>
            <v-spacer></v-spacer>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
    <v-row class="mb-0 mt-0">
      <v-card width="100%" class="ma-3 elevation-0">
        <v-data-iterator
                :items="tag_items"
                :items-per-page.sync="per_page"
                :page.sync="page"
                hide-default-footer
            >
              <template v-slot:default="props">
                <v-row>
                  <v-col
                      v-for="item in props.items"
                      :key="item.dateType"
                      cols="12"
                      sm="4"
                      md="4"
                      lg="4"
                  >

                    <v-card
                        class="elevation-2"
                        style="overflow-y: auto;overflow-x: hidden;"
                        :min-height="respHeight"
                        :max-height="respHeight"
                    >
                      <v-card-title class="subheading font-weight-bold" style="padding:8px;">
                        {{ item.dateType }}
                        <span v-if="dateChoice === 'monthly'">
                          월
                        </span>
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-card-subtitle class="subheading font-weight-bold row">
                        <span style="width: 15%;" class="text-center">순위</span>
                        <span style="width: 40%;" class="text-center">태그</span>
                        <span style="width: 15%;" class="text-center">
                          <span v-if="sort === 'uv'" style="color: #283593;">UV</span>
                          <span v-else>UV</span>
                        </span>
                        <span style="width: 15%;" class="text-center">
                          <span v-if="sort === 'pv'" style="color: #283593;">PV</span>
                          <span v-else>PV</span>
                        </span>
                        <span style="width: 13%;" class="text-center">제외</span>
                      </v-card-subtitle>
                      <v-divider></v-divider>

                      <div v-if="item.tags">
                      <v-list
                        dense
                        v-for="(tag) in item.tags" :key="tag.tag"
                        style="padding:0px;"
                      >
                          <BusinessTagPopup
                              v-if="modal_tag === tag.tag && modal_dateType === item.dateType && showModal"
                              :item="tag"
                              :media_Id="media_id"
                              :modal_dateType="dateChoice"
                              :modal_searchDate = "item.dateType"
                              v-on:close="closeModal"
                          ></BusinessTagPopup>
                        <div v-if="tag.rank < 101"
                        >
                          <v-list-item
                            style="max-height:35px;"
                          >
                            <span style="width: 15%;" class="text-center text-subtitle-2">
                                <v-icon v-if="tag.rank === 1" color="rgb(255 190 0)">mdi-crown-outline</v-icon>
                                {{ tag.rank }}위
                            </span>
                            <v-list-item-content
                                @click="createModal(tag, item.dateType)"
                                style="padding:0px;"
                            >
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }" >
                                  <span style="width: 40%;cursor: pointer;margin:0px;" class="text-center text-subtitle-2"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    {{ tag.tag }}
                                  </span>
                                </template>
                                <span>클릭 시 게시정보를 확인할 수 있습니다.</span>
                              </v-tooltip>
                            </v-list-item-content>
                            <span style="width: 16%;" class="text-center text-subtitle-2">{{ tag.uv_count }}번</span>
                            <span style="width: 15%;" class="text-center text-subtitle-2">{{ tag.count }}번</span>
                            <span style="width: 14%;" class="text-center">
                              <v-btn
                                  x-small
                                  plain
                                  color="white"
                                  class="my-1"
                                  @click="tagIgnore(tag.tag)"
                                  style="min-height:28px; max-width: 26px;"
                              >
                                <v-icon
                                    color="rgb(255,80,80)"
                                >
                                  mdi-bookmark-remove
                                </v-icon>
                              </v-btn>
                            </span>
                          </v-list-item>
                          <v-divider></v-divider>
                        </div>
                      </v-list>
                      </div>
                      <div v-else>
                        <v-list dense>
                          <v-list-item>
                            <span style="width: 100%;padding-top: 35%;" class="text-center">해당 {{ date_type_text }}에는 데이터가 없습니다.</span>
                          </v-list-item>
                        </v-list>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
          <template v-slot:footer>
            <v-row
                class="mt-4 mr-1"
                align="end"
                justify="end"
            >
              <span
                  class="mr-4 mt-4 grey--text"
              >
                현재 페이지 : {{ page }} / 전체 페이지 : {{ tagNumberOfPages }}
          </span>
              <v-btn
                  fab
                  small
                  dark
                  color="rgb(56 71 179)"
                  class="mr-1"
                  @click="tagFormerPage"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                  fab
                  small
                  dark
                  color="rgb(56 71 179)"
                  class="ml-1"
                  @click="tagNextPage"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-row>
          </template>
            </v-data-iterator>
      </v-card>
    </v-row>
    <v-row class="mb-0">
      <v-col lg="12" class="justify-center pb-0 mb-0">
        <div class="font-weight-bold">키워드 (PK) 콘텐츠 매칭 순위<span class="pl-2" style="color: gray;font-size: 12px;">가장 많이 콘텐츠와 매칭된 비즈니스태그를 최대 10개까지 보여줍니다.</span></div>
      </v-col>
    </v-row>
    <v-row class="mb-0">
      <v-col lg="12" class="justify-center">
        <v-card width="100%" style="height: 500px;">
          <v-card-text class="justify-center" style="text-align: -webkit-center;">
            <v-row no-gutters class="justify-center" >
              <div ref="chartTagDiv" style="width: 80%; height: 350px;"></div>
              <v-simple-table class="mt-2 text-center" style="border: 1px solid #e7e7e7;width: 100%;height: 110px !important;">
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th
                        v-for="(item, i) in business_tag_items" v-bind:key="i"
                        class="text-center"
                    >
                      {{ item.tag }}
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td
                        v-for="(item, i) in business_tag_items" v-bind:key="i"
                        :class="{'text-center':true, 'red--text':item.max, 'blue--text':i.min}"
                    >
                      {{ item.count }}
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
<!--              <v-col v-if="tag_loading" class="justify-center" style="width: 100%; height: 350px;"
                     :loading="tag_loading"
              >
                <card-spinner :loading="tag_loading"></card-spinner>
              </v-col>-->
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Breadcrumbs from "../../components/Breadcrumbs";
import Spinner from "../../components/Spinner";
//import CardSpinner from "../../components/CardSpinner";
import {mapGetters} from "vuex";
import numeral from 'numeral';
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import dayjs from "dayjs";
import * as am4plugins_forceDirected from "@amcharts/amcharts4/plugins/forceDirected";
import * as am4charts from "@amcharts/amcharts4/charts";
import BusinessTagPopup from "@/components/BusinessTagPopup";

am4core.useTheme(am4themes_animated);

export default {
  components: {
    Breadcrumbs,
   // CardSpinner,
    Spinner,
    BusinessTagPopup,
  },
  data() {
    return {
      loading: false,
      //tag_loading: false,
      breadcrumbs: [
        {
          text: '키워드 (PK) 순위',
          disabled: false,
          href: '/statistic-tag-ranks',
        },
      ],
      tag_length: 0,
      top_count: 10,
      per_page: 3,
      page: 1,
      tag_items: [],
      user_items: [],
      media_id: null,
      business_tag_items: [],
      business_tag_media_name: '',
      datepicker: false,
      end_datepicker: false,
      dateRanges : [],
      sort: 'uv',
      sort_items: [
        {sort: 'PV 많은 순', value: 'pv'},
        {sort: 'UV 많은 순', value: 'uv'},
      ],
      dateChoice : '',
      date_type_text : '일',
      today : dayjs(dayjs().format("YYYY-MM-DD")).format('YYYY-MM-DD'),
      showModal: false,
      modal_tag: '',
      modal_dateType: '',
      respHeight:720,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    getMediaId() {
      return this.$store.getters.getMediaId;
    },
    tagNumberOfPages () {
      return Math.ceil(this.tag_items.length / this.per_page)
    },
  },
  watch: {
    dateRanges: {
      handler() {
        this.$store.commit("SET_DATERANGES", this.dateRanges);
        if(!this.dateRanges[0] && this.dateRanges[1]){
          this.$store.commit("SET_DATERANGES", []);
        }
        if (this.dateRanges[0] > this.dateRanges[1]) {
          this.dateRanges.reverse();
          this.dateSearchChange();
        }

        if(this.getDateRanges && this.getDateRanges !== this.dateRanges) this.dateRanges = this.getDateRanges; // 아래 코드 error 로 대체
        /*
        if(this.getDateRanges[0] !== this.dateRanges[0] || this.getDateRanges[1] !== this.dateRanges[1]) {
          if(!this.dateRanges[0])  this.dateRanges[0] = this.getDateRanges[0];
          if(!this.dateRanges[1])  this.dateRanges[1] = this.getDateRanges[1];
        }
        */
        this.getBusinessTagCount();
      }
    },
    page() {
      this.$store.commit("SET_PAGE", this.page);
    },
    media_id() {
      this.$store.commit("SET_MEDIA_ID", this.media_id);
    },
    dateChoice(){
      this.$store.commit("SET_DATECHOICE", this.dateChoice);
      this.$store.commit("SET_DATERANGES", this.dateRanges);
      if(this.dateChoice === 'daily'){
        this.date_type_text = '일';
        this.dateRanges[0] = dayjs(dayjs().format("YYYY-MM-DD")).subtract(3, 'day').format('YYYY-MM-DD');
        this.dateSearchChange();
      }
      if(this.dateChoice === 'weekly'){
        this.date_type_text = '주';
        this.dateRanges[0] = dayjs(dayjs().format("YYYY-MM-DD")).subtract(14, 'week').format('YYYY-MM-DD');
        this.dateSearchChange();
      }
      if(this.dateChoice === 'monthly'){
        this.date_type_text = '월';
        this.dateRanges[0] = dayjs(dayjs().format("YYYY-MM-DD")).subtract(14, 'month').format('YYYY-MM-DD');
        this.dateSearchChange();
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    window.dispatchEvent(new Event('resize'));

    this.dateRanges[0] = dayjs(dayjs().format("YYYY-MM-DD")).subtract(3, 'day').format('YYYY-MM-DD');
    this.dateRanges[1] = this.today;

    const mediaId = this.media_id;
    if (mediaId) {
      this.media_id = parseInt(mediaId);
    } else {
      this.media_id = this.user.user_media[0].id;
    }

    if(this.getMediaId && this.getMediaId !== this.media_id) this.media_id = this.getMediaId;

    if(this.getPage !== 1) this.page = this.getPage;
    if(this.getPage === 1 && this.page !== this.getPage) this.page = 1;
    if(this.getDateRanges && this.getDateRanges !== this.dateRanges) this.dateRanges = this.getDateRanges;
    if(this.getDataChoice && this.getDataChoice !== this.dateChoice) this.dateChoice = this.getDataChoice;
    if(!this.dateChoice) this.dateChoice = 'daily';

    if(!this.dateRanges[0] && this.dateRanges[1]){
      this.$store.commit("SET_DATERANGES", []);
    }

    if (localStorage.getItem('token')) {
      this.user_items = this.user.user_media;
    }
    this.changeDatas();

  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      //this.windowWidth = window.innerWidth;
      this.respHeight = window.innerHeight - 380;
    },
    tagNextPage () {
      if (this.page + 1 <= this.tagNumberOfPages) this.page += 1
    },
    tagFormerPage () {
      if (this.page - 1 >= 1) this.page -= 1
    },
    medias(media) {
      this.changePages();
      this.media_id = media;
      if (typeof this.media_id === "undefined") {
        this.media_id = this.user.user_media[0].id;
      } else {
        this.media_id = media;
      }
      this.changeDatas();
    },
    numberComma(value) {
      return numeral(value).format('0,0');
    },
    getTagDailyCount(){ // 사용자 유입 비즈니스태그 순위
      let header = this.$root.getMediaEngNameById(this.media_id);
      let result = [];
      this.loading = true;

      this.axios.get('api/v1/tags/daily-stats', {
        params: {
          'media_id': parseInt(this.media_id),
          'start_date': this.dateRanges ? this.dateRanges[0] : '',
          'end_date': this.dateRanges ? this.dateRanges[1] : '',
          'date_choice': this.dateChoice ? this.dateChoice : 'daily',
          'sort': this.sort,
        },
        headers: {
          'c9': header
        }
      })
          .then(res => {
            this.loading = false;
            res.data.data.data.map((item) => {
              // 순위 추가
              if(typeof item.tags !== 'undefined'){
                let keys = Object.keys(item.tags);
                for (let i = 0; i < keys.length; i++) {
                  item.tags[keys[i]]['rank'] = i+1;
                }
              }
              let dateType = item.dateType;
              if(this.dateChoice === 'daily') dateType = item.dateType+"("+this.getDay(item.dateType)+")";
              result.push({
                dateType: dateType,
                tags: item.tags,
                tag_count: item.tag_count - item.tag_count + 1,
              })
            })
            this.tag_items = result;
            this.tag_length = res.data.data.total;
            this.loading = false;
          })
          .catch(err => {
            console.error(err);
            location.reload();
          });
    },
    dateSearchChange() {
      this.changePages();
      if (this.dateRanges[0] && this.dateRanges[1]) {
        this.changeDatas();
      } else {
        if(this.dateRanges[0]){
          this.changeDatas();
          this.dateRanges[1] = this.today;
        }else{
          this.$Swal.fire({
            icon: 'error',
            text: '시작 날짜를 선택해주세요.',
          }).then((result) => {
            if (result.isConfirmed) {
              location.reload();
            }
          }).catch(err => {
            console.error(err);
          });
        }
      }
      this.datepicker = false;
      this.end_datepicker = false;
    },

    getBusinessTagCount(){ // 비즈니스 태그
      let result = [];
      let header = this.$root.getMediaEngNameById(this.media_id);
      this.tag_loading = true;
      this.axios.get('api/v1/tags/stats', {
        params: {
          'media_id': parseInt(this.media_id),
        },
        headers: {
          'c9': header
        }
      })
          .then(res => {
            this.business_tag_media_name = res.data.data.name;
            res.data.data.tag.map((item) => {
              result.push({
                tag: item.tag,
                count: this.numberComma(item.count)+"개",
              })
            })
            this.business_tag_items = result;
            this.drawBusinessTagChart();
          })
          .catch(err => {
            console.error(err);
            location.reload();
          });
    },
    drawBusinessTagChart() { // 비즈니스 태그 그래프 그리기
      let chart = am4core.create(this.$refs.chartTagDiv, am4plugins_forceDirected.ForceDirectedTree);
      chart.legend = new am4charts.Legend();

      let series = chart.series.push(new am4plugins_forceDirected.ForceDirectedSeries())
      series.data = [{
        name: this.business_tag_media_name, // 매체명
        children: this.business_tag_items
      }];
      series.dataFields.value = "count";
      series.dataFields.name = "tag";
      series.dataFields.children = "children";
      series.dataFields.color = "color";

      series.nodes.template.label.text = "{name}"  // 매체명
      series.nodes.template.tooltipText = "{count}";
      series.fontSize = 12;
      series.minRadius = am4core.percent(4);
      series.maxRadius = am4core.percent(7);
      series.centerStrength = 0.8;
      series.manyBodyStrength = -8;
      series.nodes.template.fillOpacity = 1;
      series.nodes.template.outerCircle.scale = 1;
      series.nodes.template.label.hideOversized = true;
      series.nodes.template.label.truncate = true;
      series.links.template.strokeOpacity = 1;
      series.appear(1000, 100);
      this.tag_loading = false;
    },
    changeDatas(){
      this.getTagDailyCount();
      this.getBusinessTagCount();
    },
    changePages(){
      this.page = 1;
    },
    getDay(date){
      const week = ['일', '월', '화', '수', '목', '금', '토'];
      return week[new Date(date).getDay()];
    },
    sortChange(value) {
      this.sort = value;
      this.changeDatas();
    },
    tagIgnore(tag) {
      const data = [{
        media_id: this.media_id,
        tag: tag
      }]
      this.axios.post('api/v1/tags/ignore', data).then(res=>{
          this.changeDatas();
          res;
      })
        .catch(err => {
        console.error(err);
      });
    },

      createModal : function(index, dateType) {
          this.modal_tag = index.tag;
          this.modal_dateType = dateType;
          this.showModal = true;
      },
      closeModal : function() {
          this.showModal = false;
      },
  }
}
</script>

<style scoped>

</style>
