
<template>
    <v-overlay z-index="100">
        <v-row v-if="loading" class="justify-center"
               align="center"
               :loading="loading"
               z-index="200"
        >
            <spinner :loading="loading"></spinner>
        </v-row>
        <v-card
            style="background-color: #ffffff; color: #000000;width:840px; max-height: 950px; overflow-y: hidden;overflow-x: hidden;"
            class="mx-auto my-auto "
            outlined
            v-if="!loading"
        >
          <v-row>
            <v-col style="padding:36px">
              <span class="text-h5">"{{ item.tag }}" 게시정보</span>
            </v-col>
            <v-col style="padding:24px">
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn style="color: black; "
                       small
                       outlined
                       @click="closeModal"
                >
                  <v-icon
                      small
                  >
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
            <v-row class="flexDiv justify-center" style="margin:0px !important;padding:3px;">
                <v-col v-if="this.items.length === 0 ">
                    <div class="d-flex flex-nowrap justify-center" >
                        <span class="subtitle-1">데이터가 존재하지 않습니다.</span>
                    </div>
                </v-col>
                <v-col v-else
                       cols="2" sm="auto" md="auto" lg="auto"
                       v-for="(item, i) in items" :key="i"
                       style="flex-grow: unset;width:400px !important;"
                >
                <v-row
                    style="margin-bottom: 15px;margin-top:-5px;"
                    outlined no-gutters
                >
                    <v-col class="col-5 col-lg-5 col-md-5 col-sm-5" style="padding-right: 0px !important;">
                        <v-img
                            v-if="item.storage_thumbnail_url"
                            :src="item.storage_thumbnail_url"
                            class="row-pointer"
                            height="150px"
                            width="149px"
                            style="margin: 0; padding: 0;"
                            @click="byMoreView(item.id)"
                        >
                            <v-icon
                                style="background-color: #000000;"
                                mid
                                dark
                                left
                                color="#ffffff"
                            >
                                {{ item.icon }}
                            </v-icon>
                        </v-img>

                    </v-col>
                    <v-col class="col-7 col-lg-7 col-md-7 col-sm-7" style="padding-left: 0px !important; height:150px;">
                        <v-card-title class="subtitle-2"
                            v-if="(item.platform === 'google-news' || item.platform === 'naver-blog') && item.title && item.title.length >= 35"
                        >
                            {{ item.title.substr(0, 35) + '...' }}
                        </v-card-title>
                        <v-card-title class="subtitle-2"
                            v-else-if="(item.platform === 'google-news' || item.platform === 'naver-blog') && item.title && item.title.length < 35"
                        >
                            {{ item.title }}
                        </v-card-title>
                        <v-card-subtitle>
                            <span v-if="item.contents && item.contents.length >= 14">{{ item.contents.substr(0, 14) + '...' }}</span>
                            <span v-else-if="item.contents && item.contents.length < 14">{{ item.contents }}</span>
                            <span v-else>내용없음</span><br>
                            <span v-if="item.business_tag && item.business_tag.length >= 14">{{ item.business_tag.substr(0, 14) + '...' }}</span>
                            <span v-else-if="item.business_tag && item.business_tag.length < 14">{{ item.business_tag }}</span>
                            <span v-else>#태그없음</span>
                        </v-card-subtitle>
                    </v-col>
                </v-row>
                <v-divider class="indigo"></v-divider>
                </v-col>
            </v-row>
            <v-pagination
                v-if="!loading && this.searchCount > per_page"
                class="mt-10 mb-5"
                v-model="page"
                :length="last_page"
                circle
                color="#1550B2"
                prev-icon="mdi-chevron-left"
                next-icon="mdi-chevron-right"
                :total-visible="10"
                @input="getData()"
                style = "padding-bottom: 15px;"
            ></v-pagination>

        </v-card>
    </v-overlay>
</template>

<script>
import Spinner from "@/components/Spinner";
import {mapGetters} from "vuex";

export default {
    components: {
        Spinner
    },
    props: ['item','media_Id', 'modal_dateType', 'modal_searchDate'],
    computed: {
        ...mapGetters({
            user: 'auth/user',
        }),
        /*
        getPage() {
            return this.$store.getters.getPageModal;
        },
         */
        getMediaId() {
            return this.$store.getters.getMediaId;
        },
    },
    watch: {
        page() {
            this.$store.commit("SET_PAGE_MODAL", this.page);
            this.getData();
        },
    },
    mounted() {
        if(this.getMediaId && this.getMediaId !== this.media_Id) this.media_Id = this.getMediaId;
        /*
        if(this.getPage !== 1) this.page = this.getPage;
        if(this.getPage === 1 && this.page !== this.getPage) this.page = 1;
         */
        this.getData();
    },
    data() {
        return {
            loading: false,
            items: [],
            page: 1,
            per_page: 6,
            last_page: 1,
            searchCount: '',
            sendToParent: '',
        }
    },
    methods: {
        getData() {
            let result = [];
            let img = '';
            this.loading = true;
            let header = this.$root.getMediaEngNameById(this.media_Id);
            let url = this.$parent.$root.getStorageUrlByMediaName(header);

            this.axios.get('/api/v1/admin/articlesByTag', {
                params: {
                    //'page': this.page,
                    'per_page': this.per_page,
                    'tag': this.item.tag,
                    'media_id': this.media_Id,
                    'dateType': this.modal_dateType,
                    'searchDate': this.modal_searchDate
                },
                headers: {
                    'c9': header
                }
            }).then(res=> {
                if (res.data.data.articles.length > 0) {
                        res.data.data.articles.map((item, index) => {
                            if (item.storage_thumbnail_url) {
                            img = url + item.storage_thumbnail_url;
                        } else {
                            if (item.article_medias[0]) {
                                img = url + item.article_medias[0].storage_url;
                            } else {
                                img = './images/no-image.png';
                            }
                        }

                        let contents = '';
                        if(item.contents.length > 0) {
                            contents = item.contents;
                        } else {
                            contents = "내용 없음";
                        }

                        if(item.platform === 'instagram' && item.article_medias.length > 0 && item.article_medias[0].storage_url){
                            item.article_medias = item.article_medias.map((i) => {
                                return {
                                    storage_url: i.storage_url ? url + i.storage_url : '',
                                    not_full_storage_url: i.storage_url ? i.storage_url : '',
                                    width: i.width,
                                    height: i.height,
                                }
                            })
                        }else{
                            item.article_medias = [];
                            item.article_medias.push({
                                storage_url: img,
                                width: item.thumbnail_width,
                                height: item.thumbnail_height,
                                is_thumbnail: true,
                            });
                        }

                        let icon = this.$root.getPlatformIconByName(item.platform);
                        let date = item.date.substr(0, 10);
                        let diff_date = item.created_at.substr(0, 10);
                        let sort_text = '작성일';
                        let diff_sort_text = '수집일';
                        if(this.sort === 'sort_id') {
                            date = item.created_at.substr(0, 10);
                            diff_date = item.date.substr(0, 10);
                            sort_text = '수집일';
                            diff_sort_text = '작성일';
                        }
                        result.push({
                            no: index + 1,
                            id: item.id,
                            platform: item.platform,
                            type: item.type,
                            keyword: item.keyword,
                            channel: item.channel,
                            url: item.url,
                            date: date,
                            sort_text: sort_text,
                            diff_date: diff_date,
                            diff_sort_text: diff_sort_text,
                            state: item.state,
                            storage_thumbnail_url: img,
                            icon: icon,
                            business_tag: item.business_tag ? '#' + item.business_tag : '',
                            tag_activity: item.tag_activity,
                            title: item.title,
                            article_medias: item.article_medias,
                            thumbnail_width: item.thumbnail_width,
                            thumbnail_height: item.thumbnail_height,
                            contents: contents,
                        })
                    })
                }

                this.searchCount = res.data.data.totalCount;
                this.items = result;
                this.count = this.items.length;
                if (Number.isInteger(this.searchCount / this.per_page) == false) {
                    this.last_page = Math.floor(this.searchCount / this.per_page) + 1;
                } else {
                    this.last_page = this.searchCount / this.per_page
                }

                this.loading = false;
            }).catch(err => {
                console.error(err);
            })
        },

        closeModal: function () {
            this.$emit('close');
        },
        byMoreView(id){
            let header = this.$root.getMediaEngNameById(this.media_Id);
            this.$router.push('/'+id+'?c9='+header);
        },
    }
}
</script>
