<template>
  <v-container>
    <Breadcrumbs :breadcrumbs="breadcrumbs"/>
    <v-row class="row mb-0 mt-1">
      <v-col class="col-lg-4 ma-0 row ">
        <v-select style="max-width: 150px;"
                  :items="user_items"
                  v-model="media_id"
                  label="매체"
                  item-text="name"
                  item-value="id"
                  @change="medias"
                  hide-details
        ></v-select>
      </v-col>
      <v-col class="col-lg-8 ma-0 " >
        <v-btn
            class="white--text float-right mt-3"
            color="#283593"
            @click="save"
        >
          게시물 가중치 저장
        </v-btn>
      </v-col>
    </v-row>
    <v-divider class="indigo mt-2 mb-4"></v-divider>
    <v-row v-if="loading" class="justify-center"
           :loading="loading"
    >
      <spinner :loading="loading"></spinner>
    </v-row>
    <v-row class=" mb-0 mt-8">
      <v-col class="justify-center"
      >
        <v-card>
          <v-card-text class="justify-center" style="text-align: -webkit-center;">
            <v-slider class="mt-10 mb-5" style="width: 50%;"
                      v-model="recommend_weight"
                      min="0"
                      max="100"
                      step="10"
                      label="추천 가중치 비율(%)"
                      thumb-color="red"
                      thumb-label="always"
            ></v-slider>

            <v-slider class="mt-10 mb-5" style="width: 50%;"
                      v-model="normal_weight"
                      min="0"
                      max="100"
                      step="10"
                      label="일반 가중치 비율(%)"
                      thumb-color="blue"
                      thumb-label="always"
            ></v-slider>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="ma-0" >
        <v-btn
            class="white--text float-right mt-3"
            color="#283593"
            @click="saveExposureGrade"
        >
          개인화 저장
        </v-btn>
      </v-col>
    </v-row>

    <v-card>
    <v-row class=" mb-0 mt-8">

      <v-col cols="8" class="justify-center">
        <v-row>
          <v-col cols="12" class="mt-5" align="center" justify="center" >
            <h2>※ 각 등급 별 할당율</h2>
          </v-col>
        </v-row>
          <v-card-text
              class="justify-center mt-10"
              style="text-align: -webkit-center;"
          >
            <v-slider
                class="mt-10 mb-5 ml-10 align-center"
                style="width: 80%;"
                v-model="grade_1"
                :min=grade_min
                :max=grade_max
                step="1"
                label="1등급 비율(%)"
                thumb-label="always"
                color="red"
                track-color="gray"
                @change="setGradePercent()"
            >
              <template v-slot:append>
                <v-text-field
                    v-model="grade_1"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    type="number"
                    prefix="%"
                    style="width: 60px"
                    readonly
                    reverse
                ></v-text-field>
                <span class="mt-2"></span>
              </template>
            </v-slider>
            <v-range-slider
                class="mt-10 mb-5 ml-10 align-center"
                style="width: 80%;"
                v-model="grade_2"
                :min=grade_min
                :max=grade_max
                step="1"
                label="2등급 비율(%)"
                thumb-label="always"
                thumb-color="blue"
                track-fill-color="blue"
                track-color="gray"
                @change="setGradePercent()"
            >
              <template v-slot:append>
                <v-text-field
                    v-model="grade_2_percent"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    type="number"
                    prefix="%"
                    style="width: 60px"
                    readonly
                    reverse
                ></v-text-field>
                <span class="mt-2"></span>
              </template>
            </v-range-slider>
            <v-range-slider
                class="mt-10 mb-5 ml-10 align-center"
                style="width: 80%;"
                v-model="grade_3"
                :min=grade_min
                :max=grade_max
                step="1"
                label="3등급 비율(%)"
                thumb-label="always"
                thumb-color="green"
                track-fill-color="green"
                track-color="gray"
                @change="setGradePercent()"
            >
              <template v-slot:append>
                <v-text-field
                    v-model="grade_3_percent"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    type="number"
                    prefix="%"
                    style="width: 60px"
                    readonly
                    reverse
                ></v-text-field>
                <span class="mt-2"></span>
              </template>
            </v-range-slider>
            <v-range-slider
                class="mt-10 mb-5 ml-10 align-center"
                style="width: 80%;"
                v-model="grade_4"
                :min=grade_min
                :max=grade_max
                step="1"
                label="4등급 비율(%)"
                thumb-label="always"
                thumb-color="orange"
                track-fill-color="orange"
                track-color="gray"
                @change="setGradePercent()"
            >
              <template v-slot:append>
                <v-text-field
                    v-model="grade_4_percent"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    type="number"
                    prefix="%"
                    style="width: 60px"
                    readonly
                    reverse
                ></v-text-field>
                <span class="mt-2"></span>
              </template>
            </v-range-slider>
          </v-card-text>

      </v-col>

      <v-col cols="4" class="justify-center">
        <v-row class=" mb-0 mt-2" style="margin-right:20px;">
          <v-col cols="3" class="mt-5" align="center" justify="center" >
            기준일
          </v-col>
          <v-col cols="3">
            <v-text-field
                v-model="reference_day"
                label="기준일"
                clearable
            ></v-text-field>
          </v-col>
          <v-col cols="3" class="mt-5" align="center" justify="center" >
            주기
          </v-col>
          <v-col cols="3">
            <v-text-field
                v-model="reference_day_term"
                label="주기"
                clearable
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class=" mb-0 mt-2" style="margin-right:20px;">
          <v-col cols="3" class="mt-5" align="center" justify="center" >
            1등급
          </v-col>
          <v-col cols="9">
            <v-row>
              <v-col cols="6">
                <v-text-field
                    v-model="grade_1_ref_from"
                    class="float-left"
                    style="max-width:134px; min-width: 116px;"
                    label="기준일"
                    suffix="일 전부터"
                    clearable
                    disabled
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="grade_1_ref_to"
                    class="float-left"
                    style="max-width:134px; min-width: 116px;"
                    label="주기"
                    clearable
                    disabled
                    suffix="일 전까지"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class=" mb-0 mt-2" style="margin-right:20px;">
          <v-col cols="3" class="mt-5" align="center" justify="center" >
            2등급
          </v-col>
          <v-col cols="9">
            <v-row>
              <v-col cols="6">
                <v-text-field
                    v-model="grade_2_ref_from"
                    class="float-left"
                    style="max-width:134px; min-width: 116px;"
                    label="기준일"
                    suffix="일 전부터"
                    clearable
                    disabled
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="grade_2_ref_to"
                    class="float-left"
                    style="max-width:134px; min-width: 116px;"
                    label="주기"
                    clearable
                    disabled
                    suffix="일 전까지"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class=" mb-0 mt-2" style="margin-right:20px;">
          <v-col cols="3" class="mt-5" align="center" justify="center" >
            3등급
          </v-col>
          <v-col cols="9">
            <v-row>
              <v-col cols="6">
                <v-text-field
                    v-model="grade_3_ref_from"
                    class="float-left"
                    style="max-width:134px; min-width: 116px;"
                    label="기준일"
                    suffix="일 전부터"
                    clearable
                    disabled
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="grade_3_ref_to"
                    class="float-left"
                    style="max-width:134px; min-width: 116px;"
                    label="주기"
                    clearable
                    disabled
                    suffix="일 전까지"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class=" mb-0 mt-2" style="margin-right:20px;">
          <v-col cols="3" class="mt-5" align="center" justify="center" >
            4등급
          </v-col>
          <v-col cols="9">
            <v-row>
              <v-col cols="6">
                <v-text-field
                    v-model="grade_4_ref_from"
                    class="float-left"
                    style="max-width:134px; min-width: 116px;"
                    label="기준일"
                    suffix="일 전부터"
                    clearable
                    disabled
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="grade_4_ref_to"
                    class="float-left"
                    style="max-width:134px; min-width: 116px;"
                    label="주기"
                    clearable
                    disabled
                    suffix="일 전까지"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    </v-card>
  </v-container>
</template>

<script>
import Breadcrumbs from "../../components/Breadcrumbs";
import Spinner from "../../components/Spinner";
import {mapGetters} from "vuex";
export default {
  components: {
    Breadcrumbs,
    Spinner,
  },
  data() {
    return {
      loading: false,
      breadcrumbs: [
        {
          text: '노출 가중치 관리',
          disabled: false,
          href: '/exposure',
        },
      ],
      user_items: [],
      media_id: null,
      recommend_weight: 0,
      normal_weight: 0,
      grade_1: 0,
      grade_2: [0,0],
      grade_3: [0,0],
      grade_4: [0,0],
      grade_min: 0,
      grade_max: 100,
      grade_2_percent: 30,
      grade_3_percent: 15,
      grade_4_percent: 5,
      reference_day: 0,
      reference_day_term: 0,
      grade_1_ref_from: 0,
      grade_1_ref_to: 0,
      grade_2_ref_from: 0,
      grade_2_ref_to: 0,
      grade_3_ref_from: 0,
      grade_3_ref_to: 0,
      grade_4_ref_from: 0,
      grade_4_ref_to: 0,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    getMediaId() {
      return this.$store.getters.getMediaId;
    },
  },
  watch: {
    recommend_weight: {
      handler() {
        if (this.recommend_weight && this.recommend_weight !== 0) {
          this.normal_weight = 100-this.recommend_weight;
        }
      }
    },normal_weight: {
      handler() {
        if (this.normal_weight && this.normal_weight !== 0) {
          this.recommend_weight = 100-this.normal_weight;
        }
      }
    },
    reference_day: {
      handler() {
        if(this.reference_day === null || this.reference_day === '') this.reference_day = 0;
        this.grade_1_ref_to = parseInt(this.reference_day);
        this.grade_2_ref_from = this.grade_1_ref_to + 1;
        this.grade_2_ref_to = this.grade_2_ref_from + this.reference_day_term;
        this.grade_3_ref_from = this.grade_2_ref_to + 1;
        this.grade_3_ref_to = this.grade_3_ref_from + this.reference_day_term;
        this.grade_4_ref_from = this.grade_3_ref_to + 1;
        this.grade_4_ref_to = this.grade_4_ref_from + this.reference_day_term;
      }
    },
    reference_day_term: {
      handler() {
        if(this.reference_day_term === null || this.reference_day_term === '') this.reference_day_term = 0;
        this.grade_1_ref_to = parseInt(this.reference_day);
        this.grade_2_ref_from = this.grade_1_ref_to + 1;
        this.grade_2_ref_to = this.grade_2_ref_from + this.reference_day_term;
        this.grade_3_ref_from = this.grade_2_ref_to + 1;
        this.grade_3_ref_to = this.grade_3_ref_from + this.reference_day_term;
        this.grade_4_ref_from = this.grade_3_ref_to + 1;
        this.grade_4_ref_to = this.grade_4_ref_from + this.reference_day_term;
      }
    },
    grade_1: {
      handler() {
        if(this.grade_1 < 0 || this.grade_1 === null || this.grade_1 === '') this.grade_1 = 0;
        this.grade_2 = [this.grade_1, this.grade_2[1]];
      }
    },
    grade_2: {
      handler() {
        if(this.grade_2[0] < this.grade_1) this.grade_2[0] = this.grade_1;
        this.grade_2_percent = this.grade_2[1] - this.grade_2[0];
      }
    },
    grade_3: {
      handler() {
        if(this.grade_3[0] < this.grade_2[1]) this.grade_3[0] = this.grade_2[1];
      }
    },
    grade_4: {
      handler() {
        if(this.grade_4[0] < this.grade_3[1]) this.grade_4[0] = this.grade_3[1];
      }
    },
    // grade_2_percent: {
    //   handler() {
    //     // this.grade_2[1] = [this.grade_1, this.grade_1 + this.grade_2_percent];
    //   }
    // },
    // grade_3_percent: {
    //   handler() {
    //
    //   }
    // },
    // grade_4_percent: {
    //   handler() {
    //
    //   }
    // },
    media_id() {
      this.$store.commit("SET_MEDIA_ID", this.media_id);
    },
  },
  mounted() {
    this.media_id = this.getMediaId;
    const mediaId = this.media_id;
    if (mediaId) {
      this.media_id = parseInt(mediaId);
    } else {
      this.media_id = this.user.user_media[0].id;
    }
    // 추천(50) : 일반(50) 비중은 default
    if(this.recommend_weight === 0 && this.normal_weight === 0){
      this.recommend_weight = 50;
      this.normal_weight = 50;
    }
    if(this.recommend_weight && this.recommend_weight !== 0){
      this.normal_weight = 100-this.recommend_weight;
    }
    if (localStorage.getItem('token')) {
      this.user_items = this.user.user_media;
    }


    this.getData();
    this.getExposureGrade();
  },
  methods: {
    medias(media) {
      this.media_id = media;
      if (typeof this.media_id === "undefined") {
        this.media_id = this.user.user_media[0].id;
      } else {
        this.media_id = media
      }
      this.getData();
    },
    setGradePercent() {
      this.grade_2 = [this.grade_1, this.grade_2[1]];
      this.grade_2_percent = this.grade_2[1]-this.grade_2[0];
      if(this.grade_2_percent <= 0) {
        this.grade_2_percent = 0;
        this.grade_2[1] = this.grade_2[0];
      }
      this.grade_3 = [this.grade_2[1], this.grade_3[1]];
      this.grade_3_percent = this.grade_3[1]-this.grade_3[0];
      if(this.grade_3_percent <= 0) {
        this.grade_3_percent = 0;
        this.grade_3[1] = this.grade_3[0];
      }
      this.grade_4 = [this.grade_3[1], 100];
      this.grade_4_percent = this.grade_4[1]-this.grade_4[0];
      if(this.grade_4_percent <= 0) {
        this.grade_4_percent = 0;
        this.grade_4[0] = this.grade_4[1];
      }
    },
    getData() {
      let header = '';
      this.loading = true
      if (this.media_id === 1) {
        header = 'bloodpressure';
      } else if (this.media_id === 2) {
        header = 'golfzip';
      } else if (this.media_id === 3) {
        header = 'ticketoffice';
      } else if (this.media_id === 4) {
        header = 'tingstar';
      } else if (this.media_id === 5) {
        header = 'sivillage';
      }
      this.axios.get('api/v1/exposure/' + this.media_id, {
        headers: {
          'c9': header
        }
      })
          .then(res => {
            let data_arr = res.data.data;
            // 데이터가 없는 게 아니면 api에서 값 가져와서 넣기
            if (typeof data_arr.id !== "undefined") {
              this.recommend_weight = data_arr.recommend_weight;
              this.normal_weight = data_arr.normal_weight;
            }else{
              this.recommend_weight = 50;
              this.normal_weight = 50;
            }
          })
          .catch(err => {
            this.loading = false;
            console.error(err);
          });
      this.loading = false;
    },
    getExposureGrade() {
      this.axios.get('api/v1/exposure/grade/'+this.media_id).then(res => {
        this.reference_day = res.data.data.reference_day;
        this.reference_day_term = res.data.data.term;
        this.grade_1 = res.data.data.config[0]['weight'];
        this.grade_2 = [this.grade_1, this.grade_1 + res.data.data.config[1]['weight']];
        this.grade_2_percent = this.grade_2[1] - this.grade_2[0];
        this.grade_3 = [this.grade_2[1], this.grade_2[1] + res.data.data.config[2]['weight']];
        this.grade_3_percent = this.grade_3[1] - this.grade_3[0];
        this.grade_4 = [this.grade_3[1], this.grade_3[1] + res.data.data.config[3]['weight']];
        this.grade_4_percent = this.grade_4[1] - this.grade_4[0];

        this.setGradePercent();
        res;
      }).catch(err => {
        console.error(err);
      });
    },
    save() {
      const data = {
        media_id: this.media_id,
        recommend_weight: this.recommend_weight,
        normal_weight: this.normal_weight,
      };
      this.axios.post('api/v1/exposure', data).then(res => {
        //console.log(res);
        res;
        this.medias(this.media_id);
      }).catch(err => {
        console.error(err);
      });
    },
    saveExposureGrade() {
      let grade = [
        {grade:1, weight:this.grade_1},
        {grade:2, weight:this.grade_2[1]-this.grade_2[0]},
        {grade:3, weight:this.grade_3[1]-this.grade_3[0]},
        {grade:4, weight:this.grade_4[1]-this.grade_4[0]}
      ];

      const data = {
        reference_day: this.reference_day,
        term: this.reference_day_term,
        config: grade,
      };
      this.axios.put('api/v1/exposure/grade/'+this.media_id, data).then(res => {
        //console.log(res);
        this.$Swal.fire('저장 되었습니다.', '', 'info')
        res;
      }).catch(err => {
        console.error(err);
      });
    },
  }
}
</script>

<style scoped>
</style>