<template>
    <v-container>
        <Breadcrumbs :breadcrumbs="breadcrumbs"/>
        <div class="font-weight-bold mt-0"><span class="pt-0" style="color: gray;font-size: 12px;">게시된 컨텐츠가 보여지는 메뉴입니다. 비활성 버튼을 클릭 시, 선택한 컨텐츠에 대한 게시가 취소 되고 수집정보 메뉴로 해당 컨텐츠가 이동됩니다.</span></div>
        <TopSearchMenu
             @setMedia="setMedia"
             @setPlatform="setPlatform"
             @setType="setType"
             @setDateSearch="setDateSearch"
             @setSearch="setSearch"
             @setSort="setSort"
        />
        <v-divider class="indigo mt-2 mb-2"></v-divider>
        <v-row class="pt-3">
          <v-col cols="12" sm="6" md="8" lg="9" class="pb-0">
            <p class="mb-0">
              <v-chip
                  class="ma-1 white--text"
                  color="green"
              >
                전체 {{ this.numberComma(this.totalCount) }}개
              </v-chip>
              <v-chip
                  class="ma-1"
                  color="rgb(151, 154, 154)"
                  text-color="white"
              >
                검색 결과 {{ this.numberComma(this.searchCount) }}개
              </v-chip>
              <v-chip
                  v-if="currentMedia.auto_post === 0"
                  class="ma-1"
                  color="orange"
                  text-color="white"
              >
                오늘 활성 {{ this.numberComma(this.todayActive) }}개
              </v-chip>
              <v-chip
                  v-if="currentMedia.auto_post === 0"
                  class="ma-1"
                  color="rgb(151, 154, 154)"
                  text-color="white"
              >
                어제 활성 {{ this.numberComma(this.yesterdayActive) }}개
              </v-chip>
              <v-chip
                  v-if="currentMedia.auto_post === 0"
                  class="ma-1"
                  color="blue"
                  text-color="white"
              >
                오늘 비활성 {{ this.numberComma(this.todayInactive) }}개
              </v-chip>
              <v-chip
                  v-if="currentMedia.auto_post === 0"
                  class="ma-1"
                  color="rgb(151, 154, 154)"
                  text-color="white"
              >
                어제 비활성 {{ this.numberComma(this.yesterdayInactive) }}개
              </v-chip>
            </p>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3"
                 class="justify-end pt-lg-3 pt-md-3"
          >
            <v-btn v-if="!checked_state"
                   class="subtitle-2 float-right ml-2"
                   @click="selectDeleteAll()"
                   color="gray"
            >
              삭제
            </v-btn>

            <v-btn v-else class="subtitle-2 white--text float-right ml-2"
                   @click="selectDeleteAll()"
                   color="red"
            >
              삭제
            </v-btn>
<!--            <v-btn v-if="!checked_state"-->
<!--                   class="subtitle-2 float-right"-->
<!--                   @click="changeAllState()"-->
<!--                   color="gray"-->
<!--            >-->
<!--              비활성-->
<!--            </v-btn>-->
<!--            <v-btn v-else class="subtitle-2 white&#45;&#45;text float-right"-->
<!--                   @click="changeAllState()"-->
<!--                   color="rgb(218 33 36)"-->
<!--            >-->
<!--              비활성-->
<!--            </v-btn>-->
            <p style="display:inline-block;" class="float-right mb-0">
                <v-checkbox
                    class="mt-1 mr-2"
                    label="전체 선택"
                    color="rgb(218 33 36)"
                    v-model="selected"
                    @click="selectAll()"
                />
            </p>
          </v-col>
        </v-row>
        <v-row class="pt-1">
          <v-col cols="12" sm="12" md="12" lg="12" class="pb-0">
              <span style="margin-top: 8px !important;">
                <span class="subtitle-2 mr-2">해당 기간 동안 수집한 한 개수(%)</span>
                <span v-for="item in this.platform_date_items" :key="item.platform" class="subtitle-2 mr-2">
                    <v-icon small :color="item.color">{{ item.icon }}</v-icon> : {{ item.count }} <span>({{ item.rate }})</span>
                </span>
              </span>
          </v-col>
        </v-row>
        <v-row v-if="loading" class="justify-center"
        :loading="loading"
        >
          <spinner :loading="loading"></spinner>
        </v-row>
        <v-row v-else class="flexDiv">
          <v-col v-if="this.items.length === 0"
                 justify="center" align="center"
          >
            <span class="subtitle-1">데이터가 존재하지 않습니다.</span>
          </v-col>
          <v-col v-else
               cols="6" sm="auto" md="auto" lg="auto"
               v-for="(item, i) in items" :key="i"
               style="flex-grow: unset;"
          >
            <v-card
                class="mx-auto card-width"
            >
              <v-card-actions style="background-color: #22bb33;">
              </v-card-actions>
              <v-card-actions style="background-color: white;">
                <v-btn class="subtitle-2"
                       style="color: #ffffff;"
                       @click="reportDelete(item)"
                       block
                       color="#CC3300"
                >
                  삭제하기
                </v-btn>
              </v-card-actions>
              <v-img
                  v-if="item.storage_thumbnail_url"
                  :src="item.storage_thumbnail_url"
                  @click="createModal(item)"
                  class="row-pointer"
                  height="150px"
                  style="margin: 0; padding: 0;"
              >
                <v-icon
                    style="background-color: #000000;"
                    mid
                    dark
                    left
                    color="#ffffff"
                >
                  {{ item.icon }}
                </v-icon>
                <v-card-title class="subtitle-2 pa-0"
                              style="margin-top: 82px;height:44px;background-color: black; color: white;"
                              v-if="item.title && item.title.length >= 35"
                >
                  {{ item.title.substr(0, 35) + '...' }}
                </v-card-title>
                <v-card-title class="subtitle-2 pa-0"
                              style="margin-top: 82px;height:44px;background-color: black; color: white;"
                              v-else-if="item.title && item.title.length < 35"
                >
                  {{ item.title }}
                </v-card-title>
              </v-img>
              <ArticlePopup
                  v-if="modelId === item.id && showModal"
                  :item="item"
                  :mediaId="mediaId"
                  v-on:close="closeModal"
                  v-on:refresh="getData"
                  v-on:moreView="handleClick(item)"
                  v-on:deleteArticle="handleDeleteClick(item)"
              ></ArticlePopup>
              <v-snackbar
                  v-model="snackbar"
                  :timeout="timeout"
                  color="black"
                  elevation="0"
              >
                {{ text }}

                <template v-slot:action="{ attrs }">
                  <v-btn
                      color="pink"
                      text
                      v-bind="attrs"
                      @click="snackbar = false"
                  >
                    Close
                  </v-btn>
                </template>
              </v-snackbar>
              <v-card-subtitle class="pb-0" style="margin-bottom: 8px;padding: 8px 0 0 8px;height: 48px !important;">
<!--                <p class="ma-0" v-if="item.tag_activity">활성강도 : {{ item.tag_activity }}%</p>-->
                <span v-if="item.business_tag && item.business_tag.length >= 14">{{ item.business_tag.substr(0, 14) + '...' }}</span>
                <span v-else-if="item.business_tag && item.business_tag.length < 14">{{ item.business_tag }}</span>
                <span v-else>#태그없음</span>
              </v-card-subtitle>
              <v-divider class="gray" ></v-divider>

              <v-card-text class="text--primary">
                <div class="row">
                  <v-checkbox style="flex: auto;margin: -4px 0 0 3px;"
                              v-model="checked"
                              :key="item.id"
                              :value="item.id"
                              color="rgb(218 33 36)"
                              @click="checkState()"
                              hide-details
                  ></v-checkbox>
                  <span class="justify-end mr-1">{{ item.sort_text }} : {{ item.date }}</span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-divider class="indigo mt-8 mb-2" ></v-divider>
        <v-row class="pt-3">
          <v-col cols="12" sm="6" md="8" lg="9" class="pb-0">
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3"
                 class="justify-end pt-lg-3 pt-md-3"
          >
            <v-btn v-if="!checked_state"
                   class="subtitle-2 float-right ml-2"
                   @click="selectDeleteAll()"
                   color="gray"
            >
              삭제
            </v-btn>

            <v-btn v-else class="subtitle-2 white--text float-right ml-2"
                   @click="selectDeleteAll()"
                   color="red"
            >
              삭제
            </v-btn>
          </v-col>
        </v-row>

      <v-pagination
          v-if="!loading && this.searchCount > per_page"
          class="mt-10 mb-5"
          v-model="page"
          :length="last_page"
          circle
          color="#1550B2"
          prev-icon="mdi-chevron-left"
          next-icon="mdi-chevron-right"
          :total-visible="20"
          @input="getData()"
          @next="goToNextPage()"
          @previous="goToPrevPage()"
      ></v-pagination>
    </v-container>
</template>

<script>
import Breadcrumbs from "../../components/Breadcrumbs";
import TopSearchMenu from "../../components/TopSearchMenu";
import Spinner from "../../components/Spinner";
import {mapGetters} from 'vuex';
import ArticlePopup from "../../components/ArticlePopup";
import dayjs from "dayjs";
import numeral from "numeral";
//import dayjs from 'dayjs';

export default {
    components: {
        Breadcrumbs,
        TopSearchMenu,
        ArticlePopup,
        Spinner
    },
    data() {
        return {
            loading: false,
            modelId: '',
            showModal: false,
            breadcrumbs: [
                {
                    text: '게시정보',
                    disabled: false,
                    href: '/article',
                },
            ],
            mediaId: null,
            medias: [],
            currentMedia: [],
            page: 1,
            per_page: 32,
            last_page: 1,
            platform: [],
            type: [],
            search: '',
            sort: '',
            state: 1,
            dateRanges: [],
            datepicker: null,
            end_datepicker: null,
            items: [],
            selected: false,
            checked: [],
            checked_state: false,
            platform_date_items: [],
            icon: '',
            snackbar: false,
            text: '완료',
            timeout: 3000,
            searchCount: '', // 검색했을 때 값
            totalCount: '', // total 값
            todayScraped: '', // 오늘 수집수
            todayActive: '',  // 오늘 활성수
            todayInactive: '', // 오늘 비활성수
            yesterdayActive: '', // 어제 활성수
            yesterdayInactive: '', // 어제 비활성수
            yesterdayScraped: '', // 어제 수집수
            active: '', // 총 활성 수
            inactive: '', // 총 비활성 수
            report: '', // 총 신고수
            reportBlock: '', // 총 삭제 수
            total: '',
            today : dayjs().format("YYYY-MM-DD"),
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
        }),
        getPage() {
          return this.$store.getters.getPage;
        },
        getMediaId() {
          return this.$store.getters.getMediaId;
        },
        getMedias() {
          return this.$store.getters.getMedias;
        },
        getPlatform() {
          return this.$store.getters.getPlatform;
        },
        getType() {
          return this.$store.getters.getType;
        },
        getSearch() {
          return this.$store.getters.getSearch;
        },
        getDateRanges() {
          return this.$store.getters.getDateRanges;
        },
        getSort() {
          return this.$store.getters.getSort;
        },
    },
    watch: {
        checked(v) {
            let result = true;

            v.forEach((selected) => {
                if (!selected) {
                    result = false;
                }
            })
            this.selected = result;
        },
        page() {
          this.checked_state = false;
          this.$store.commit("SET_PAGE", this.page);
          /*
          this.getData();
          this.getCount();
           */
        },
        dateRanges(){
          this.checked_state = false;
          if(!this.dateRanges[0] && this.dateRanges[1]){
            this.$store.commit("SET_DATERANGES", []);
          }
          if (this.dateRanges[0] > this.dateRanges[1]) {
            this.dateRanges.reverse();
          }
          if(this.getDateRanges[0] !== this.dateRanges[0] || this.getDateRanges[1] !== this.dateRanges[1]) {
            if(!this.dateRanges[0])  this.dateRanges[0] = this.getDateRanges[0];
            if(!this.dateRanges[1])  this.dateRanges[1] = this.getDateRanges[1];
          }
        },

        platform(){
          this.checked_state = false;
          this.changeDatas();
        },
        type(){
          this.checked_state = false;
          this.changeDatas();
        },
        search(){
          this.checked_state = false;
          this.changeDatas();
        },
        sort(){
          this.checked_state = false;
          this.changeDatas();
        }
    },
    mounted() {
        if(this.getMediaId && this.getMediaId !== this.mediaId) this.mediaId = this.getMediaId;
        if(this.getPage !== 1) this.page = this.getPage;
        if(this.getPage === 1 && this.page !== this.getPage) this.page = 1;
        if(this.getDateRanges && this.getDateRanges !== this.dateRanges) this.dateRanges = this.getDateRanges;
        if(this.getSearch && this.getSearch !== this.search) this.search = this.getSearch;
        if(this.getPlatform && this.getPlatform !== this.platform) this.platform = this.getPlatform;
        if(this.getType && this.getType !== this.type) this.type = this.getType;
        if(this.getSort && this.getSort !== this.sort) this.sort = this.getSort;

        if(!this.dateRanges[0] && this.dateRanges[1]){
          this.$store.commit("SET_DATERANGES", []);
        }
    },
    methods: {
        setMedia(value, is_change) {
          this.mediaId = parseInt(value);
          if(is_change) this.page = 1;
          this.changeDatas();

          // 현재 선택된 매체 정보
          if(this.getMedias) this.medias = this.getMedias;
          this.currentMedia = this.medias.filter(item => item.id === this.mediaId)[0]
        },
        setPlatform(value, is_change) {
          this.platform = value;
          if(is_change) this.page = 1;
        },
        setType(value, is_change) {
          this.type = value;
          if(is_change) this.page = 1;
        },
        setDateSearch(value, is_change) {
          this.dateRanges = value;
          if(is_change) this.page = 1;
          if (this.dateRanges[0] && this.dateRanges[1] || !this.dateRanges[0] && !this.dateRanges[1]) {
            this.changeDatas();
          } else {
            if(this.dateRanges[0]){
              this.dateRanges[1] = this.today;
              this.changeDatas();
            }else{
              this.$Swal.fire({
                icon: 'error',
                text: '시작 날짜를 선택해주세요.',
              }).then((result) => {
                if (result.isConfirmed) {
                  this.$store.commit("SET_DATERANGES", []);
                  location.reload();
                }
              }).catch(err => {
                console.error(err);
                location.reload();
              });
            }
          }
        },
        reportDelete(value) {
          let mediaIdKind = this.$root.getMediaEngNameById(this.mediaId);
          this.$Swal.fire({
            html: '<h1><b>삭제</b></h1> <h2>하시겠습니까?</h2>',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: '확인',
            denyButtonText: `취소`,
            confirmButtonColor: '#1550B2'
          }).then((result) => {
            if (result.isConfirmed) {
              this.showModal = false;
              this.axios.delete('api/v1/articles/' + value.id + '/delete', {
                headers: {
                  'c9': mediaIdKind
                }
              }).then(res => {
                // this.snackbar = true;
                location.reload();
                res;
              }).catch(err => {
                console.error(err);
              });
            }
          });
        },
        setSearch(value, is_change) {
          this.search = value;
          if(is_change) this.page = 1;
          if(this.getSearch !== this.search) this.search = this.getSearch;
        },
        setSort(value, is_change) {
          this.sort = value;
          if(is_change) this.page = 1;
        },
        checkState() {
          const articleIds = this.checked.filter(chk => chk !== false);
          if (articleIds.length < 1) {
            this.checked_state = false;
          }else{
            this.checked_state = true;
          }
        },
        changeAllState() {
          const articleIds = this.checked.filter(chk => chk !== false);

          if (articleIds.length < 1) {
              this.$Swal.fire({
                  icon: 'error',
                  text: '비활성할 게시물을 선택해주세요.',
              }).then((result) => {
                  if (result.isConfirmed) {
                      this.state = 1;
                  }
              }).catch(err => {
                  console.error(err);
              });
          } else {
              this.$Swal.fire({
                  html: '<h2>비활성 하시겠습니까?</h2>',
                  showDenyButton: true,
                  showCancelButton: false,
                  confirmButtonText: '확인',
                  denyButtonText: `취소`,
              }).then((result) => {
                if (result.isConfirmed) {
                    let header = {};
                    let mediaIdKind = this.$root.getMediaEngNameById(this.mediaId);

                    if(mediaIdKind !== ''){
                        header = {
                            headers: {
                              'c9': mediaIdKind
                            }
                        }
                    }
                    const data = {
                        article_ids: articleIds,
                        media_id: this.mediaId,
                        state: 0 // 1일 때 : 활성화, 0일 때 : 비활성화
                    }
                    this.loading = true;
                    this.axios.put('api/v1/articles', data, header).then(res => {
                        this.snackbar = true;
                        this.getData();
                        this.getCount();
                        // console.log(res);
                      this.loading = false;
                      res;
                    }).catch(err => {
                        console.error(err);
                    });
                  } else if (result.isDenied) {
                      this.checked_state = false;
                      this.getData();
                      this.getCount();
                }
              })
          }
        },
        selectDeleteAll() {
          const articleIds = this.checked.filter(chk => chk !== false);
          if (articleIds.length < 1) {
            this.$Swal.fire({
              icon: 'error',
              text: '삭제할 게시물을 선택해주세요.',
            }).then((result) => {
              if (result.isConfirmed) {
                this.state = 0;
              }
            }).catch(err => {
              console.error(err);
            });
          } else {
            this.$Swal.fire({
              html: '<h1><b>삭제</b></h1> <h2>하시겠습니까?</h2>',
              showDenyButton: true,
              showCancelButton: false,
              confirmButtonText: '확인',
              denyButtonText: `취소`,
              confirmButtonColor: '#1550B2'
            }).then((result) => {
              if (result.isConfirmed) {
                let mediaIdKind = this.$root.getMediaEngNameById(this.mediaId);
                this.axios.delete('api/v1/articles/delete', {
                  params: {
                    article_ids: articleIds
                  },
                  headers: {
                    'c9': mediaIdKind
                  }
                }).then(res => {
                  this.checked_state = false;
                  this.changeDatas();
                  res;
                }).catch(err => {
                  console.error(err);
                });
              } else if (result.isDenied) {
                this.checked_state = false;
                this.changeDatas();
              }
            })
          }
        },
        clickState(id) {
            let header = {};
            let mediaIdKind = this.$root.getMediaEngNameById(this.mediaId);
            if(mediaIdKind !== ''){
              header = {
                headers: {
                  'c9': mediaIdKind
                }
              }
            }
            const data = {
                state: 0
            };
            this.axios.put('api/v1/articles/' + id + '/state', data, header).then(res => {
                this.snackbar = true;
                this.changeDatas();
                //console.log(res);
                res;
            }).catch(err => {
                console.error(err);
            });
        },
        handleClick(value) {
          let header = this.$root.getMediaEngNameById(this.mediaId);
          let url = `&page=${this.page}&per_page=${this.per_page}&media_id=${this.mediaId}&state=1&platform=${this.platform}&type=${this.type}&search=${this.search}&start_date=${this.dateRanges[0] ?? ''}&end_date=${this.dateRanges[1] ?? ''}`;
          this.$router.push('/'+value.id+'?c9='+header+url);
        },
        handleDeleteClick(value) {
          let mediaIdKind = this.$root.getMediaEngNameById(this.mediaId);
          this.$Swal.fire({
            html: '<h1><b>삭제</b></h1> <h2>하시겠습니까?</h2>',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: '확인',
            denyButtonText: `취소`,
            confirmButtonColor: '#1550B2'
          }).then((result) => {
            if (result.isConfirmed) {
              this.showModal = false;
              this.axios.delete('api/v1/articles/' + value.id + '/delete', {
                headers: {
                  'c9': mediaIdKind
                }
              }).then(res => {
                this.snackbar = true;
                this.changeDatas();
                res;
              }).catch(err => {
                console.error(err);
              });
            }
          });
        },
        goToNextPage() {
          this.page = this.page + 20;
        },
        goToPrevPage() {
          this.page = this.page - 20;
          if (this.page < 0) this.page = 1
          console.log(this.page)
        },
        getData() {
            let result = [];
            let img = '';
            this.loading = true;
            let header = this.$root.getMediaEngNameById(this.mediaId);
            let url = this.$root.getStorageUrlByMediaName(header);

            // 멀티 플랫폼일 때
            let keyPlatform = '';
            let searchPlatform = '';
            for(keyPlatform in this.platform){
                searchPlatform += this.platform[keyPlatform];
            }

            // 멀티 타입일 때
            let keyType = '';
            let searchType = '';
            for(keyType in this.type){
                searchType += this.type[keyType];
            }

            this.axios.get('api/v1/admin/post-articles', {
                params: {
                    'page': this.page,
                    'per_page': this.per_page,
                    'sort': this.sort ? this.sort : '',
                    'media_id': this.mediaId,
                    'type': searchType ? searchType : '',
                    'search': this.search ? '#' + this.search : '',
                    'platform': searchPlatform ? searchPlatform : '',
                    'start_date': this.dateRanges ? this.dateRanges[0] : '',
                    'end_date': this.dateRanges ? this.dateRanges[1] : '',
                },
                headers: {
                    'c9': header
                }
            })
                .then(res => {
                    if (res.data.data.articles.length > 0) {
                        res.data.data.articles.map((item, index) => {
                            if (item.storage_thumbnail_url) {
                                img = url + item.storage_thumbnail_url;
                            } else {
                                if (item.article_medias[0]) {
                                    img = url + item.article_medias[0].storage_url;
                                } else {
                                    img = './images/no-image.png';
                                }
                            }

                            let contents = '';
                            if (item.contents.length > 0) {
                              contents = item.contents;
                            } else {
                              contents = '내용 없음';
                            }

                            // 인스타그램 이미지 여러 개일 때
                            if(item.platform === 'instagram' && item.article_medias.length > 0 && item.article_medias[0].storage_url){
                              item.article_medias = item.article_medias.map((i) => {
                                return {
                                  storage_url: i.storage_url ? url + i.storage_url : '',
                                  not_full_storage_url: i.storage_url ? i.storage_url : '',
                                  width: i.width,
                                  height: i.height,
                                }
                              })
                            }else{ // 인스타그램 미디어가 1개인데 비디오 타입 이거나(storage_url이 없을 때) 없거나, 나머지 플랫폼은 썸네일로 띄우기
                              item.article_medias = [];
                              item.article_medias.push({
                                storage_url: img,
                                width: item.thumbnail_width,
                                height: item.thumbnail_height,
                                is_thumbnail: true,  // 썸네일로 대체했는지 여부 (ArticlePopup에서 필요)
                              });
                            }
                            let icon = this.$root.getPlatformIconByName(item.platform);
                            let date = item.date.substr(0, 10);
                            let diff_date = item.created_at.substr(0, 10);
                            let sort_text = '작성일';
                            let diff_sort_text = '수집일';
                            if(this.sort === 'sort_id') {
                              date = item.created_at.substr(0, 10);
                              diff_date = item.date.substr(0, 10);
                              sort_text = '수집일';
                              diff_sort_text = '작성일';
                            }
                            result.push({
                                no: index + 1,
                                id: item.id,
                                platform: item.platform,
                                type: item.type,
                                keyword: item.keyword,
                                channel: item.channel,
                                url: item.url,
                                date: date,
                                sort_text: sort_text,
                                diff_date: diff_date,
                                diff_sort_text: diff_sort_text,
                                state: item.state,
                                storage_thumbnail_url: img,
                                icon: icon,
                                business_tag: item.business_tag ? '#' + item.business_tag : '',
                                tag_activity: item.tag_activity,
                                title: item.title,
                                article_medias: item.article_medias,
                                thumbnail_width: item.thumbnail_width,
                                thumbnail_height: item.thumbnail_height,
                                contents: contents,
                            })
                        })
                    }
                    this.searchCount = res.data.data.searchCount; // total 또는 검색 했을 때 개수
                    this.totalCount = res.data.data.totalCount; // total 또는 검색 했을 때 개수
                    this.items = result;
                    this.count = this.items.length;
                    if (Number.isInteger(res.data.data.searchCount / this.per_page) == false) {
                        this.last_page = Math.floor(res.data.data.searchCount / this.per_page) + 1;
                    } else {
                        this.last_page = res.data.data.searchCount / this.per_page
                    }
                    for (let i = 0; i < this.count; i++) {
                        this.$set(this.checked, i, false)
                    }
                    let hasAdmin = res.data.data.articles.some(user => user.state === 0);
                    if (hasAdmin === true) {
                        this.state = 0
                    } else {
                        this.state = 1
                    }
                    if (this.items.length === 0) {
                        this.state = 0;
                    }
                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                    console.error(err);
                });
        },
        getCount() {
          // this.loading = true;
          let header = this.$root.getMediaEngNameById(this.mediaId);
          this.axios.get('api/v1/admin/articles/stats', {
            params: {
              'media_id': parseInt(this.mediaId),
            },
            headers: {
              'c9': header
            }
          })
              .then(res => {
                this.todayScraped = res.data.data.todayScraped;
                this.todayActive = res.data.data.todayActive;
                this.todayInactive = res.data.data.todayInactive;
                this.yesterdayActive = res.data.data.yesterdayActive;
                this.yesterdayInactive = res.data.data.yesterdayInactive;
                this.yesterdayScraped = res.data.data.yesterdayScraped;
                this.active = res.data.data.active;
                this.inactive = res.data.data.inactive;
                this.report = res.data.data.report;
                this.reportBlock = res.data.data.reportBlock;
                this.total = res.data.data.total;
                this.loading = false;
              })
              .catch(err => {
                // this.loading = false;
                console.error(err);
              });
        },
        createModal : function(index) {
          this.modelId = index.id;
          this.showModal = true;
        },
        closeModal : function() {
          this.modelId = '';
          this.showModal = false;
        },
        selectAll() {
          if (this.selected) {
            this.checked = this.items.map(v => v.id);
            this.checked_state = true;
          } else {
            this.checked = [];
            this.checked_state = false;
          }
        },
      changeDatas() {
        if(!this.loading) {
          this.getData();
          this.getCount();
          this.getPlatformDateCount();
        }
      },
      getPlatformDateCount(){ // 기간에 따른 플랫폼별 활성 개수(비율)
        let header = this.$root.getMediaEngNameById(this.mediaId);
        let result = [];
        let isset_twitter = false;
        // this.loading = true;
        this.axios.get('api/v1/admin/articles/platform-date-stats', {
          params: {
            'media_id': parseInt(this.mediaId),
            'start_date': this.dateRanges ? this.dateRanges[0] : '',
            'end_date': this.dateRanges ? this.dateRanges[1] : '',
            'sort': this.sort,
            'type': 'posted'
          },
          headers: {
            'c9': header
          }
        })
            .then(res => {
              let total = res.data.data.total ? res.data.data.total : 0;
              res.data.data.data.map((item) => {
                let icon = this.$root.getPlatformIconByName(item.platform);
                let color = this.$root.getPlatformColorByName(item.platform);
                if(item.platform === 'twitter') isset_twitter = true;
                let rate = Math.round((100 / parseInt(total+'') * parseInt((item.count+'').replace(',',''))) * 100) / 100;
                if(isNaN(rate)) rate = 0;
                result.push({
                  platform: item.platform,
                  platform_kr: item.platform_kr,
                  count: item.count+"개",
                  rate: rate+"%",
                  icon: icon,
                  color: color,
                })
              })
              if(!isset_twitter){
                result.push({
                  platform: 'twitter',
                  platform_kr: '트위터',
                  count: 0 + "개",
                  rate: 0 + "%",
                  icon: 'mdi-twitter',
                  color: 'blue',
                })
              }
              this.platform_date_items = result;
              // this.loading = false;
            })
            .catch(err => {
              console.error(err);
              // this.loading = false;
              //  location.reload();
            });
      },
      numberComma(value) {
        return numeral(value).format('0,0');
      },
    }
}
</script>
