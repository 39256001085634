import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login'
import Home from "../views/Home";
import Platform from "../views/platform/Platform";
import PlatformShow from "../views/platform/PlatformShow";
import ArticleShow from "../views/article/ArticleShow";
import ArticleList from "../views/article/ArticleList";
import ArticleReportList from "../views/article/ArticleReportList";
import UserList from "../views/user/UserList";
import UserSearchList from "../views/statistics/UserSearchList";
import Topic from "../views/target/Topic";
import Keyword from "../views/target/Keyword";
import BaseKeyword from "../views/target/BaseKeyword";
import Channel from "../views/target/Channel";
import Tag from "../views/target/Tag";
import ExposureWeight from "../views/weight/ExposureWeight";
import Statistics from "../views/statistics/StatisticsList";
import UserStatisticsList from "../views/statistics/UserStatisticsList";
import UserRankStatisticsList from "../views/statistics/UserRankStatisticsList";
import ContentStatisticsList from "../views/statistics/ContentStatisticsList";
import TagStatisticsList from "../views/statistics/TagStatisticsList";
import TagStatisticsListAggs from "../views/statistics/TagStatisticsListAggs";
import TagStatisticsListIgnores from "../views/statistics/TagStatisticsListIgnores";
import UserLogsList from "@/views/statistics/UserLogsList";

Vue.use(VueRouter)

const routes = [
	{ path: '/', redirect: { name: 'Home' }},
	{
		path: '/login',
		name: 'Login',
		component: Login,
		beforeEnter: (to, from, next) => {
			if (localStorage.getItem('token')) {
				return next({
					name: '/'
				})
			}
			next()
		}
	},
	{
		path: '/statistics',
		name: 'Statistics',
		component: Statistics,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/statistic-users',
		name: 'UserStatistics',
		component: UserStatisticsList,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/statistic-content-ranks',
		name: 'ContentStatistics',
		component: ContentStatisticsList,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/statistic-tag-ranks',
		name: 'TagStatistics',
		component: TagStatisticsList,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/statistic-tag-aggs',
		name: 'TagStatisticsAggs',
		component: TagStatisticsListAggs,
		meta: {
			requiresAuth: true,
		},
	},

	{
		path: '/base-keywords',
		name: 'BaseKeyword',
		component: BaseKeyword,
		meta: {
			requiresAuth: true,
		},
	},


	{
		path: '/statistic-tag-ignores',
		name: 'TagStatisticsIgnores',
		component: TagStatisticsListIgnores,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/statistic-user-ranks',
		name: 'UserRankStatisticsList',
		component: UserRankStatisticsList,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/statistic-user-search',
		name: 'UserSearchList',
		component: UserSearchList,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/statistic-user-logs',
		name: 'UserLogsList',
		component: UserLogsList,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/collect',
		name: 'Home',
		component: Home,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/article',
		name: 'ArticleList',
		component: ArticleList,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/article-report',
		name: 'ArticleReportList',
		component: ArticleReportList,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/platforms',
		name: 'Platform',
		component: Platform,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/topics',
		name: 'Topic',
		component: Topic,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/keywords',
		name: 'Keyword',
		component: Keyword,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/channels',
		name: 'Channel',
		component: Channel,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/tags',
		name: 'Tag',
		component: Tag,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/platform/:id',
		name: 'PlatformShow',
		component: PlatformShow,
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: '/exposure',
		name: 'Exposure',
		component: ExposureWeight,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/users',
		name: 'UserList',
		component: UserList,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/:id',
		name: 'ArticleShow',
		component: ArticleShow,
		meta: {
			requiresAuth: true,
		},
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	if (to.matched.some(route => route.meta.requiresAuth)) {
		if (!localStorage.getItem('token')) {
			return next({
				name: 'Login'
			})
		} else {
			next();
		}
	}
	next();
});

export default router
