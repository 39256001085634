<template>
  <v-container>
    <v-row class="row mt-0 mb-0">
      <v-col class="ma-0 row pb-0 pl-0 pt-0" cols="12" sm="6" md="4" lg="4">
        <v-select style="max-width: 150px;"
                  class="mr-2"
                  :items="items"
                  v-model="media"
                  label="매체"
                  item-text="name"
                  item-value="id"
                  @change="medias"
                  hide-details
        ></v-select>
      </v-col>
      <v-col class=" justify-end ma-0 row pb-0" cols="12" sm="6" md="8" lg="8" style="flex-wrap: nowrap;">
        <v-btn-toggle
            v-model="dateChoice"
            class="mt-3 mr-3"
            color="deep-indigo accent-3"
        >
          <v-btn class="mt-3"
                 value="reset"
                 small
          >
            <span class="hidden-sm-and-down">전체</span>
          </v-btn>
          <v-btn class="mt-3"
                 value="today"
                 v-bind:class="[date_choice_reset_active ? 'v-item--active v-btn--active' : '']"
                 small
          >
            <span class="hidden-sm-and-down">오늘</span>
          </v-btn>
          <v-btn class="mt-3"
                 value="yesterday"
                 small
          >
            <span class="hidden-sm-and-down">어제</span>
          </v-btn>
          <v-btn class="mt-3"
                 value="week"
                 small
          >
            <span class="hidden-sm-and-down">일주일</span>
          </v-btn>
        </v-btn-toggle>
        <v-menu
            v-model="datepicker"
            :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <v-text-field class="row-pointer mr-2"
                          style="max-width: 110px;"
                          label="시작일"
                          append-icon="mdi-calendar"
                          v-on="on"
                          offset-y
                          :value="dateRanges[0]"
                          readonly
                          hide-details
            >
            </v-text-field>
          </template>
          <v-date-picker
              v-model="dateRanges[0]"
              no-title
              locale="ko-kr"
              changeMonth="true"
              :max="today"
              @change="dateSearchChange"
          >
            <v-spacer></v-spacer>
            <v-btn
                   text
                   color="primary"
                   @click="resetDate"
                   hide-details
            >
              날짜 모두 초기화
            </v-btn>
          </v-date-picker>
        </v-menu>
        <p style="margin-top: 20px !important;">~</p>
        <v-menu
            v-model="end_datepicker"
            :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <v-text-field class="row-pointer ml-2 mr-2"
                          style="max-width: 110px;"
                          label="종료일"
                          append-icon="mdi-calendar"
                          v-on="on"
                          offset-y
                          :value="dateRanges[1]"
                          readonly
                          hide-details
            >
            </v-text-field>
          </template>
          <v-date-picker
              v-model="dateRanges[1]"
              no-title
              locale="ko-kr"
              changeMonth="true"
              :max="today"
              @change="dateSearchChange"
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="resetDate"
                hide-details
            >
              날짜 모두 초기화
            </v-btn>
          </v-date-picker>
        </v-menu>
        <v-text-field
            label="검색"
            style="max-width: 220px;"
            v-model="search"
            append-icon="mdi-magnify"
            @keyup.native.enter="searchChange"
            hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="row mt-0 mb-0 pl-0">
      <v-col class="ma-0 row pt-0 pl-0" cols="9" sm="6" md="8" lg="8">
        <v-btn-toggle
            v-model="platform"
            multiple
        >
          <v-btn style="height:48px !important;"
                 @input="selectPlatformAll"
                 @click="checkClickPlatform"
                 outlined
                 filter
                 v-bind:class="all_platform_active"
                 v-bind:disabled="platform_disabled"
          >
            전체
          </v-btn>
          <v-btn
              v-for="platform in platform_items"
              :key="platform.value"
              :value="platform.value"
              @input="selectPlatform"
              @click="checkClickPlatform"
              v-bind:class="[platform_active ? 'v-item--active v-btn--active' : '']"
              outlined
              filter
          >
            <v-icon>{{platform.icon }}</v-icon>
          </v-btn>
        </v-btn-toggle>
        <v-btn-toggle
            class="ml-sm-2"
            v-model="type"
            multiple
        >
          <v-btn style="height:48px !important;"
                 value="all"
                 @input="selectTypeAll"
                 @click="checkClickType"
                 outlined
                 filter
                 v-bind:class="all_type_active"
                 v-bind:disabled="type_disabled"
          >
           전체
          </v-btn>
          <v-btn style="height:48px !important;" class=""
                 v-for="type in type_items"
                 :key="type.value"
                 :value="type.value"
                 @input="selectType"
                 @click="checkClickType"
                 v-bind:class="[type_active ? 'v-item--active v-btn--active' : '']"
                 outlined
                 filter
          >
            {{ type.type }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col class="ma-0 row pa-0 justify-end float-right" cols="3" sm="6" md="4" lg="4">
        <v-select style="max-width: 150px;"
                  class="mr-2"
                  :items="sort_items"
                  v-model="sort"
                  label="정렬방식"
                  item-text="sort"
                  item-value="value"
                  @change="sorting"
                  hide-details
        ></v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import dayjs from "dayjs";

export default {
    data() {
        return {
            items: [],
            sort_items: [
              {sort: '작성일', value: 'id'},
              {sort: '수집일', value: 'sort_id'},
            ],
            loading : false,
            media: null,
            platform_items: [
              {platform: '인스타그램', value: '#instagram', icon: 'mdi-instagram'},
              {platform: '유튜브', value: '#youtube', icon: 'mdi-youtube'},
              {platform: '네이버블로그', value: '#naver-blog', icon: 'mdi-blogger'},
              {platform: '구글뉴스', value: '#google-news', icon: 'mdi-google'},
              {platform: '트위터', value: '#twitter', icon: 'mdi-twitter'},
              {platform: '네이버뉴스', value: '#naver-news', icon: 'mdi-alpha-n-box'},
            ],
            type_items: [
              {type: '키워드', value: '#keyword'},
              {type: '채널', value: '#channel'}
            ],
            platform: [],
            type: [],
            search : '',
            sort: '',
            datepicker: false,
            end_datepicker: false,
            is_sort_change: false,
            is_media_change: false,
            is_type_change: false,
            is_platform_change: false,
            is_date_change: false,
            is_search_change: false,
            dateRanges: [],
            article_state : 0,
            all_platform_selected: true, // 플랫폼 전체 선택 여부
            all_platform_active: 'v-item--active v-btn--active', // 플랫폼 '전체' 버튼 active
            platform_active: true, // 플랫폼 버튼 active
            platform_disabled: true, // 플랫폼 '전체' 버튼 disabled 여부
            all_type_selected: true, // 타입 전체 선택 여부
            all_type_active: 'v-item--active v-btn--active', // 타입 '전체' 버튼 active
            type_active: false, // 타입 버튼 active
            type_disabled: true, // 타입 '전체' 버튼 disabled 여부
            dateChoice: '',
            date_choice_reset_active: true, // 전체 버튼 active
            today : dayjs().format("YYYY-MM-DD"),
            yesterday : dayjs(dayjs().format("YYYY-MM-DD")).subtract(1, 'day').format('YYYY-MM-DD'),
            week : dayjs(dayjs().format("YYYY-MM-DD")).subtract(1, 'week').format('YYYY-MM-DD')
      }
    },
    computed: {
      ...mapGetters({
        user: 'auth/user',
      }),
      getSort() {
        return this.$store.getters.getSort;
      },
      getMediaId() {
        return this.$store.getters.getMediaId;
      },
      getPlatform() {
        return this.$store.getters.getPlatform;
      },
      getType() {
        return this.$store.getters.getType;
      },
      getSearch() {
        return this.$store.getters.getSearch;
      },
      getDateRanges() {
        return this.$store.getters.getDateRanges;
      },
    },
    watch: {
      sort() {
        this.is_sort_change = true;
        this.$store.commit("SET_SORT", this.sort);
      },
      media() {
        this.is_media_change = true;
        this.$store.commit("SET_MEDIA_ID", this.media);
      },platform() {
        this.$store.commit("SET_PLATFORM", this.platform);
      },type() {
        this.$store.commit("SET_TYPE", this.type);
      },search() {
        this.is_search_change = true;
        this.$store.commit("SET_SEARCH", this.search);
      },
      dateRanges(){
        this.is_date_change = true;
        this.dateChoice = '';
        this.date_choice_reset_active = false;
        this.$store.commit("SET_DATERANGES", this.dateRanges);
      },
      dateChoice(){
        if(this.dateChoice === 'today') this.dateRanges[0] = this.today;
        else if(this.dateChoice === 'yesterday') this.dateRanges[0] = this.yesterday;
        else if(this.dateChoice === 'week') this.dateRanges[0] = this.week;
        else if(this.dateChoice === 'reset'){
          this.dateRanges[0] = '';
          this.dateRanges[1] = '';
        }

        if(this.dateRanges[0]) this.dateRanges[1] = this.today;

        this.$store.commit("SET_DATERANGES", this.dateRanges);
        this.dateSearchChange();
      }
    },
    async mounted() {
        await this.getMedia();
        this.media = this.getMediaId;
        this.sort = this.getSort;
        this.platform = this.getPlatform;
        this.type = this.getType;
        this.search = this.getSearch;
        this.dateRanges = this.getDateRanges;

        const media_id = this.media;
        if(media_id) {
            this.media = parseInt(media_id);
        } else {
            this.media = this.user.user_media[0].id;
        }
        this.medias();
        // this.platform 길이가 0일 때 미리 모든 플랫폼 값 넣어 놓기
        if(this.platform.length === 0){
          this.platform_items.forEach(
              element => {
                this.platform.push(element.value)
              }
          )
        }
        // this.type 길이가 0일 때 미리 모든 타입 값 넣어 놓기
        if(this.type.length === 0){
          this.type_items.forEach(
              element => {
                this.type.push(element.value)
              }
          )
        }
    },
    methods: {
        getMedia() {
            this.loading = true;
            return this.axios.get('api/v1/medias')
                .then(res => {
                    this.items = res.data.data;
                    this.$store.state.medias = res.data.data;
                    this.loading = false;
                })
                .catch(err => {
                    console.error(err);
                });
        },
        sorting() {
            this.$emit('setSort', this.sort, this.is_sort_change)
        },
        medias() {
            this.$emit('setMedia', this.media, this.is_media_change)
        },
        platformChange() {
          this.$emit('setPlatform', this.platform, this.is_platform_change);
        },
        typeChange() {
          this.$emit('setType', this.type, this.is_type_change);
        },
        dateSearchChange() {
          this.$emit('setDateSearch', this.dateRanges, this.is_date_change);
          this.datepicker = false;
          this.end_datepicker = false;
        },
        searchChange() {
          this.$emit('setSearch', this.search, this.is_search_change);
        },
        // 플랫폼 전체 선택했을 때
        selectPlatformAll: function() {
          this.all_platform_selected = !this.all_platform_selected;

          if(this.all_platform_selected){
            this.platform = [];
            this.platform_active = true;
            this.platform_disabled = true;
            this.all_platform_selected = true;
            let index = this.platform.indexOf('all');
            if (index > -1) {
              this.platform.splice(index, 1);
            }
            this.platform_items.forEach(
                element => {
                  this.platform.push(element.value)
                }
            )
          }
          this.platformChange();
        },
        // 플랫폼 개별 선택했을 때
        selectPlatform: function() {
          this.all_platform_selected = false;
          this.all_platform_active = '';
          this.platform_active = false;
          this.platform_disabled = false;
          let index = this.platform.indexOf('all');
          if (index > -1) {
            this.platform.splice(index, 1);
          }

          // 개별 선택 개수가 플랫폼 전체 개수랑 같아질 때 '전체'버튼 active
          if(this.platform.length >= this.platform_items.length || this.platform.length === 0){
            this.all_platform_selected = true;
            this.all_platform_active = 'v-item--active v-btn--active';
            this.platform_disabled = true;
          }else{
            this.all_platform_selected = false;
            this.all_platform_active = '';
            this.platform_disabled = false;
          }
          this.platformChange();
        },
        // 타입 전체 선택했을 때
        selectTypeAll: function() {
            this.all_type_selected = !this.all_type_selected;

            if(this.all_type_selected){
              this.type = [];
              this.type_active = true;
              this.type_disabled = true;
              this.all_type_selected = true;
              let index = this.type.indexOf('all');
              if (index > -1) {
                this.type.splice(index, 1);
              }
              this.type_items.forEach(
                element => {
                    this.type.push(element.value)
                }
              )
            }
           this.typeChange();
        },
        // 타입 개별 선택했을 때
        selectType: function() {
          this.all_type_selected = false;
          this.all_type_active = '';
          this.type_active = false;
          this.type_disabled = false;
          let index = this.type.indexOf('all');
          if (index > -1) {
            this.type.splice(index, 1);
          }

          // 개별 선택 개수가 플랫폼 전체 개수랑 같아질 때 '전체'버튼 active
          if(this.type.length >= this.type_items.length || this.type.length === 0){
            this.all_type_selected = true;
            this.all_type_active = 'v-item--active v-btn--active';
            this.type_disabled = true;
          }else{
            this.all_type_selected = false;
            this.all_type_active = '';
            this.type_disabled = false;
          }
          this.typeChange();
        },
        resetDate(){
          this.$store.commit("SET_DATERANGES", []);
          this.dateRanges = [];
          this.is_date_change = true;
          this.dateSearchChange();
        },
        checkClickType(){
          this.is_type_change = true;
        },
        checkClickPlatform(){
          this.is_platform_change = true;
        }
    }
}
</script>

<style scoped>
row-pointer :hover {
  cursor: pointer;
}
.myClass:focus::before {
  opacity: 0 !important;
}
</style>
