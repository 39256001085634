<template>
    <v-container>
        <Breadcrumbs :breadcrumbs="breadcrumbs"/>
        <v-row class="row mb-0 mt-1">
          <v-col cols="12" sm="6" md="8">
            <v-row>
              <v-col cols="12" sm="4">
                <v-select
                  :items="mediasItems"
                  label="매체"
                  v-model="media_id"
                  @change="getData"
                ></v-select>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="4">
                <v-menu
                  ref="start_at"
                  v-model="date_calendar.start_at"
                  :close-on-content-click="false"
                  :return-value.sync="date_range.start_at"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_range.start_at"
                      label="집계 구간 시작일"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date_range.start_at" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="date_calendar.start_at = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="changeDateRange('start_at')">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="4">
                <v-menu
                  ref="end_at"
                  v-model="date_calendar.end_at"
                  :close-on-content-click="false"
                  :return-value.sync="date_range.end_at"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_range.end_at"
                      label="집계 구간 종료일"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date_range.end_at" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="date_calendar.end_at = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="changeDateRange('end_at')">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

          </v-col>
          <v-col class="ma-0 justify-space-between">
            <v-text-field class="float-right"
                  label="검색"
                  v-model="search"
                  append-icon="mdi-magnify"
                  @keyup.native.enter="searchChange()"
                  hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider class="indigo mt-2 mb-4"></v-divider>
        <v-row v-if="loading" class="justify-center" :loading="loading">
          <spinner :loading="loading"></spinner>
        </v-row>
        <v-row v-else class="pt-0">
          <v-col v-if="this.searchCount === 0" justify="center" align="center">
            <span class="subtitle-1">데이터가 존재하지 않습니다.</span>
          </v-col>
          <v-col v-else>
            <table class="type10">
              <thead>
              <tr>
                <th class="text-center" style="width : 15% !important;">No.</th>
                <th class="text-center" style="width : 15% !important;">매체</th>
                <th class="text-center" style="width : 30% !important;">태그
                  <v-icon small @click="toggleTagOrder" v-if="this.tag_sort === 'asc'">mdi-arrow-up</v-icon>
                  <v-icon small @click="toggleTagOrder" v-else>mdi-arrow-down</v-icon>
                </th>
                <th class="text-center" style="width : 20% !important;">수집수
                  <v-icon small @click="toggleSumOrder" v-if="this.sum_sort === 'asc'">mdi-arrow-up</v-icon>
                  <v-icon small @click="toggleSumOrder" v-else>mdi-arrow-down</v-icon>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <td class="text-center" style="padding-top: 4px; padding-bottom: 4px;">{{ item.no }}</td>
                <td class="text-center" style="padding-top: 4px; padding-bottom: 4px;">{{ item.media_name }}</td>
                <td class="text-center" style="padding-top: 4px; padding-bottom: 4px;" v-html="addTagHighlight(item.tag)"></td>
                <td class="text-center" style="padding-top: 4px; padding-bottom: 4px;">{{ item.article_count }}</td>
              </tr>
              </tbody>
            </table>
                <v-pagination
                    v-if="this.searchCount > per_page"
                    class="mt-5 mb-5"
                    v-model="page"
                    :length="last_page"
                    circle
                    color="#1550B2"
                    prev-icon="mdi-chevron-left"
                    next-icon="mdi-chevron-right"
                    :total-visible="10"
                    @input="getData()"
                ></v-pagination>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Breadcrumbs from "../../components/Breadcrumbs";
import Spinner from "../../components/Spinner";
import {mapGetters} from "vuex";
import numeral from "numeral";
import dayjs from "dayjs";

export default {
    components: {
        Breadcrumbs,
        Spinner,
    },
    data() {
      return {
          sum_sort: 'desc',
          tag_sort: 'asc',
          loading: false,
          breadcrumbs: [
              {
                  text: '키워드 (PK) 집계',
                  disabled: false,
                  href: '/statistic-tag-aggs',
              },
          ],
          items: [],
          medias: [],
          media_id: null,
          page: 1,
          per_page: 21,
          after_per_page: 15,
          last_page: 1,
          search: '',
          date_calendar: {
            start_at: false,
            end_at: false,
          },
          date_range: {
            start_at: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
            end_at: dayjs().format('YYYY-MM-DD'),
          },
          searchCount: '', // total 또는 검색했을 때 값
          //windowWidth: 0,
          windowHeignt: 0,
        }
    },
    computed: {
      ...mapGetters({
        user: 'auth/user',
      }),
      mediasItems() {
        return [
          ...this.medias.map(m => ({ 'text': m.name, 'value': m.id }))
        ];
      },
      getMediaId() {
        return this.$store.getters.getMediaId;
      },
    },
    watch: {
      media_id() {
        this.$store.commit("SET_MEDIA_ID", this.media_id);
      },
    },
    mounted() {
        this.media_id = this.getMediaId;
        window.addEventListener('resize', this.handleResize);
        window.dispatchEvent(new Event('resize'));

        if (localStorage.getItem('token')) {
            this.user_items = this.user.user_media;
        }

        if (this.$route.query.search) {
          this.search = this.$route.query.search;
        }

        this.getData();
        this.getMedia();
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
        //this.windowWidth = window.innerWidth;
        this.windowHeignt = window.innerHeight;
        /*
        this.after_per_page = Math.floor((this.windowHeignt - 306) / 48);
        console.log(this.after_per_page);
         */
        if(this.windowHeignt >= 2160) {
          this.after_per_page = 39;
        } else if(this.windowHeignt >= 1440 && this.windowHeignt < 2160) {
          this.after_per_page = 23;
        } else if(this.windowHeignt >= 1366 && this.windowHeignt < 1440) {
          this.after_per_page = 21;
        } else if(this.windowHeignt >= 1280 && this.windowHeignt < 1366) {
          this.after_per_page = 19;
        } else if(this.windowHeignt >= 1080 && this.windowHeignt < 1280) {
          this.after_per_page = 15;
        } else if(this.windowHeignt >= 1024 && this.windowHeignt < 1280) {
          this.after_per_page = 14;
        } else if(this.windowHeignt >= 768 && this.windowHeignt < 1024) {
          this.after_per_page = 8;
        } else {
          this.after_per_page = 5;
        }

        if(this.per_page != this.after_per_page) {
          this.resizeCheck();
          this.per_page = this.after_per_page;
          this.getData();
        }

      },
      resizeCheck() {
        if( (this.after_per_page != this.per_page) && this.page > 1) {
            this.page = Math.round(this.page * (this.per_page / this.after_per_page));
        }
      },
      getMedia() {
        this.loading = true;
        return this.axios.get('api/v1/medias')
          .then(res => {
              this.medias = res.data.data;
              this.loading = false;
          })
          .catch(err => {
              console.error(err);
          });
      },
      getData() {
          let result = [];
          let header = '';
          this.loading = true;

          const params = {
            'media_id': this.media_id,
            'page': this.page,
            'per_page': this.per_page,
            'date_range[start_at]': this.date_range.start_at,
            'date_range[end_at]': this.date_range.end_at,
            'search': this.search ? this.search : '',
            'tag_order': this.tag_sort,
          };

          if( this.sum_sort ) params.sum_order = this.sum_sort;

          this.axios.get('api/v1/tags/total', {
              params,
              headers: {
                  'c9': header
              }
          })
          .then(res => {
            this.searchCount = res.data.data.total; // total 또는 검색 했을 때 개수
            if (this.searchCount > 0) {
                  res.data.data.tags.map((item, index) => {
                      let no = this.searchCount - (this.page - 1) * this.per_page - index;
                      if(item.sum === null){
                        item.sum = 0;
                      }
                      result.push({
                          no: this.numberComma(no),
                          media_name: item.name,
                          tag: item.tag,
                          article_count: this.numberComma(item.sum)+"개", // 수집수
                      })
                  })
              }
              this.items = result;

              if (Number.isInteger(this.searchCount / this.per_page) == false) {
                this.last_page = Math.floor(this.searchCount / this.per_page) + 1;
              } else {
                this.last_page = this.searchCount / this.per_page;
              }
              this.loading = false;
          })
          .catch(err => {
              console.error(err);
          });
        },

        searchChange() {
          this.page = 1;
          this.getData();
        },

        addTagHighlight(tag) {
          if (this.search.length > 0) {
            return tag.replace(this.search, `<span class="orange white--text">${this.search}</span>`);
          }
          return tag;
        },

        changeDateRange(start_end) {
          this.$refs[start_end].save(this.date_range[start_end]);

          this.getData();
        },

        numberComma(value) {
          return numeral(value).format('0,0');
        },
        toggleSumOrder() {
          if(this.sum_sort === 'asc') this.sum_sort = 'desc';
          else this.sum_sort = 'asc';
          this.getData();
        },
        toggleTagOrder() {
          if(this.tag_sort === 'asc') this.tag_sort = 'desc';
          else this.tag_sort = 'asc';
          this.sum_sort = '';
          this.getData();
        },
    }
}
</script>

<style scoped>
table.type10 {
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12)!important;
  width: 99%;
  text-align: left;
  line-height: 1.5;
  overflow-x: hidden;
  border-spacing: 0;
}
table.type10 thead th {
  width: 150px;
  padding: 10px;
  vertical-align: top;
  color: rgba(0,0,0,.6);
  margin: 20px 10px;
  border-bottom: thin solid rgba(0,0,0,.12);
  font-size: .75rem;
}
table.type10 tbody th {
  width: 150px;
  padding: 10px;
  font-size: .75rem;
}
table.type10 td {
  width: 350px;
  padding: 10px;
  vertical-align: top;
  border-bottom: thin solid rgba(0,0,0,.12);
  font-size: .875rem;
  line-height: 2.5;
}
table.type10:hover tbody tr:hover td {
  background: #eeeeee;
  color: black;
}
</style>
