<template>
    <v-container>
        <Breadcrumbs :breadcrumbs="breadcrumbs"/>
        <v-card width="500">
            <v-data-table
                class="row-pointer"
                :headers="headers"
                :items="items"
                :loading="loading"
                @click:row="handleClick"
            >
                <template v-slot:item.icon="{ item }">
                    <v-icon
                        mid
                        dark
                        left
                        :color="getColor(item.platform)"
                    >
                        {{ item.icon }}
                    </v-icon>
                    {{ item.platform }}
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>

<script>
import Breadcrumbs from "../../components/Breadcrumbs";

export default {
    components: {
        Breadcrumbs,
    },
    data() {
        return {
            loading: false,
            breadcrumbs: [
                {
                    text: '플랫폼 관리',
                    disabled: false,
                    href: '/platforms',
                },
            ],
            headers: [
                {text: 'NO.', value: 'no', filterable: false},
                {text: '플랫폼', value: 'icon', filterable: false},
            ],
            items: [],
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            let result = [];
            this.loading = true
            this.axios.get('api/v1/platforms')
                .then(res => {
                    if (res.data.data.platforms.length > 0) {
                        res.data.data.platforms.map((item, index) => {
                            let icon = this.$root.getPlatformIconByName(item.platforms);
                            result.push({
                                no: index + 1,
                                id: item.id,
                                platform: item.platform,
                                icon: icon
                            })
                        })
                    }
                    this.items = result;
                    this.loading = false
                })
                .catch(err => {
                    console.error(err);
                });
        },
        handleClick(value) {
            this.$router.push({name: 'PlatformShow', params: {id: value.id}});
        },
        getColor(platform) {
          return this.$getPlatformColorByName(platform);
        }
    }
}
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
</style>
